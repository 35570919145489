import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
    return (
        <React.Fragment>
            <h2>Home page</h2>

            <Link to={'/auth'}>Acceder</Link>
        </React.Fragment>
    );
}
