import { SelectChangeEvent } from "@mui/material/Select";
import SelectInput from "app/components/SelectInput";

type OrganizationalUnitRoleSelectProps = {
    name: string
    value?: string
    error: string
    onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
}

const roles = [
    {
        label: 'Líder',
        value: 'LEADER'
    },
    {
        label: 'Miembro del equipo',
        value: 'SUBORDINATE'
    }
]

export default function OrganizationalUnitRoleSelect(props: OrganizationalUnitRoleSelectProps) {
    return (
        <SelectInput
            fullWidth
            name={props.name}
            label={"Rol"}
            error={props.error}
            options={roles}
            value={props.value}
            onChange={props.onChange}
        />
    )
}