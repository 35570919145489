import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "app/redux/hooks"
import { findAllAction } from "../../statement.slice";
import CheckBoxGroupInput from "app/components/CheckBoxGroupInput"

type Props = {
    required?: boolean
    label: string
    name: string
    value: string[]
    error?: string
    disabled?: boolean
    onChange?: (name: string, value: string[]) => void
}

export default function StatementsCheckBoxGroupInput(props: Props) {
    const dispatch = useAppDispatch();
    const list = useAppSelector((state) => state.statement.list);

    useEffect(() => {
        refreshData()
    }, [])

    const refreshData = () => {
        dispatch(findAllAction({
            page: 0,
            perPage: 0
        }))
    }

    return (
        <CheckBoxGroupInput
            label={props.label}
            name={props.name}
            value={props.value}
            list={list.data.map(item => ({
                id: item.id!,
                label: item.name
            }))}
            onChange={props.onChange}
        />
    )
}
