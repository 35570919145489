import SelectInput, { SelectInputOption } from 'app/components/SelectInput';
import { findAllAction } from "../../organizational-unit.slice";
import { useAppDispatch, useAppSelector } from "app/redux/hooks";
import { useEffect, useState } from 'react';
import { organizationalUnitFeature } from '../../organizational-unit.feature';
import { SelectChangeEvent } from '@mui/material/Select';

export type OrganizationalUnitSelectProps = {
    organizationId: string | null
    name: string
    value?: string
    error?: string
    onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
}

export default function OrganizationalUnitSelect(props: OrganizationalUnitSelectProps) {
    const dispatch = useAppDispatch()
    const organizationalUnitsSelector = useAppSelector(state => state.organizationalUnit.list)
    const [organizationalUnitOptions, setOrganizationalUnitOptions] = useState<SelectInputOption[]>([])
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (props.organizationId) {
            refreshData()
        }
    }, [])

    useEffect(() => {
        if (props.organizationId) {
            refreshData()
        }
    }, [props.organizationId])

    useEffect(() => {
        if (
            organizationalUnitsSelector.loading === false &&
            organizationalUnitsSelector.error === '' &&
            organizationalUnitsSelector.done === true &&
            organizationalUnitsSelector.data.length === 0
        ) {
            setErrorMessage(`No hay ${organizationalUnitFeature.label.plural} para mostrar`)
        }

        if (
            organizationalUnitsSelector.loading === false &&
            organizationalUnitsSelector.error === '' &&
            organizationalUnitsSelector.done === true &&
            organizationalUnitsSelector.data.length > 0
        ) {
            setErrorMessage(props.error ? props.error : '')
            setOrganizationalUnitOptions(
                organizationalUnitsSelector.data
                    .map(organizationalUnit => ({
                        label: organizationalUnit.name,
                        value: organizationalUnit.id
                    }))
            )
        }
    }, [organizationalUnitsSelector])

    const refreshData = () => {
        const filter = props.organizationId ? `organizationId:${props.organizationId}` : undefined

        dispatch(
            findAllAction({
                page: 0,
                perPage: 0,
                filter
            })
        )
    }

    return <SelectInput
        fullWidth
        name={props.name}
        label={organizationalUnitFeature.label.singular}
        error={errorMessage}
        options={organizationalUnitOptions}
        onChange={props.onChange}
        value={props.value}
    />
}
