export const chunkIntoN = (arr: any[], n: number) => {
    const size = Math.ceil(arr.length / n);

    return Array.from({ length: n }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );
}

export const removeValue = <T>(array: T[], value: T): T[] => {
    const index = array.indexOf(value)

    if (index > -1) {
        array.splice(index, 1);
    }

    return array
}