import Joi from "joi";
import { UpdateSociodemographicInfoDto } from "./update-sociodemographic-info.dto";

export const formLabels = {
    alias: '¿Cómo te gusta que te digan?',
    identificationType: '¿Cuál es tu tipo de identificación?',
    identificationNumber: 'Escribe tu número de identificación',
    gender: '¿Con qué género te identificas?',
    birthDay: '¿Cuál es tu fecha de nacimiento?',
    maritalStatus: '¿Cuál es tu estado civil?',
    typeOfPropertyOwn: '¿Qué tipo de propiedad tienes?',
    dependentsQuantity: '¿Cuántas personas tienes a cargo? (Así no vivan contigo)',
    childrenQuantity: '¿Cuantos hijos tienes? en caso de no tener hijos escribe cero (0)',
    socioeconomicStratum: '¿En qué estrato socioeconómico vives?',
    highestLevelOfEducationCompleted: '¿Cuál es el último nivel de escolaridad culminado?',
    countryOfResidence: '¿En qué país vives?',
    departmentOfResidence: '¿En qué departamento vives?',
    workArea: 'Selecciona tu área',
    workBranch: 'Selecciona tu Sede',
    workChargeType: '¿Qué tipo de cargo tienes en la CUN?',
    workChargeName: '¿Cuál es el nombre actual de tu cargo en la CUN?',
    workPropleReportingDirectly: '¿Cuántas personas tienes a cargo en tu reporte directo?',
    hasReportingPeopleToYouMorePeopleInCharge: '¿Las personas que te reportan directamente tienen personas a cargo?',
    haveEverHadLeadershipCharges: 'A lo largo de tu trayectoria laboral, ¿Has tenido a cargo colaboradores o funciones como líder de personal?',
    monthsQuantityWithLeadershipCharges: 'De tu experiencia laboral total ¿Cuánto tiempo llevas con funciones de liderazgo?, Expresa el tiempo en términos de meses.',
    dateOfIngressToTheCompany: '¿En qué fecha entraste a la CUN?',
}

export const schema = Joi.object<UpdateSociodemographicInfoDto, true>({
    alias: Joi
        .string()
        .label(formLabels.alias)
        .allow('', null)
        .optional(),
    identificationType: Joi
        .string()
        .label(formLabels.identificationType)
        .min(2)
        .max(255)
        .allow('', null)
        .optional(),
    identificationNumber: Joi
        .string()
        .label(formLabels.identificationNumber)
        .min(2)
        .max(255)
        .allow('', null)
        .optional(),
    gender: Joi
        .string()
        .label(formLabels.gender)
        .min(2)
        .max(255)
        .allow('', null)
        .optional(),
    birthDay: Joi
        .string()
        .label(formLabels.birthDay)
        .allow('', null)
        .optional(),
    maritalStatus: Joi
        .string()
        .label(formLabels.maritalStatus)
        .min(2)
        .max(255)
        .allow('', null)
        .optional(),
    typeOfPropertyOwn: Joi
        .string()
        .label(formLabels.typeOfPropertyOwn)
        .min(2)
        .max(255)
        .allow('', null)
        .optional(),
    dependentsQuantity: Joi
        .number()
        .label(formLabels.dependentsQuantity)
        .min(0)
        .max(10000)
        .allow('', null)
        .optional(),
    childrenQuantity: Joi
        .number()
        .label(formLabels.childrenQuantity)
        .min(0)
        .max(10000)
        .allow('', null)
        .optional(),
    socioeconomicStratum: Joi
        .string()
        .label(formLabels.socioeconomicStratum)
        .allow('', null)
        .optional(),
    highestLevelOfEducationCompleted: Joi
        .string()
        .label(formLabels.highestLevelOfEducationCompleted)
        .allow('', null)
        .optional(),
    countryOfResidence: Joi
        .string()
        .label(formLabels.countryOfResidence)
        .allow('', null)
        .optional(),
    departmentOfResidence: Joi
        .string()
        .label(formLabels.departmentOfResidence)
        .allow('', null)
        .optional(),
    workArea: Joi
        .string()
        .label(formLabels.workArea)
        .allow('', null)
        .optional(),
    workBranch: Joi
        .string()
        .label(formLabels.workBranch)
        .allow('', null)
        .optional(),
    workChargeType: Joi
        .string()
        .label(formLabels.workChargeType)
        .allow('', null)
        .optional(),
    workChargeName: Joi
        .string()
        .label(formLabels.workChargeName)
        .allow('', null)
        .optional(),
    workPropleReportingDirectly: Joi
        .number()
        .label(formLabels.workPropleReportingDirectly)
        .min(0)
        .max(10000)
        .allow('', null)
        .optional(),
    hasReportingPeopleToYouMorePeopleInCharge: Joi
        .string()
        .label(formLabels.hasReportingPeopleToYouMorePeopleInCharge)
        .allow('', null)
        .optional(),
    haveEverHadLeadershipCharges: Joi
        .string()
        .label(formLabels.haveEverHadLeadershipCharges)
        .allow('', null)
        .optional(),
    monthsQuantityWithLeadershipCharges: Joi
        .number()
        .label(formLabels.monthsQuantityWithLeadershipCharges)
        .min(0)
        .max(10000)
        .allow('', null)
        .optional(),
    dateOfIngressToTheCompany: Joi
        .string()
        .label(formLabels.dateOfIngressToTheCompany)
        .allow('', null)
        .optional(),
})
