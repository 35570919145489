import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { PropsWithChildren } from "react";

type Props = {
    title: string
} & PropsWithChildren

export default function CardInputWrapper(props: Props) {
    return (
        <>
            <Card sx={{ mt: 2 }} variant="outlined">
                <CardHeader subheader={props.title} />
                <Divider />
                <CardContent>
                    {props.children}
                </CardContent>
            </Card>
        </>
    )
}
