import { MenuListItem } from "app/containers/Layout/PrivateLayout/SideBar/MenuList";
import { Feature } from "app/types/feature.type";

export const buildMenuListItemsFromFeatures = (features: Feature[]): MenuListItem[] => {
    return features.map(feature => {
        const label = feature.label[feature.menu.grammaticalNumber]

        return {
            label,
            icon: feature.icon,
            link: feature.entryLink,
            isAuthorized: feature.isAuthorized,
            children: buildMenuListItemsFromFeatures(feature.features || [])
        }
    })
}
