import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { findAllAction, initialState } from '../../user-employee.slice';
import DataTable from '../../../../components/DataTable';
import ActionButtons, { ActionButton } from '../../../../components/ActionButtons';
import { AuthorizationService } from '../../../../services/AuthorizationService';
import PageHeader from '../../../../components/PageHeader';
import { Entity, EntitySchema } from '../../user-employee.entity';
import { userEmployeeFeature } from '../../user-employee.feature';
import FormDialog from '../../../../components/FormDialog';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import Create from '../Create';
import { useQuery } from 'app/hooks';
import { FilterState, JoinState, SortingState } from 'app/types/pagination.dto';
import { MRT_ColumnFiltersState, MRT_SortingState, MRT_PaginationState } from "material-react-table";
import UpdateInfo from '../UpdateInfo';
import Delete from '../Delete';
import UpdatePassword from '../UpdatePassword';
import { buildFilterByQueryParams } from 'app/helpers/pagination.helper';

const feature = userEmployeeFeature
const entity = new Entity()

const joinQuery = entity.join.map(joinDefinition => {
    return `${joinDefinition.entity}:${joinDefinition.attributes.join('|')}`
}).join(',')

const joinInitialState = {
    state: entity.join,
    query: joinQuery
}

export default function List() {
    const dispatch = useAppDispatch()
    const query = useQuery()
    const listSelector = useAppSelector(state => state.userEmployee.list)
    const deleteSelector = useAppSelector(state => state.userEmployee.delete);

    const filterInitialState: FilterState | undefined = buildFilterByQueryParams([
        {
            id: 'organizationId',
            value: query.get('organizationId')
        },
        {
            id: 'profileOrganizationalUnitAttachments.organizationalUnitId',
            value: query.get('organizationalUnitId')
        }
    ])

    const [selectedRow, setSelectedRow] = useState<EntitySchema | null>(null)
    const [sorting, setSorting] = useState<SortingState>()
    const [filter, setFilter] = useState<FilterState | undefined>(filterInitialState)
    const [page, setPage] = useState<MRT_PaginationState>({
        pageIndex: initialState.list.meta.page,
        pageSize: initialState.list.meta.perPage
    })
    const [join] = useState<JoinState>(joinInitialState)

    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [updateInfoModalOpen, setUpdateInfoModalOpen] = useState(false)
    const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const [refreshEnabled, setRefreshEnabled] = useState(false)
    const [createEnabled, setCreateEnabled] = useState(false)
    const [updateInfoEnabled, setUpdateInfoEnabled] = useState(false)
    const [updatePasswordEnabled, setUpdatePasswordEnabled] = useState(false)
    const [deleteEnabled, setDeleteEnabled] = useState(false)

    const [refreshVisible, setRefreshVisible] = useState(false)
    const [createVisible, setCreateVisible] = useState(false)
    const [updateInfoVisible, setUpdateInfoVisible] = useState(false)
    const [updatePasswordVisible, setUpdatePasswordVisible] = useState(false)
    const [deleteVisible, setDeleteVisible] = useState(false)

    const refreshData = () => {
        dispatch(
            findAllAction({
                page: page.pageIndex,
                perPage: page.pageSize,
                sortBy: sorting?.query,
                filter: filter?.query,
                join: join?.query
            })
        )
    }

    useEffect(() => {
        refreshData()
    }, [sorting, filter, page])

    useEffect(() => {
        const userIsAuthorizedToFindAll = AuthorizationService.userIsAuthorized('user-employee:findAll')
        setRefreshVisible(userIsAuthorizedToFindAll)
        setRefreshEnabled(userIsAuthorizedToFindAll)

        setCreateVisible(AuthorizationService.userIsAuthorized('user-employee:create'))
        setUpdateInfoVisible(AuthorizationService.userIsAuthorized('user-employee:update-info'))
        setUpdatePasswordVisible(AuthorizationService.userIsAuthorized('user-employee:update-password'))
        setDeleteVisible(AuthorizationService.userIsAuthorized('user-employee:remove'))
    }, [])

    useEffect(() => {
        if(deleteSelector.done) setSelectedRow(null)
    }, [deleteSelector.done]);

    useEffect(() => {
        setCreateEnabled(!selectedRow)
        setUpdateInfoEnabled(!!selectedRow)
        setUpdatePasswordEnabled(!!selectedRow)
        setDeleteEnabled(!!selectedRow)
    }, [selectedRow])

    const handleRowSelected = (row: EntitySchema | null) => {
        setSelectedRow(row)
    }

    const handleSortingChange = (sorting: MRT_SortingState, query?: string) => {
        setSorting({
            state: sorting,
            query
        })
    }

    const handleFilterChange = (filter: MRT_ColumnFiltersState, query?: string) => {
        setFilter({
            state: filter,
            query
        })
    }

    const handlePageChange = (page: MRT_PaginationState) => {
        setPage({
            pageIndex: page.pageIndex + 1,
            pageSize: page.pageSize
        })
    }

    const handleCreateCancelled = () => {
        setCreateModalOpen(false)
    }

    const handleCreateCompleted = (created: boolean) => {
        if (created) {
            setCreateModalOpen(false)
            refreshData()
        }
    }

    const handleUpdateInfoCancelled = () => {
        setUpdateInfoModalOpen(false)
    }

    const handleUpdateInfoCompleted = (updated: boolean) => {
        if (updated) {
            setUpdateInfoModalOpen(false)
            refreshData()
        }
    }

    const handleUpdatePasswordCancelled = () => {
        setUpdatePasswordModalOpen(false)
    }

    const handleUpdatePasswordCompleted = (updated: boolean) => {
        if (updated) {
            setUpdatePasswordModalOpen(false)
            refreshData()
        }
    }

    const handleDeleteCancelled = () => {
        setDeleteModalOpen(false)
    }

    const handleDeleteCompleted = (deleted: boolean) => {
        if (deleted) {
            setDeleteModalOpen(false)
            refreshData()
        }
    }

    const leftButtons: ActionButton[] = [
        {
            label: 'Refrescar',
            icon: UpdateIcon,
            color: 'primary',
            isEnabled: refreshEnabled,
            isVisible: refreshVisible,
            onClick: () => refreshData()
        },
    ]

    const rightButtons: ActionButton[] = [
        {
            label: 'Create',
            icon: AddIcon,
            color: 'primary',
            isEnabled: createEnabled,
            isVisible: createVisible,
            onClick: () => setCreateModalOpen(true)
        },
        {
            label: 'Actualizar',
            icon: EditIcon,
            color: 'primary',
            isEnabled: updateInfoEnabled,
            isVisible: updateInfoVisible,
            onClick: () => setUpdateInfoModalOpen(true)
        },
        {
            label: 'Restablecer contraseña',
            icon: LockResetIcon,
            color: 'primary',
            isEnabled: updatePasswordEnabled,
            isVisible: updatePasswordVisible,
            onClick: () => setUpdatePasswordModalOpen(true)
        },
        {
            label: 'Eliminar',
            icon: DeleteIcon,
            color: 'error',
            isEnabled: deleteEnabled,
            isVisible: deleteVisible,
            onClick: () => setDeleteModalOpen(true)
        }
    ]

    return (
        <>
            <PageHeader
                title={feature.label.plural}
                icon={feature.icon}
            />

            <ActionButtons
                leftButtons={leftButtons}
                rightButtons={rightButtons}
            />

            <DataTable
                entity={entity}
                dataTable={listSelector}
                sorting={sorting?.state}

                onRowSelected={handleRowSelected}
                onSortingChange={handleSortingChange}
                onFilterChange={handleFilterChange}
                onPageChange={handlePageChange}
            />

            <FormDialog open={createModalOpen}>
                <Create
                    onCancelled={handleCreateCancelled}
                    onCompleted={handleCreateCompleted}
                />
            </FormDialog>

            <FormDialog open={updateInfoModalOpen}>
                <UpdateInfo
                    id={selectedRow?.id ? selectedRow.id : ''}
                    onCancelled={handleUpdateInfoCancelled}
                    onCompleted={handleUpdateInfoCompleted}
                />
            </FormDialog>

            <FormDialog open={updatePasswordModalOpen}>
                <UpdatePassword
                    id={selectedRow?.id ? selectedRow.id : ''}
                    onCancelled={handleUpdatePasswordCancelled}
                    onCompleted={handleUpdatePasswordCompleted}
                />
            </FormDialog>

            <FormDialog open={deleteModalOpen}>
                <Delete
                    id={selectedRow?.id ? selectedRow.id : ''}
                    onCancelled={handleDeleteCancelled}
                    onCompleted={handleDeleteCompleted}
                />
            </FormDialog>
        </>
    );
}
