import { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderGroupParams, AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import debounce from '@mui/material/utils/debounce';

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

export type AutoCompleteGroupOption = {
    id: string;
    label: string;
    group?: string;
}

type Props = {
    defaultValue?: string
    name: string
    searchInputlabel: string
    searchInputPlaceHoler?: string
    group?: boolean
    options: AutoCompleteGroupOption[]
    noOptionsText?: string
    error?: string
    readonly?: boolean
    disabled?: boolean
    onSelect: (name: string, value?: AutoCompleteGroupOption) => void
    onAutoCompleteChange: (search: string) => void
}

export default function AutoCompleteGroupInput(props: Props) {
    const [options, setOptions] = useState<AutoCompleteGroupOption[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [value, setValue] = useState<AutoCompleteGroupOption | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const searchDelayed = useCallback(
        debounce((search: string, callback: (search: string) => void) => {
            callback(search)
        }, 500),
        []
    );

    useEffect(() => {
        const sortedOptions = props.group ?
            props.options.sort((a, b) => a.group!.localeCompare(b.group!)) :
            props.options.sort((a, b) => a.label.localeCompare(b.label))

        const selectedOption = props.options
            .find(option => option.id === props.defaultValue) || null

        setValue(selectedOption)
        setOptions(sortedOptions)
        setIsLoading(false);
    }, [props.options]);

    useEffect(() => {
        setIsLoading(true);

        searchDelayed(searchQuery, (search: string) => {
            props.onAutoCompleteChange(search)
        });
    }, [searchQuery, searchDelayed]);

    const renderGroupFn = (params: AutocompleteRenderGroupParams) => {
        return (
            <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
            </li>
        )
    }

    const renderInputFn = (params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                label={props.searchInputlabel}
                placeholder={props.searchInputPlaceHoler}
                error={Boolean(props.error)}
                helperText={props.error}
                onChange={handleInputChange}
            />
        )
    }

    const groupByFn = (option: AutoCompleteGroupOption) => {
        return option.group!
    }

    const handleChange = (event: unknown, value: AutoCompleteGroupOption | null) => {
        setValue(value);
        props.onSelect(props.name, value || undefined);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchQuery(event.target.value);
    }

    return (
        <FormControl
            margin="normal"
            fullWidth
            error={Boolean(props.error)}
            defaultValue={props.defaultValue ? props.defaultValue : undefined}
        >
            <Autocomplete
                options={options}
                groupBy={props.group ? groupByFn : undefined}
                getOptionLabel={(option) => option.label}
                renderInput={renderInputFn}
                renderGroup={props.group ? renderGroupFn : undefined}
                noOptionsText={props.noOptionsText}

                readOnly={props.readonly}
                disabled={props.disabled}

                onChange={handleChange}

                loading={isLoading}
                loadingText="Cargando..."

                value={value}
            />
        </FormControl>
    );
}
