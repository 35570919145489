import * as generator from 'generate-password-browser'

export function generatePassword() {
    return generator.generate({
        length: 16,
        numbers: true,
        lowercase: true,
        uppercase: true,
        symbols: '#$%&/'
    })
}
