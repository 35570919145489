import List from "@mui/material/List";
import MenuItem from "./MenuItem";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";

export type MenuListItem = {
    label: string,
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    link: string,
    isAuthorized: boolean
    children?: MenuListItem[]
}

type Props = {
    items: MenuListItem[]
    level: number
}

export default function MenuList(props: Props) {
    const authorizedMenuItems = props.items.filter(item => item.isAuthorized)

    return (
        <List>
            {authorizedMenuItems.map((item, index) => (
                <MenuItem
                    key={`${props.level}-${index}`}
                    index={index}
                    label={item.label}
                    link={item.link}
                    icon={item.icon}
                    children={item.children || []}
                    level={props.level}
                />
            ))}
        </List>
    )
}
