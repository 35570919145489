import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'
import { ProfileService } from './profile.service'
import { UpdateInfoDto } from './pages/UpdateInfo/update-info.dto'
import { UpdatePasswordDto } from './pages/UpdatePassword/update-password.dto'
import { UpdateSociodemographicInfoDto } from './pages/UpdateSociodemographicInfo/update-sociodemographic-info.dto'
import { handleApiResponses } from 'app/redux/helpers/async-thunk.helper'

const sliceName = 'profile'

const initialActionState = {
    loading: false,
    error: '',
    done: false
}

const initialProfileInfo: UpdateInfoDto = {
    name: '',
    lastName: '',
    email: '',
}

const initialUpdatePassword: UpdatePasswordDto = {
    lastPassword: '',
    newPassword: ''
}

export const initialSociodemographicInfo: UpdateSociodemographicInfoDto = {
    alias: undefined,
    identificationType: undefined,
    identificationNumber: undefined,
    gender: undefined,
    birthDay: undefined,
    maritalStatus: undefined,
    typeOfPropertyOwn: undefined,
    dependentsQuantity: undefined,
    childrenQuantity: undefined,
    socioeconomicStratum: undefined,
    highestLevelOfEducationCompleted: undefined,
    countryOfResidence: undefined,
    departmentOfResidence: undefined,
    workArea: undefined,
    workBranch: undefined,
    workChargeType: undefined,
    workChargeName: undefined,
    workPropleReportingDirectly: undefined,
    hasReportingPeopleToYouMorePeopleInCharge: undefined,
    haveEverHadLeadershipCharges: undefined,
    monthsQuantityWithLeadershipCharges: undefined,
    dateOfIngressToTheCompany: undefined
}

const initialProfileInfoState = {
    ...initialActionState,
    data: initialProfileInfo
}

const initialUpdatePasswordState = {
    ...initialActionState,
    data: initialUpdatePassword
}

const initialGetSociodemographicInfoState = {
    ...initialActionState,
    data: initialSociodemographicInfo
}

const initialUpdateSociodemographicInfoState = {
    ...initialActionState,
    data: initialUpdatePassword
}

const initialState = {
    profileInfo: initialProfileInfoState,
    updatePassword: initialUpdatePasswordState,
    getSociodemographicInfo: initialGetSociodemographicInfoState,
    updateSociodemographicInfo: initialUpdateSociodemographicInfoState
}

const getProfileAction = createAsyncThunk(
    `${sliceName}/get`,
    async () => {
        const service = new ProfileService()

        return service.getMyProfile()
    }
)

const updateInfoAction = createAsyncThunk(
    `${sliceName}/updateInfo`,
    async (input: UpdateInfoDto) => {
        const service = new ProfileService()

        return service.updateInfo(input)
    }
)

const updatePasswordAction = createAsyncThunk(
    `${sliceName}/updatePassword`,
    async (input: UpdatePasswordDto, thunkApi) => {
        const service = new ProfileService()
        const response = await service.updatePassword(input)

        return handleApiResponses(response, thunkApi)
    }
)

const getSociodemographicInfoAction = createAsyncThunk(
    `${sliceName}/getSociodemographicInfo`,
    async () => {
        const service = new ProfileService()

        return service.getSociodemographicInfo()
    }
)

const updateSociodemographicInfoAction = createAsyncThunk(
    `${sliceName}/updateSociodemographicInfo`,
    async (input: UpdateSociodemographicInfoDto) => {
        const service = new ProfileService()

        return service.updateSociodemographicInfo(input)
    }
)

export const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetUpdateInfoAction: (state) => {
            state.profileInfo = initialProfileInfoState
        },
        resetUpdatePasswordAction: (state) => {
            state.updatePassword = initialUpdatePasswordState
        },
        resetGetSociodemographicInfoAction: (state) => {
            state.getSociodemographicInfo = initialGetSociodemographicInfoState
        },
        resetUpdateSociodemographicInfoAction: (state) => {
            state.getSociodemographicInfo = initialGetSociodemographicInfoState
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfileAction.pending, (state) => {
                state.profileInfo.loading = true;
                state.profileInfo.error = ''
                state.profileInfo.done = false
                state.profileInfo.data = initialProfileInfo
            })
            .addCase(getProfileAction.fulfilled, (state, action) => {
                state.profileInfo.loading = false;
                state.profileInfo.error = ''
                state.profileInfo.done = true
                state.profileInfo.data = action.payload as UpdateInfoDto
            })
            .addCase(getProfileAction.rejected, (state, action) => {
                state.profileInfo.loading = false;
                state.profileInfo.error = action.error.message || 'Someting wrong occured'
                state.profileInfo.done = false
                state.profileInfo.data = initialProfileInfo
            })

            .addCase(updateInfoAction.pending, (state) => {
                state.profileInfo.loading = true;
                state.profileInfo.error = ''
                state.profileInfo.done = false
            })
            .addCase(updateInfoAction.fulfilled, (state, action) => {
                state.profileInfo.loading = false;
                state.profileInfo.error = ''
                state.profileInfo.done = action.payload
            })
            .addCase(updateInfoAction.rejected, (state, action) => {
                state.profileInfo.loading = false;
                state.profileInfo.error = action.error.message || 'Someting wrong occured'
                state.profileInfo.done = false
            })

            .addCase(updatePasswordAction.pending, (state) => {
                state.updatePassword.loading = true
                state.updatePassword.error = initialState.updatePassword.error
                state.updatePassword.done = initialState.updatePassword.done
            })
            .addCase(updatePasswordAction.fulfilled, (state, action) => {
                state.updatePassword.loading = initialState.updatePassword.loading
                state.updatePassword.error = initialState.updatePassword.error
                state.updatePassword.done = true
            })
            .addCase(updatePasswordAction.rejected, (state, action) => {
                state.updatePassword.loading = false
                state.updatePassword.error = action.payload as string
                state.updatePassword.done = false
            })

            .addCase(getSociodemographicInfoAction.pending, (state) => {
                state.getSociodemographicInfo.loading = true;
                state.getSociodemographicInfo.error = ''
                state.getSociodemographicInfo.done = false
                state.getSociodemographicInfo.data = initialSociodemographicInfo
            })
            .addCase(getSociodemographicInfoAction.fulfilled, (state, action) => {
                state.getSociodemographicInfo.loading = false;
                state.getSociodemographicInfo.error = ''
                state.getSociodemographicInfo.done = true
                state.getSociodemographicInfo.data = action.payload as UpdateSociodemographicInfoDto
            })
            .addCase(getSociodemographicInfoAction.rejected, (state, action) => {
                state.getSociodemographicInfo.loading = false;
                state.getSociodemographicInfo.error = action.error.message || 'Someting wrong occured'
                state.getSociodemographicInfo.done = false
                state.getSociodemographicInfo.data = initialSociodemographicInfo
            })

            .addCase(updateSociodemographicInfoAction.pending, (state) => {
                state.updatePassword.loading = true;
                state.updatePassword.error = ''
                state.updatePassword.done = false
            })
            .addCase(updateSociodemographicInfoAction.fulfilled, (state, action) => {
                state.updatePassword.loading = false;
                state.updatePassword.error = ''
                state.updatePassword.done = action.payload
            })
            .addCase(updateSociodemographicInfoAction.rejected, (state, action) => {
                state.updatePassword.loading = false;
                state.updatePassword.error = action.error.message || 'Someting wrong occured'
                state.updatePassword.done = false
            })
    }
})

export {
    getProfileAction,
    updateInfoAction,
    updatePasswordAction,
    getSociodemographicInfoAction,
    updateSociodemographicInfoAction
}

export const { resetUpdateInfoAction, resetUpdatePasswordAction } = slice.actions
export const selector = (state: RootState) => state.profile
export default slice.reducer;
