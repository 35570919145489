import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import FormHelperText from "@mui/material/FormHelperText"
import React, { useEffect, useState } from "react"
import { chunkIntoN, removeValue } from "app/helpers/array.helper"
import { ButtonGroup } from "@mui/material"
import CardInputWrapper from "../CardInputWrapper"

type CheckBoxInput = {
    id: string
    label: string
}

type Props = {
    required?: boolean
    label: string
    name: string
    value: string[]
    list: CheckBoxInput[]
    error?: string
    disabled?: boolean
    onChange?: (name: string, value: string[]) => void
}

export default function CheckBoxGroupInput(props: Props) {
    const [inputGroups, setInputGroups] = useState<CheckBoxInput[][]>([])
    const [values, setValues] = useState<string[]>([])

    useEffect(() => {
        const chunks = chunkIntoN(props.list, 3)

        setInputGroups(chunks)
    }, [props.list])

    useEffect(() => {
        setValues(props.value)
    }, [props.value])

    const isStatementChecked = (statementId: string): boolean => {
        return values.includes(statementId)
    }

    const handleParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.checked) {
            setValues([])
        } else {
            setValues(props.list.map(l => l.id))
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.currentTarget.name
        const value = event.currentTarget.value
        const checked = event.currentTarget.checked

        if (props.onChange) {
            const newValues = checked ?
                [...new Set([...values, value])] :
                removeValue(values, value)

            props.onChange(name, newValues)
        }
    };

    return (
        <CardInputWrapper
            title={props.label}
        >
            <ButtonGroup></ButtonGroup>
            <FormControl
                required
                error={!!props.error}
                component="fieldset"
                variant="outlined"
            >
                <FormControlLabel
                    label="Seleccionar todo"
                    control={
                        <Checkbox
                            checked={values.length === props.list.length}
                            indeterminate={values.length > 0 && values.length !== props.list.length}
                            onChange={handleParentChange}
                        />
                    }
                />
                <br />
                <Box sx={{ display: 'flex' }}>
                    {inputGroups.map((statements, groupIndex) => {
                        return (
                            <FormGroup key={`formgroup-${groupIndex}`}>
                                {statements.map((statement) => {
                                    return (
                                        <FormControlLabel
                                            key={`statement-${statement.id}`}
                                            control={
                                                <Checkbox
                                                    name={props.name}
                                                    value={statement.id}
                                                    checked={isStatementChecked(statement.id!)}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={statement.label}
                                        />
                                    )
                                })}
                            </FormGroup>
                        )
                    })}
                </Box>
                <FormHelperText>{props.error}</FormHelperText>
            </FormControl>
        </CardInputWrapper>
    )
}
