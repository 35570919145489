import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MenuListItem } from './SideBar/MenuList';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';
import { Chip, Link } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { stringAvatar } from '../../../helpers/string.helper';
import { logout, getAuthenticated, getPermissionsAndRoles } from '../../../redux/authenticatorSlice'
import { AuthorizationService } from 'app/services/AuthorizationService';
import Fingerprint from '@mui/icons-material/Fingerprint';

const drawerWidth = 240;

interface Props {
    menuListItems: MenuListItem[]
}

export default function ResponsiveAppBar(props: Props) {
    const dispatch = useAppDispatch()
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const authenticatedUser = useAppSelector(state => state.authenticator.authenticated?.user)
    const [profileMenuVisible, setProfileMenuVisible] = React.useState(false)
    const [permissionsAndRolesVisible, setPermissionsAndRolesVisible] = React.useState(false)

    React.useEffect(() => {
        setProfileMenuVisible(
            AuthorizationService.userIsAuthorized('my-profile:view') ||
            AuthorizationService.userIsAuthorized('my-profile:update-password')
        )

        setPermissionsAndRolesVisible(
            AuthorizationService.userIsAuthorized('auth:getPermissionsAndRoles')
        )

        dispatch(
            getAuthenticated()
        )
    }, [])

    const handleClickGetPermissionsAndRoles = () => {
        dispatch(
            getPermissionsAndRoles()
        )
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogoutClick = () => {
        dispatch(
            logout()
        )
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}></Box>

                    {
                        authenticatedUser?.roles.map((role, index) => {
                            return (
                                <Chip
                                    key={index}
                                    label={role}
                                    variant="filled"
                                    color='secondary'
                                />
                            )
                        })
                    }

                    {
                        permissionsAndRolesVisible && (
                            <IconButton
                                sx={{ ml: 1 }}
                                size="small"
                                color='secondary'
                                onClick={handleClickGetPermissionsAndRoles}
                            >
                                <Fingerprint />
                            </IconButton>
                        )
                    }


                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar {...stringAvatar(authenticatedUser?.name + ' ' + authenticatedUser?.lastName)} />
                        </IconButton>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {
                                profileMenuVisible && (
                                    <MenuItem key='profile'>
                                        <Link href="/client/profile" underline="none" color="inherit">
                                            <Typography textAlign="center">Perfil</Typography>
                                        </Link>
                                    </MenuItem>
                                )
                            }

                            <MenuItem key='logout' onClick={handleLogoutClick}>
                                <Typography textAlign="center">Salir</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <SideBar menuListItems={props.menuListItems} />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    <SideBar menuListItems={props.menuListItems} />
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                style={mainBoxStyle}
            >
                <Toolbar />
                <Outlet></Outlet>
            </Box>
        </Box>
    );
}

const mainBoxStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',

    backgroundColor: '#e5e5f7',
    backgroundSize: '20px 20px',
    backgroundImage: 'radial-gradient(#cacaca 1px, #fdfdfd 1px)'
}