import { Feature } from '../../types/feature.type';
import QuizIcon from '@mui/icons-material/Quiz';
import { Entity } from './assessment.entity';
import { feminineArticle } from '../../features/articles';
import { AuthorizationService } from '../../services/AuthorizationService';

const entity = new Entity()

export const assessmentFeature: Feature = {
  label: {
    singular: entity.name.singular,
    plural: entity.name.plural,
  },
  menu: {
    grammaticalNumber: 'plural'
  },
  article: feminineArticle,
  icon: QuizIcon,
  isAuthorized: AuthorizationService.userIsAuthorized('assessment:findAll'),
  entryLink: `/client/${entity.slug}`,
}
