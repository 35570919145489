import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';

type ErrorAlertProps = {
    shown: boolean
    message: string
}

export default function ErrorAlert(props: ErrorAlertProps) {
    const [open, setOpen] = useState(props.shown)

    useEffect(() => {
        setOpen(props.shown)
    }, [props.shown])

    return (
        <Box sx={{ width: '100%' }}>
            <Collapse in={open}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {props.message}
                </Alert>
            </Collapse>
        </Box>
    );
}
