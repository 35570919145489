import {
    EntityAttribute,
    EntityName,
    UiEntity,
  } from "../../interfaces/EntityInterface";
  
  export type EntitySchema = {};
  
  export const entityInitialState = {};
  
  export class Entity implements UiEntity {
    slug: string = "dashboard";
  
    name: EntityName = {
      singular: "Panel de Control",
      plural: "Panel de Control",
    };
  
    primaryKeyAttribute: string = "";
  
    linkedToViewAttribute: string = "";
  
    attributes: EntityAttribute[] = [];
  }
  