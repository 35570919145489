import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { validateForm } from "../../../../helpers/validation";
import { schema } from "./schema";
import { updateAction, resetUpdateAction, findOneAction } from '../../assessment.slice'
import { UpdateDto } from './update.dto'
import { Entity } from "../../assessment.entity";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (done: boolean) => void
}

const formInitialState = {
    name: '',
    lastName: '',
    email: ''
}

const errorsInitialState = formInitialState
const uiEntity = new Entity()

export default function Update(props: Props) {
    const dispatch = useAppDispatch()
    const updateSelector = useAppSelector(state => state.assessment.update)
    const [form, setForm] = useState<UpdateDto>(updateSelector.data)
    const [errors, setErrors] = useState<UpdateDto>(errorsInitialState)

    useEffect(() => {
        dispatch(findOneAction(props.id))
    }, [])

    useEffect(() => {
        setForm(updateSelector.data)
    }, [updateSelector.data])

    useEffect(() => {
        if (updateSelector.done)
            props.onCompleted(updateSelector.done)
        dispatch(resetUpdateAction())
    }, [updateSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        const formData = {
            name: form.name,
        }

        const formValidation = validateForm(schema, formData)

        if (formValidation.error) {
            let validationErrors: Record<string, any> = {}

            for (const errorDetail of formValidation.error.details) {

                if (errorDetail.context?.key) {
                    validationErrors[errorDetail.context?.key] = errorDetail.message
                }
            }

            setErrors({
                ...errorsInitialState,
                ...validationErrors
            })
        } else {
            dispatch(updateAction({
                id: props.id,
                dto: formData
            }))
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar ` + uiEntity.name.singular}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={form.name ? form.name : ''}
                        onChange={handleInputChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />

                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updateSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updateSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
