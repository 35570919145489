import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import PasswordInput from "../PasswordInput";
import { useEffect, useState } from "react";
import { generatePassword } from "../../helpers/security";

type Props = {
    switchLabel: string
    switchEnabled: boolean
    inputLabel: string
    inputName: string
    inputValue: string
    inputError: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onGenerate: (newPassword: string) => void
}

export default function AutoGeneratePasswordInput(props: Props) {
    const [isPasswordGenerated, setIsPasswordGenerated] = useState(props.switchEnabled)
    const [value, setValue] = useState(props.inputValue)

    useEffect(() => {
        validateNewPassword(props.switchEnabled)
    }, [])

    useEffect(() => {
        setValue(props.inputValue)
    }, [props.inputValue])

    const validateNewPassword = (generate: boolean) => {
        if (generate) {
            const newPassword = generatePassword()

            setValue(newPassword)
            props.onGenerate(newPassword)
        } else {
            setValue('')
            props.onGenerate('')
        }
    }

    const handleGeneratePasswordChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        validateNewPassword(checked)
        setIsPasswordGenerated(checked)
    }

    return (
        <>
            <FormControlLabel
                value="end"
                control={<Switch
                    color="primary"
                    checked={isPasswordGenerated}
                    onChange={handleGeneratePasswordChange}
                />}
                label={props.switchLabel}
                labelPlacement="end"
            />

            <PasswordInput
                label="Contraseña"
                name={props.inputName}
                value={value}
                error={props.inputError}
                disabled={isPasswordGenerated}
                showPassword={isPasswordGenerated}
                onChange={props.onChange}
            />
        </>
    )
}
