import { JoinAndCountDefinition } from "app/types/join-and-count.type"
import { EntityAttribute, EntityName, UiEntity } from "../../interfaces/EntityInterface"
import { JoinDefinition } from "../../types/join.type"
import { organizationFeature } from "../organization/organization.feature"
import { testVersionFeature } from "../test-version/test-version.feature"
import { testFeature } from "../test/test.feature"
import { EntitySchema as UserEmployeeEntitySchema } from "../user-employee/user-employee.entity"
import { EntitySchema as TestVersionEntitySchema } from "../test-version/test-version.entity"
import { userEmployeeFeature } from "../user-employee/user-employee.feature"
import { BaseUiEntity } from "app/classes/base-ui-entity"
import { Link } from "@mui/material"
import CopyToClipboardButton from "app/components/CopyToClipboardButton"


export enum TestApplicationRecordStatus {
    ISSUED = 'ISSUED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'ENABLED',
}

export type EntitySchema = {
    id: string
    issueDate: string
    expirationDate: string
    uiUrl: number
    status: string
    maxPossiblePoints: number
    acquiredPoints: string
    accessToken: string
    accessTokenValidUntil: string
    profileId: string
    organizationId: string
    contractId: string
    organizationalUnitId: string
    testVersionId: string
    createdAt: string
    updatedAt: string
    profile: UserEmployeeEntitySchema | null
    testVersion: TestVersionEntitySchema | null
}

export const entityInitialState: EntitySchema = {
    id: "",
    issueDate: "",
    expirationDate: "",
    uiUrl: 0,
    status: TestApplicationRecordStatus.ISSUED,
    maxPossiblePoints: 0,
    acquiredPoints: "",
    accessToken: "",
    accessTokenValidUntil: "",
    profileId: "",
    organizationId: "",
    contractId: "",
    organizationalUnitId: "",
    testVersionId: "",
    createdAt: "",
    updatedAt: "",
    profile: null,
    testVersion: null
}

const name: EntityName = {
    singular: 'Registro de aplicación de prueba',
    plural: 'Registros de aplicación de prueba'
}

const slug = 'test-application-record'

const primaryKeyAttribute: string = 'id'

const linkedToViewAttribute: string = 'name'

const attributes: EntityAttribute[] = [
    {
        key: 'profile.organization.name',
        label: organizationFeature.label.singular,
        dataType: 'string',
        showInTable: true,
        sort: false,
        filter: false
    },
    {
        key: 'profile.name',
        label: userEmployeeFeature.label.singular,
        accessorFn: (row: EntitySchema) => {
            return row.profile ?
                `${row.profile.name} ${row.profile.lastName}` :
                ''
        },
        showInTable: true,
        sort: false,
        filter: false
    },
    {
        key: 'testVersion.test.name',
        label: testFeature.label.singular,
        dataType: 'string',
        showInTable: true,
        sort: false,
        filter: false
    },
    {
        key: 'testVersion.name',
        label: testVersionFeature.label.singular,
        dataType: 'string',
        showInTable: true,
        sort: false,
        filter: false
    },
    {
        key: 'issueDate',
        label: 'Fecha de emisión',
        dataType: 'date',
        showInTable: true,
        filter: false
    },
    {
        key: 'expirationDate',
        label: 'Fecha límite de presentación',
        dataType: 'date',
        showInTable: true,
        filter: false
    },
    {
        key: 'status',
        label: 'Estado',
        showInTable: true,
        filter: true,
        sort: false,
    },
    {
        label: 'Acciones',
        showInTable: true,
        filter: false,
        sort: false,
        accessorFn: (row: EntitySchema) => {
            if (row.status === 'ISSUED') {
                return (
                    <CopyToClipboardButton textToCopy={`${row.testVersion?.uiUrl}/home?id=${row.accessToken}`} label="Copiar enlace"/>
                )
            }

            if (row.status === 'QUALIFIED') {
                return (
                    <Link href={`${row.testVersion?.uiUrl}/pdf?id=${row.id}`} target="_blank">
                        Ver Resultado
                    </Link>
                )
            }
        }
    }
]

const join: JoinDefinition[] = []

const joinAndCount: JoinAndCountDefinition[] = []

export class TestApplicationRecordEntity extends BaseUiEntity {
    constructor() {
        super(
            name,
            slug,
            primaryKeyAttribute,
            linkedToViewAttribute,
            attributes,
            join,
            joinAndCount,
        )
    }
}
