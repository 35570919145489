import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useEffect, useState } from "react";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "app/redux/hooks";
import CardHeader from "@mui/material/CardHeader";
import PasswordInput from "app/components/PasswordInput";
import { mapValidationErrors, validateForm } from "app/helpers/validation";
import { schema } from "./schema";
import { updatePasswordAction } from "../../profile.slice";
import ErrorAlert from "app/components/ErrorAlert";
import SuccessAlert from "app/components/SuccessAlert";

const formInitialState = {
    lastPassword: '',
    newPassword: '',
    confirmNewPassword: ''
}

const errorsInitialState = formInitialState

export function UpdatePassword() {
    const dispatch = useAppDispatch()
    const updatePasswordSelector = useAppSelector(state => state.profile.updatePassword)
    const [form, setForm] = useState(formInitialState)
    const [errors, setErrors] = useState(errorsInitialState)
    const [successMessage, setSuccessMessage] = useState<string | undefined>()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    useEffect(() => {
        if (
            updatePasswordSelector.loading === false &&
            updatePasswordSelector.error === '' &&
            updatePasswordSelector.done === true
        ) {
            setSuccessMessage('Contraseña actualizada')
            setErrorMessage(undefined)
        }

        if (
            updatePasswordSelector.loading === false &&
            updatePasswordSelector.error.length > 0 &&
            updatePasswordSelector.done === false
        ) {
            setSuccessMessage(undefined)
            setErrorMessage(updatePasswordSelector.error)
        }
    }, [updatePasswordSelector])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setErrors({
                ...formInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                updatePasswordAction({
                    lastPassword: form.lastPassword,
                    newPassword: form.newPassword,
                })
            )

            setForm(formInitialState)
        }
    };

    return (
        <Card elevation={0} sx={{ width: 500 }}>
            <CardHeader
                title={`Actualizar Contraseña`}
            />

            <CardContent>

                <PasswordInput
                    name="lastPassword"
                    label="Contraseña anterior"
                    value={form.lastPassword}
                    onChange={handleInputChange}
                    error={errors.lastPassword}
                />
                <PasswordInput
                    name="newPassword"
                    label="Nueva contraseña"
                    value={form.newPassword}
                    onChange={handleInputChange}
                    error={errors.newPassword}
                />

                <PasswordInput
                    name="confirmNewPassword"
                    label="Confirmar contraseña"
                    value={form.confirmNewPassword}
                    onChange={handleInputChange}
                    error={errors.confirmNewPassword}
                />

                <ErrorAlert
                    shown={Boolean(errorMessage)}
                    message={errorMessage || ''}
                />

                <SuccessAlert
                    shown={Boolean(successMessage)}
                    message={successMessage || ''}
                />

            </CardContent>

            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updatePasswordSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
