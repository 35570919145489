import { between } from 'app/helpers/number.helper'
import { ApiResponse } from '../../types/api-response'
import { AsyncThunkConfig, GetThunkAPI } from '../types'

export const handleApiResponses = <T>(response: ApiResponse<T>, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
    switch (true) {
        case between(response.statusCode, 200, 299):
            return response
        case between(response.statusCode, 400, 499):
            return thunkAPI.rejectWithValue(response.message)
        case between(response.statusCode, 500, 599):
            return thunkAPI.rejectWithValue('Ha ocurrido un error, intente de nuevo')
    }
}
