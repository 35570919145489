import { Article } from "../types/feature.type";

export const masculineArticle: Article = {
    definite: {
        plural: 'los',
        singular: 'el'
    },
    indefinite: {
        plural: 'unos',
        singular: 'unas'
    }
}

export const feminineArticle: Article = {
    definite: {
        plural: 'las',
        singular: 'la'
    },
    indefinite: {
        plural: 'unas',
        singular: 'una'
    }
}
