import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { CreateDto } from "./pages/Create/create.dto";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./test.entity";
import { ProtectedService } from "../../services/ProtectedService";
import { UpdateDto } from "./pages/Update/update.dto";
import { FindOneDto } from "./dto/find-one.dto";
import { isAxiosError } from "axios";

export class TestService extends ProtectedService {

  async findOne(dto: FindOneDto): Promise<EntitySchema> {
    const response = await this.axios.get(`/test/${dto.id}`, {
      params: {
        attributes: dto.attributes,
        join: dto.join
      }
    })

    return response.data
  }

  async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
    const response = await this.axios.get("/test", {
      params: dto,
    });

    return response.data;
  }

  async create(dto: CreateDto) {
    const response = await this.axios.post('/test', dto)

    if (response.status === 201) {
      return response.data.id
    }

    return undefined
  }

  async update(id: string, dto: UpdateDto): Promise<boolean> {
    const response = await this.axios.patch(`/test/${id}`, dto)

    return response.status === 200
  }

  async delete(dto: DeleteDto) {
    let ok = false
    let message = ''

    try {
      const result = await this.axios.delete(`/test/${dto.id}`)

      ok = true
      message = result.data
    } catch (error) {
      if (isAxiosError(error)) {
        ok = false
        message = error.response?.data.error
      } else {
        ok = false
        message = 'Error desconocido'
      }
    }

    return {
      ok,
      message
    }
  }
}
