import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./test-version.entity";
import { TestVersionService } from "./test-version.service";
import { RootState } from "../../redux/store";
import { FindOneDto } from "./dto/find-one.dto";
import { initialListState, initialViewState, initialCreateState, initialUpdateState, initialDeleteState } from "app/redux/helpers/state.helper";

const sliceName = "testVersion";

export const initialState = {
  list: initialListState<EntitySchema>(),
  view: initialViewState<EntitySchema>(),
  create: initialCreateState(),
  update: initialUpdateState(),
  delete: initialDeleteState()
}

const findOneAction = createAsyncThunk(
  `${sliceName}/findOne`,
  (input: FindOneDto) => {
    const service = new TestVersionService()

    return service.findOne(input)
  }
)

const findAllAction = createAsyncThunk(
  `${sliceName}/findAll`,
  (input: ListDto) => {
    const service = new TestVersionService()

    return service.findAll(input)
  }
)

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetListAction: (state) => {
      state.list = initialState.list
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllAction.pending, (state) => {
        state.list.loading = true;
        state.list.done = false
        state.list.error = initialState.list.error
        state.list.data = initialState.list.data
        state.list.meta = initialState.list.meta
      })
      .addCase(findAllAction.fulfilled, (state, action) => {
        state.list.loading = false;
        state.list.done = true
        state.list.error = initialState.list.error
        state.list.data = action.payload.data
        state.list.meta = action.payload.meta
      })
      .addCase(findAllAction.rejected, (state, action) => {
        state.list.loading = false;
        state.list.done = false
        state.list.error = action.error.message || 'Someting wrong occured'
        state.list.data = initialState.list.data
        state.list.meta = initialState.list.meta
      })

      .addCase(findOneAction.pending, (state) => {
        state.view.loading = true;
        state.view.done = false
        state.view.error = initialState.view.error
        state.view.data = initialState.view.data
      })
      .addCase(findOneAction.fulfilled, (state, action) => {
        state.view.loading = false;
        state.view.done = true
        state.view.error = initialState.view.error
        state.view.data = action.payload
      })
      .addCase(findOneAction.rejected, (state, action) => {
        state.view.loading = false;
        state.view.done = true
        state.view.error = action.error.message || 'Someting wrong occured'
        state.view.data = initialState.view.data
      })
  },
});

export { findOneAction, findAllAction };

export const { resetListAction } = slice.actions;
export const selector = (state: RootState) => state.testVersion;
export default slice.reducer;
