import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createForOrganizationAction, resetCreateForOrganizationAction } from '../../test-application-record.slice'
import { validateForm, mapValidationErrors } from "../../../../helpers/validation";
import { schema } from "./schema";
import { CreateForOrganizationDto } from './create-for-organization.dto'
import { testApplicationRecordFeature } from "../../test-application-record.feature";
import { SelectChangeEvent } from "@mui/material/Select";
import TestVersionSelectInput from "app/modules/test-version/components/TestVersionSelectInput";
import { testVersionFeature } from "app/modules/test-version/test-version.feature";
import DatePickerInput from "app/components/DatePickerInput";
import OrganizationAutoCompleteInput from "app/modules/organization/components/OrganizationAutoCompleteInput";
import ContractValidatorInput from "app/modules/contract/components/ContractValidatorInput";

type Props = {
    onCancelled: () => void
    onCompleted: () => void
}

const initialState: CreateForOrganizationDto = {
    expirationDate: '',
    organizationId: '',
    contractId: '',
    testVersionId: ''
}

const initialErrorState = {
    expirationDate: '',
    organizationId: '',
    contractId: '',
    testVersionId: ''
}

const feature = testApplicationRecordFeature

export default function CreateForOrganizationalUnit(props: Props) {
    const dispatch = useAppDispatch()
    const createForOrganizationSelector = useAppSelector(state => state.testApplicationRecord.createForOrganization)
    const contratSelector = useAppSelector(state => state.contract.viewByOrganization) 

    const [form, setForm] = useState<CreateForOrganizationDto>(initialState)
    const [errors, setErrors] = useState(initialErrorState)

    useEffect(() => {
        if (
            createForOrganizationSelector.loading === false &&
            createForOrganizationSelector.error === '' &&
            createForOrganizationSelector.done === true
        ) {
            props.onCompleted()
            dispatch(resetCreateForOrganizationAction())
        }
    }, [createForOrganizationSelector.done])

    const handleSelectChange = (event: SelectChangeEvent) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleDatePickerInputChange = (name: string, value: string | null) => {
        setForm({
            ...form,
            [name]: value
        })
    }

    const handleEntityAutoCompleteInputChange = (name: string, value?: string) => {
        setForm({
            ...form,
            testVersionId: '',
            [name]: value
        })
    }

    const handleContractValidatorInputFound = (contractId: string) => {
        setForm({
            ...form,
            contractId
        })
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema(contratSelector.data?.expirationDate || ''), form)

        if (formValidation.error) {
            setErrors({
                ...initialErrorState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                createForOrganizationAction(form)
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>

            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <DatePickerInput
                        fullWidth
                        disablepast
                        name="expirationDate"
                        label="Fecha de expiración"
                        value={form.expirationDate}
                        maxDate={contratSelector.data?.expirationDate}
                        onChange={handleDatePickerInputChange}
                        error={Boolean(errors.expirationDate)}
                        helperText={errors.expirationDate}
                    />

                    <OrganizationAutoCompleteInput
                        name="organizationId"
                        value={form.organizationId}
                        onSelect={handleEntityAutoCompleteInputChange}
                        error={errors.organizationId}
                    />

                    {
                        Boolean(form.organizationId) && (
                            <ContractValidatorInput
                                organizationId={form.organizationId}
                                onContractFound={handleContractValidatorInputFound} />
                        )
                    }

                    {
                        form.contractId && Boolean(form.organizationId) && (
                            <TestVersionSelectInput
                                label={testVersionFeature.label.singular}
                                name="testVersionId"
                                value={form.testVersionId}
                                error={errors.testVersionId}
                                onChange={handleSelectChange}
                            />
                        )
                    }
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createForOrganizationSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createForOrganizationSelector.loading || !Boolean(form.organizationId)}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
