import {
  EntityAttribute,
  EntityName,
  UiEntity,
} from "../../interfaces/EntityInterface";
import { testFeature } from "../test/test.feature";
import { EntitySchema as TestEntitySchema } from "../test/test.entity"
import { JoinDefinition } from "app/types/join.type";
import { JoinAndCountDefinition } from "app/types/join-and-count.type";
import { BaseUiEntity } from "app/classes/base-ui-entity";

export type EntitySchema = {
  id?: string;
  name: string;
  description: string;
  uiUrl: string;
  createdAt: string;
  updatedAt: string;
  testId: string;
  test?: TestEntitySchema
};

export const entityInitialState = {
  id: undefined,
  name: "",
  description: "",
  uiUrl: "",
  createdAt: "",
  updatedAt: "",
  testId: "",
  test: undefined
};

const slug: string = "test-version";

const name: EntityName = {
  singular: "Version de prueba",
  plural: "Versiones de pruebas",
};

const primaryKeyAttribute: string = "id";

const linkedToViewAttribute: string = "name";

const attributes: EntityAttribute[] = [
  {
    key: "id",
    label: "ID",
    dataType: "string",
    showInTable: false
  },
  {
    key: "name",
    label: "Nombre",
    dataType: "string",
    showInTable: true
  },
  {
    key: "description",
    label: "Descripción",
    dataType: "string",
    showInTable: true
  },
  {
    key: 'test.name',
    label: testFeature.label.singular,
    dataType: 'string',
    showInTable: true
  },
  {
    key: "createdAt",
    label: "Creado",
    dataType: "date",
    showInTable: true
  },
  {
    key: "updatedAt",
    label: "Actualizado",
    dataType: "date",
    showInTable: false
  },
];

const join: JoinDefinition[] = [
  {
    entity: 'test',
    attributes: ['id', 'name']
  }
]

const joinAndCount: JoinAndCountDefinition[] = []

export class Entity extends BaseUiEntity {
  constructor() {
    super(
      name,
      slug,
      primaryKeyAttribute,
      linkedToViewAttribute,
      attributes,
      join,
      joinAndCount,
    )
  }
}