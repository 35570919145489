import { JoinDefinition } from "app/types/join.type";
import {
  EntityAttribute,
  EntityName,
  UiEntity,
} from "../../interfaces/EntityInterface";
import { EntitySchema as StatementEntitySchema } from '../statement/statement.entity';

export type EntitySchema = {
  id?: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  statements: StatementEntitySchema[];
};

export const entityInitialState = {
  id: undefined,
  name: "",
  createdAt: "",
  updatedAt: "",
  statements: []
};

export class Entity implements UiEntity {
  slug: string = "policy";

  join?: JoinDefinition[] | undefined;

  name: EntityName = {
    singular: "Política",
    plural: "Políticas",
  };

  primaryKeyAttribute: string = "id";

  linkedToViewAttribute: string = "name";

  attributes: EntityAttribute[] = [
    {
      key: "id",
      label: "ID",
      dataType: "string",
      showInTable: false,
      // order: 0,
    },
    {
      key: "name",
      label: "Nombre",
      dataType: "string",
      showInTable: true,
      // order: 1,
    },
    {
      key: "createdAt",
      label: "Creado",
      dataType: "date",
      showInTable: true,
      // order: 3,
    },
    {
      key: "updatedAt",
      label: "Actualizado",
      dataType: "date",
      showInTable: false,
      // order: 4,
    },
  ];
}
