import Grid from "@mui/material/Grid";
import OrganizationalUnitSelect from "../OrganizationalUnitSelect";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import OrganizationalUnitRoleSelect from "../OrganizationalUnitRoleSelect";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import DeleteEmployeeFromOrganizationalUnitDialog from "../OrganizationalUnitWithRoleMultipleInput/DeleteEmployeeFromOrganizationalUnitDialog";
import { SelectChangeEvent } from "@mui/material/Select";
import { AttachEmployeeOrganizationalUnitRole } from "app/modules/user-employee/pages/Create/create.dto";

type OrganizationalUnitWithRoleInputProps = {
    index: number
    organizationId: string | null
    organizationalUnitId?: string
    role?: string
    error?: AttachEmployeeOrganizationalUnitRole
    onChange: (index: number, organizationalUnitId?: string, role?: string) => void
    onConfirmDeletion?: (index: number, organizationalUnitId?: string, role?: string) => void
}

export type OrganizationalUnitWithRole = {
    organizationalUnitId?: string
    role?: string
}

export default function OrganizationalUnitWithRoleInput(props: OrganizationalUnitWithRoleInputProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCancelDeletion = () => {
        setAnchorEl(null);
    }

    const handleConfirmDeletion = () => {
        setAnchorEl(null);

        if (props.onConfirmDeletion) {
            props.onConfirmDeletion(props.index, props.organizationalUnitId, props.role)
        }
    }

    const handleOrganizationalUnitChange = (event: SelectChangeEvent<string>) => {
        props.onChange(props.index, event.target.value, props.role)
    }

    const handleOrganizationalUnitRoleChange = (event: SelectChangeEvent<string>) => {
        props.onChange(props.index, props.organizationalUnitId, event.target.value)
    }

    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={6}>
                    <OrganizationalUnitSelect
                        organizationId={props.organizationId}
                        error={props.error?.organizationalUnitId || ''}
                        name={`organizationalUnitId_${props.index}`}
                        value={props.organizationalUnitId}
                        onChange={handleOrganizationalUnitChange}
                    />
                </Grid>
                <Grid item xs={5}>
                    <OrganizationalUnitRoleSelect
                        name={`role_${props.index}`}
                        error={props.error?.role  || ''}
                        value={props.role}
                        onChange={handleOrganizationalUnitRoleChange}
                    />
                </Grid>
                <Grid item xs={1} display="flex" justifyContent="center">
                    {
                        props.index !== 0 && (
                            <IconButton
                                disableRipple
                                onClick={handleDeleteButtonClick}
                            >
                                <DeleteIcon color="error" />
                            </IconButton> 
                        )
                    }
                </Grid>
            </Grid>

            <Popover
                id={`delete-${props.index}-popover`}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCancelDeletion}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <DeleteEmployeeFromOrganizationalUnitDialog
                    onConfirm={handleConfirmDeletion}
                    onCancelled={handleCancelDeletion}
                />
            </Popover>
        </>
    )
}