export const language = 'es'

export const messages = {
    'alternatives.all': '{{#label}} no es compatible con ninguno de los tipos solicitados',
    'alternatives.any': '{{#label}} no es compatible con ninguno de los tipos permitidos',
    'alternatives.match': '{{#label}} no es compatible con ninguno de los tipos permitidos',
    'alternatives.one': '{{#label}} es compatible con más de uno de los tipos permitidos',
    'alternatives.types': '{{#label}} debe ser uno de los siguientes tipos: {{#types}}',

    'any.custom': '{{#label}} ha fallado en la validación debido a {{#error.message}}',
    'any.default': '{{#label}} ha generado un error al ejecutar el método por defecto',
    'any.failover': '{{#label}} ha generado un error al ejecutar el método failover',
    'any.invalid': '{{#label}} contiene un valor inválido',
    'any.only': '{{#label}} debe ser {if(#valids.length == 1, "", "uno de los ")}{{#valids}}',
    'any.ref': '{{#label}} {{#arg}} referencia {{:#ref}} que {{#reason}}',
    'any.required': '{{#label}} es obligatorio',
    'any.unknown': '{{#label}} no está permitido',

    'array.base': '{{#label}} debe ser un array',
    'array.excludes': '{{#label}} contiene un valor excluido',
    'array.hasKnown': '{{#label}} no contiene ningún patrón necesario para el tipo {:#patternLabel}',
    'array.hasUnknown': '{{#label}} no contiene ningún patrón necesario',
    'array.includes': '{{#label}} no es compatible con ninguno de los tipos permitidos',
    'array.includesRequiredBoth': '{{#label}} no contiene {{#knownMisses}} y otro(s) valor(es) {{#unknownMisses}} obligatorios',
    'array.includesRequiredKnowns': '{{#label}} no contiene {{#knownMisses}}',
    'array.includesRequiredUnknowns': '{{#label}} no contiene el/los valor(es) {{#unknownMisses}} obligatorios',
    'array.length': '{{#label}} debe contener {{#limit}} elementos',
    'array.max': '{{#label}} debe contener como máximo {{#limit}} elementos',
    'array.min': '{{#label}} debe contener al menos {{#limit}} elementos',
    'array.orderedLength': '{{#label}} debe contener al menos {{#limit}} elementos',
    'array.sort': '{{#label}} debe ser ordenado en {#order} por {{#by}}',
    'array.sort.mismatching': '{{#label}} no puede ser ordenado debido a tipos incompatibles',
    'array.sort.unsupported': '{{#label}} no puede ser ordenado debido al tipo no soportado: {#type}',
    'array.sparse': '{{#label}} no debe ser un elemento no definido',
    'array.unique': '{{#label}} contiene un elemento duplicado',

    'binary.base': '{{#label}} debe ser un búfer o un texto',
    'binary.length': '{{#label}} debe tener {{#limit}} bytes',
    'binary.max': '{{#label}} debe tener como máximo {{#limit}} bytes',
    'binary.min': '{{#label}} debe tener al menos {{#limit}} bytes',

    'boolean.base': '{{#label}} debe ser un booleano',

    'date.base': '{{#label}} debe ser una fecha válida.',
    'date.format': '{{#label}} debe estar en el formato: {msg("date.format." + #format) || #format}',
    'date.greater': '{{#label}} debe ser mayor que {{:#limit}}',
    'date.less': '{{#label}} debe ser menor que {{:#limit}}',
    'date.max': '{{#label}} debe ser menor o igual a {{:#limit}}',
    'date.min': '{{#label}} debe ser mayor o igual a {{:#limit}}',

    'date.format.iso': 'fecha ISO 8601',
    'date.format.javascript': 'marca de tiempo o número de milisegundos',
    'date.format.unix': 'marca de tiempo o número de segundos',

    'function.arity': '{{#label}} debe tener {{#n}} argumentos',
    'function.class': '{{#label}} debe ser una clase',
    'function.maxArity': '{{#label}} debe tener como máximo {{#n}} argumentos',
    'function.minArity': '{{#label}} debe tener al menos {{#n}} argumentos',

    'object.and': '{{#label}} contiene {{#presentWithLabels}} sin sus pares necesarios {{#missingWithLabels}}',
    'object.assert': '{{#label}} no es válido debido a {if(#subject.key, `"` + #subject.key + `" falló en ` + (#message || "pasar la prueba de validación"), #message || "falló la validación")}',
    'object.base': '{{#label}} debe ser del tipo {{#type}}',
    'object.instance': '{{#label}} debe ser una instancia de {{:#type}}',
    'object.length': '{{#label}} debe tener {{#limit}} entrada{if(#limit == 1, "", "s")}',
    'object.max': '{{#label}} debe tener como máximo {{#limit}} entrada{if(#limit == 1, "", "s")}',
    'object.min': '{{#label}} debe tener al menos {{#limit}} entrada{if(#limit == 1, "", "s")}',
    'object.missing': '{{#label}} debe contener al menos uno de {{#peersWithLabels}}',
    'object.nand': '{{:#mainWithLabel}} no debe existir simultáneamente con {{#peersWithLabels}}',
    'object.oxor': '{{#label}} contiene un conflicto entre pares opcionales exclusivos {{#peersWithLabels}}',
    'object.pattern.match': 'las entradas de {{#label}} no cumplen con los patrones solicitados',
    'object.refType': '{{#label}} debe ser una referencia de Joi',
    'object.regex': '{{#label}} debe ser un objeto RegExp',
    'object.rename.multiple': '{{#label}} no puede cambiar el nombre de {{:#from}} porque el cambio de nombre de múltiples entradas está deshabilitado y otra clave ya ha sido renombrada a {{:#to}}',
    'object.rename.override': '{{#label}} no puede cambiar el nombre de {{:#from}} porque la sobrescritura está deshabilitada y el destino {{:#to}} existe',
    'object.schema': '{{#label}} debe ser un esquema Joi del tipo {{#type}}',
    'object.unknown': '{{#label}} no está permitido',
    'object.with': '{{:#mainWithLabel}} falta el par obligatorio {{:#peerWithLabel}}',
    'object.without': '{{:#mainWithLabel}} entra en conflicto con el par prohibido {{:#peerWithLabel}}',
    'object.xor': '{{#label}} contiene un conflicto entre pares exclusivos {{#peersWithLabels}}',

    'number.base': '{{#label}} debe ser un número',
    'number.greater': '{{#label}} debe ser mayor que {{#limit}}',
    'number.infinity': '{{#label}} no puede ser infinito',
    'number.integer': '{{#label}} debe ser un número entero',
    'number.less': '{{#label}} debe ser menor que {{#limit}}',
    'number.max': '{{#label}} debe ser menor o igual a {{#limit}}',
    'number.min': '{{#label}} debe ser mayor o igual a {{#limit}}',
    'number.multiple': '{{#label}} debe ser un múltiplo de {{#multiple}}',
    'number.negative': '{{#label}} debe ser un número negativo',
    'number.port': '{{#label}} debe ser un puerto válido',
    'number.positive': '{{#label}} debe ser un número positivo',
    'number.precision': '{{#label}} no debe tener más de {{#limit}} decimales',
    'number.unsafe': '{{#label}} debe ser un número seguro',

    'string.alphanum': '{{#label}} debe contener solo números y letras',
    'string.base': '{{#label}} debe ser un texto',
    'string.base64': '{{#label}} debe ser un texto base64 válido',
    'string.creditCard': '{{#label}} debe ser un número de tarjeta de crédito válido',
    'string.dataUri': '{{#label}} debe ser una URI de datos',
    'string.domain': '{{#label}} debe ser un nombre de dominio válido',
    'string.email': '{{#label}} debe ser un correo electrónico válido',
    'string.empty': '{{#label}} no debe estar vacío',
    'string.guid': '{{#label}} debe ser un GUID válido',
    'string.hex': '{{#label}} debe contener solo caracteres hexadecimales',
    'string.hexAlign': '{{#label}} la decodificación hexadecimal debe estar alineada con bytes',
    'string.hostname': '{{#label}} debe ser un nombre de host válido',
    'string.ip': '{{#label}} debe ser una dirección IP válida con un CIDR {{#cidr}}',
    'string.ipVersion': '{{#label}} debe ser una dirección IP válida de una de las versiones: {{#version}} con un CIDR {{#cidr}}',
    'string.isoDate': '{{#label}} debe estar en formato ISO',
    'string.isoDuration': '{{#label}} debe ser una duración con formato ISO 8601 válido',
    'string.length': 'La cantidad de caracteres de {{#label}} debe ser de {{#limit}} caracteres',
    'string.lowercase': '{{#label}} debe contener solo caracteres en minúsculas',
    'string.max': 'La cantidad de caracteres de {{#label}} debe ser menor o igual que {{#limit}}',
    'string.min': 'La cantidad de caracteres de {{#label}} debe ser al menos de {{#limit}}',
    'string.normalize': '{{#label}} debe estar normalizado en unicode en la forma {{#form}}',
    'string.token': '{{#label}} debe contener solo números, letras y caracteres de subrayado',
    'string.pattern.base': '{{#label}} con valor {:[.]} no cumple con el patrón requerido: {{#regex}}',
    'string.pattern.name': '{{#label}} con valor {:[.]} no cumple con el patrón {{#name}}',
    'string.pattern.invert.base': '{{#label}} con valor {:[.]} cumple con el patrón invertido: {{#regex}}',
    'string.pattern.invert.name': '{{#label}} con valor {:[.]} cumple con el patrón invertido {{#name}}',
    'string.trim': '{{#label}} no debe tener espacios en blanco al principio o al final',
    'string.uri': '{{#label}} debe ser una URL válida',
    'string.uriCustomScheme': '{{#label}} debe ser una URL válida según el patrón {{#scheme}}',
    'string.uriRelativeOnly': '{{#label}} debe ser una URL relativa válida',
    'string.uppercase': '{{#label}} debe contener solo caracteres en mayúsculas',

    'symbol.base': '{{#label}} debe ser un símbolo',
    'symbol.map': '{{#label}} debe ser uno de los {{#map}}',
}