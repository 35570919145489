import Box from "@mui/material/Box";
import Fab, { FabPropsColorOverrides } from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { PropTypes } from "@mui/material";
import { OverridableStringUnion } from '@mui/types';

export type ActionButton = {
    label: string,
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    color: OverridableStringUnion<"error" | "info" | "success" | "warning" | PropTypes.Color, FabPropsColorOverrides>
    isEnabled: boolean,
    isVisible: boolean,
    onClick: () => void
}

type Props = {
    leftButtons: ActionButton[]
    rightButtons: ActionButton[]
}

export default function ActionButtons(props: Props) {

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ '& > :not(style)': { m: 1 } }} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                {props.leftButtons.map((button, index) => {
                    return (
                        <Slide direction="right" in={button.isVisible} unmountOnExit key={index}>
                            <Tooltip title={button.label}>
                                <span>
                                    <Fab disabled={!button.isEnabled} color={button.color} aria-label={button.label} onClick={button.onClick}>
                                        <button.icon />
                                    </Fab>
                                </span>
                            </Tooltip>
                        </Slide>
                    )
                })}
            </Box>
            <Box sx={{ '& > :not(style)': { m: 1 } }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {props.rightButtons.map((button, index) => {
                    return (
                        <Slide direction="left" in={button.isVisible} unmountOnExit key={index}>
                            <Tooltip title={button.label}>
                                <span>
                                    <Fab disabled={!button.isEnabled} color={button.color} aria-label={button.label} onClick={button.onClick}>
                                        <button.icon />
                                    </Fab>
                                </span>
                            </Tooltip>
                        </Slide>
                    )
                })}
            </Box>
        </div>
    )
}
