import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"

export type SelectInputOption = {
    label: string,
    value: string
}

type Props = {
    fullWidth?: boolean
    name: string
    label: string
    error: string
    options: SelectInputOption[]
    value?: string
    onChange?: (event: SelectChangeEvent<any>, child: React.ReactNode) => void
}

export default function SelectInput(props: Props) {
    return (
        <FormControl
            fullWidth={props.fullWidth}
            margin="normal"
            error={Boolean(props.error)}
        >
            <InputLabel
                error={Boolean(props.error)}
            >
                {props.label}
            </InputLabel>
            <Select
                name={props.name}
                label={props.label}
                value={props.value || ''}
                onChange={props.onChange}
                error={Boolean(props.error)}
            >
                {props.options.map(option => {
                    return (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    )
                })}
            </Select>
            {
                Boolean(props.error) &&
                (
                    <FormHelperText error>
                        {props.error}
                    </FormHelperText>
                )
            }
        </FormControl>
    )
}