import Joi from "joi";

export const schema = Joi.object({
    lastPassword: Joi
        .string()
        .label('Contraseña Anterior')
        .min(3)
        .max(255)
        .required(),
    newPassword: Joi
        .string()
        .label('Nueva contraseña')
        .min(3)
        .max(255)
        .required(),
    confirmNewPassword: Joi
        .any()
        .label('Confirmar contraseña')
        .required()
        .valid(Joi.ref('newPassword'))
        .messages({
            'any.only': '{{#label}} no coincide con "Nueva contraseña".',
        })
})
