import { EntityAttribute, EntityName, UiEntity } from "app/interfaces/EntityInterface";
import { JoinAndCountDefinition } from "app/types/join-and-count.type";
import { JoinDefinition } from "app/types/join.type";

export class BaseUiEntity implements UiEntity {
    constructor(
        public name: EntityName,
        public slug: string,
        public primaryKeyAttribute: string,
        public linkedToViewAttribute: string,
        public attributes: EntityAttribute[],
        public join: JoinDefinition[],
        public joinAndCount: JoinAndCountDefinition[]
    ) { }

    getJoinQuery(): string | undefined {
        return this.join.map(joinDefinition => {
            return `${joinDefinition.entity}:${joinDefinition.attributes.join('|')}`
        }).join(',')
    }

    getJoinAndCountQuery(): string | undefined {
        return this.joinAndCount.map(joinAndCountDefinition => {
            return joinAndCountDefinition.entity
        }).join(',')
    }
}
