import { Box, SvgIconTypeMap, Toolbar, Typography } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { ReactElement } from "react"

type Props = {
    title: string
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}

export default function PageHeader(props: Props) {
    return (
            <Box sx={{}}>
                <Typography variant="h2">{props.title}</Typography>

                <props.icon sx={{ position: 'absolute', mt: '-130px', ml: '-100px', fontSize: 300, opacity: '0.2', color: 'gray' }} />
            </Box>
    )
}
