import {
  EntityAttribute,
  EntityName,
  UiEntity,
} from '../../interfaces/EntityInterface';
import { EntitySchema as PolicyEntitySchema } from '../policy/policy.entity';

export type EntitySchema = {
  id?: string;
  name: string;
  scope: string;
  createdAt: string;
  updatedAt: string;
  policies: PolicyEntitySchema[];
};

export const entityInitialState = {
  id: undefined,
  name: '',
  scope: '',
  createdAt: '',
  updatedAt: '',
  policies: [],
};

export class Entity implements UiEntity {
  slug: string = 'role';

  name: EntityName = {
    singular: 'Rol',
    plural: 'Roles',
  };

  primaryKeyAttribute: string = 'id';

  linkedToViewAttribute: string = 'name';

  attributes: EntityAttribute[] = [
    {
      key: 'id',
      label: 'ID',
      dataType: 'string',
      showInTable: false,
      // order: 0,
    },
    {
      key: 'name',
      label: 'Nombre',
      dataType: 'string',
      showInTable: true,
      // order: 1,
    },
    {
      key: 'scope',
      label: 'Ámbito',
      dataType: 'string',
      showInTable: true,
      // order: 1,
    },
    {
      key: 'createdAt',
      label: 'Creado',
      dataType: 'date',
      showInTable: true,
      // order: 3,
    },
    {
      key: 'updatedAt',
      label: 'Actualizado',
      dataType: 'date',
      showInTable: false,
      // order: 4,
    },
  ];
}
