import * as React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { requestPasswordReset } from '../../../redux/authenticatorSlice';
import { mapValidationErrors, validateForm } from '../../../helpers/validation';
import { schema } from './schema';

function RequestPasswordResetConfirmationMesage(email: string) {
  return (
    <>
      <br />
      <Alert severity="info">
        Ya puede cerrar esta pestaña <br />
        Siga las instrucciones enviadas al correo {email}
      </Alert>
    </>
  )
}

const formInitialState = {
  email: ''
}

const errorsInitialState = formInitialState

export default function RequestPasswordReset() {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const requestPasswordResetState = useAppSelector(state => state.authenticator.requestPasswordReset)

  const [form, setForm] = useState({
    ...formInitialState,
    email: searchParams.get('email') || ''
  })

  const [errors, setErrors] = useState(errorsInitialState)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = () => {
    const formValidation = validateForm(schema, form)

    if (formValidation.error) {
      setErrors({
        ...formInitialState,
        ...mapValidationErrors(formValidation.error)
      })
    } else {
      dispatch(
        requestPasswordReset({
          email: form.email
        })
      )

      setErrors(errorsInitialState)
      setForm(formInitialState)
    }
  };

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h5">
        Restablecer contraseña
      </Typography>

      {
        (requestPasswordResetState.done && Boolean(requestPasswordResetState.error === ''))
        && RequestPasswordResetConfirmationMesage(form.email)
      }

      <Box sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Correo electrónico"
          name="email"
          autoComplete="email"
          value={form.email}
          error={Boolean(errors.email)}
          helperText={errors.email}
          onChange={handleInputChange}
          autoFocus
        />
        <Button
          fullWidth
          variant="contained"
          disabled={requestPasswordResetState.loading}
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit}
        >
          {requestPasswordResetState.loading ? 'Cargando' : 'Solicitar'}
        </Button>

        <Button
          href='/auth/login'
          fullWidth
          variant="outlined"
          disabled={requestPasswordResetState.loading}
          sx={{ mt: 3, mb: 2 }}
        >
          Volver
        </Button>

      </Box>

    </Box>
  );
}
