import { useMemo } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";

export const useNavigateSearch = () => {
  const navigate = useNavigate();

  return (pathname: string, params: Record<string, string>) =>
    navigate(`${pathname}?${createSearchParams(params)}`);
};

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
