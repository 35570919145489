import ChecklistIcon from '@mui/icons-material/Checklist';
import { organizationalUnitFeature } from "../modules/organizational-unit/organizational-unit.feature";
import { Feature } from "../types/feature.type";
import { userEmployeeFeature } from '../modules/user-employee/user-employee.feature';
import { dashboardFeature } from "../modules/dashboard/dashboard.feature";
import { assessmentFeature } from "../modules/assessment/assessment.feature";
import { organizationFeature } from "../modules/organization/organization.feature";
import { testApplicationRecordFeature } from "app/modules/test-application-record/test-application-record.feature";
import { contractFeature } from "app/modules/contract/contract.feature";
import { masculineArticle } from './articles';
import { AuthorizationService } from 'app/services/AuthorizationService';
import { myTestApplicationRecordFeature } from 'app/modules/my-test-application-record/my-test-application-record.feature';

export const features: Feature[] = [
    dashboardFeature,
    organizationFeature,
    organizationalUnitFeature,
    userEmployeeFeature,
    assessmentFeature,
    {
        label: {
            plural: 'Pruebas',
            singular: 'Prueba',
        },
        menu: {
            grammaticalNumber: 'plural'
        },
        icon: ChecklistIcon,
        isAuthorized: (
            AuthorizationService.userIsAuthorized('test-application-record:findAll') ||
            AuthorizationService.userIsAuthorized('test-application-record:findAllMine')
        ),
        entryLink: '#',
        article: masculineArticle,
        features: [
            testApplicationRecordFeature,
            myTestApplicationRecordFeature
        ]
    },
    contractFeature
]