import { UpdateInfo } from "./UpdateInfo";
import PageHeader from "app/components/PageHeader";
import { profileFeature } from "../profile.feature";
import { UpdatePassword } from "./UpdatePassword";
import Tabs from "@mui/material/Tabs";
import { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import { AuthorizationService } from "app/services/AuthorizationService";
import { UpdateSociodemographicInfo } from "./UpdateSociodemographicInfo";

const feature = profileFeature

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Profile() {
  const [value, setValue] = useState(0);
  const [profileTabVisible, setProfileTabVisible] = useState(false)
  const [passwordTabVisible, setPasswordTabVisible] = useState(false)
  const [socioDemograpgicInformationTabVisible, setSocioDemograpgicInformationTabVisible] = useState(false)

  useEffect(() => {
    setProfileTabVisible(AuthorizationService.userIsAuthorized('my-profile:view'))
    setPasswordTabVisible(AuthorizationService.userIsAuthorized('my-profile:update-password'))
    setSocioDemograpgicInformationTabVisible(AuthorizationService.userIsAuthorized('my-profile:view-sociodemographic-information'))
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <PageHeader
        title={feature.label.singular}
        icon={feature.icon}
      />

      <Paper
        sx={{ zIndex: 1 }}
        elevation={1}>
        <Box
          sx={{ flexGrow: 1, display: 'flex' }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {
              profileTabVisible && (
                <Tab label="Perfil" {...a11yProps(0)} />
              )
            }

            {
              passwordTabVisible && (
                <Tab label="Contraseña" {...a11yProps(1)} />
              )
            }

            {
              socioDemograpgicInformationTabVisible && (
                <Tab label="Sociodemográfica" {...a11yProps(1)} />
              )
            }
          </Tabs>

          <TabPanel value={value} index={0}>
            <UpdateInfo />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UpdatePassword />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UpdateSociodemographicInfo />
          </TabPanel>
        </Box>
      </Paper>
    </>
  );
}
