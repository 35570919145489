import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { CreateForEmployeeDto } from "./pages/CreateForEmployees/create-for-employee.dto";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./test-application-record.entity";
import { ProtectedService } from "../../services/ProtectedService";
import { isAxiosError } from "axios";
import { CreateForOrganizationalUnitDto } from "./pages/CreateForOrganizationalUnit/create-for-organizational-unit.dto";
import { CreateForOrganizationDto } from "./pages/CreateForOrganization/create-for-organization.dto";
import { handleAxiosError } from "app/helpers/axios.helper";

export class TestApplicationRecordService extends ProtectedService {
    async findOne(id: string): Promise<EntitySchema> {
        const response = await this.axios.get(`/test-application-record/${id}`)

        return response.data
    }

    async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
        const response = await this.axios.get('/test-application-record', {
            params: dto
        })

        return response.data
    }

    async createForOrganization(dto: CreateForOrganizationDto): Promise<string | undefined> {
        const response = await this.axios.post('/test-application-record/organization', dto)

        if (response.status === 201) {
            return response.data.id
        }

        return undefined
    }

    async createForOrganizationalUnit(dto: CreateForOrganizationalUnitDto): Promise<string | undefined> {
        const response = await this.axios.post('/test-application-record/organizational-unit', dto)

        if (response.status === 201) {
            return response.data.id
        }

        return undefined
    }

    async createForEmployee(dto: CreateForEmployeeDto): Promise<string | undefined> {
        const response = await this.axios.post('/test-application-record/employee', {
            expirationDate: dto.expirationDate,
            profileId: dto.profileId,
            organizationId: dto.organizationId,
            contractId: dto.contractId,
            testVersionId: dto.testVersionId,
        })

        if (response.status === 201) {
            return response.data.id
        }

        return undefined
    }

    async delete(dto: DeleteDto) {
        try {
            const response = await this.axios.delete(`/test-application-record/${dto.id}`)

            return {
                statusCode: response.status,
                result: response.data.id
            }
        } catch (error) {
            return handleAxiosError(error)
        }
    }
}
