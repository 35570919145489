import { useEffect, useState } from "react";
import { MRT_ColumnFiltersState, MRT_SortingState } from "material-react-table";
import DataTable from "../../../../components/DataTable";
import ActionButtons, { ActionButton } from "../../../../components/ActionButtons";
import PageHeader from "../../../../components/PageHeader";
import { roleFeature } from "../../role.feature";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { findAllAction } from "../../role.slice";
import { AuthorizationService } from "../../../../services/AuthorizationService";
import Create from "../Create";
import Update from "../Update";
import { Entity, EntitySchema } from "../../role.entity";
import FormDialog from "../../../../components/FormDialog";
import Delete from "../Delete";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';

const feature = roleFeature;
const entity = new Entity();

export default function List() {
    const dispatch = useAppDispatch();
    const dataTable = useAppSelector((state) => state.role.list);
    const deleteSelector = useAppSelector(state => state.role.delete);
    const [selectedRow, setSelectedRow] = useState<EntitySchema | null>(null);
    const [sorting, setSorting] = useState<{ state: MRT_SortingState, query?: string }>()
    const [filter, setFilter] = useState<{ state: MRT_ColumnFiltersState, query?: string }>()
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [refreshEnabled, setRefreshEnabled] = useState(false);
    const [createEnabled, setCreateEnabled] = useState(false);
    const [updateEnabled, setUpdateEnabled] = useState(false);
    const [deleteEnabled, setDeleteEnabled] = useState(false);

    const [refreshVisible, setRefreshVisible] = useState(false);
    const [createVisible, setCreateVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);

    const refreshData = () => {
        dispatch(findAllAction({
            page: dataTable.meta.page,
            perPage: dataTable.meta.perPage,
            sortBy: sorting?.query,
            filter: filter?.query,
        }))
    }

    useEffect(() => {
        refreshData();

        const userIsAuthorizedToFindAll =
            AuthorizationService.userIsAuthorized("rbac-role:findAll");
        setRefreshVisible(userIsAuthorizedToFindAll);
        setRefreshEnabled(userIsAuthorizedToFindAll);

        setCreateVisible(AuthorizationService.userIsAuthorized("rbac-role:create"));
        setUpdateVisible(AuthorizationService.userIsAuthorized("rbac-role:update"));
        setDeleteVisible(AuthorizationService.userIsAuthorized("rbac-role:delete"));
    }, []);

    useEffect(() => {
        setCreateEnabled(!selectedRow);
        setUpdateEnabled(!!selectedRow);
        setDeleteEnabled(!!selectedRow);
    }, [selectedRow]);

    useEffect(() => {
        if(deleteSelector.done) setSelectedRow(null)
    }, [deleteSelector.done]);

    useEffect(() => {
        refreshData()
    }, [sorting, filter])

    const handleRowSelected = (row: EntitySchema | null) => {
        setSelectedRow(row)
    }

    const handleSortingChange = (sorting: MRT_SortingState, query?: string) => {
        setSorting({
            state: sorting,
            query
        })
    }

    const handleFilterChange = (filter: MRT_ColumnFiltersState, query?: string) => {
        setFilter({
            state: filter,
            query
        })
    }

    const leftButtons: ActionButton[] = [
        {
            label: 'Refrescar',
            icon: UpdateIcon,
            color: 'primary',
            isEnabled: refreshEnabled,
            isVisible: refreshVisible,
            onClick: () => refreshData()
        },
    ]

    const rightButtons: ActionButton[] = [
        {
            label: 'Crear',
            icon: AddIcon,
            color: 'primary',
            isEnabled: createEnabled,
            isVisible: createVisible,
            onClick: () => setCreateModalOpen(true)
        },
        {
            label: 'Actualizar',
            icon: EditIcon,
            color: 'primary',
            isEnabled: updateEnabled,
            isVisible: updateVisible,
            onClick: () => setUpdateModalOpen(true)
        },
        {
            label: 'Eliminar',
            icon: DeleteIcon,
            color: 'error',
            isEnabled: deleteEnabled,
            isVisible: deleteVisible,
            onClick: () => setDeleteModalOpen(true)
        }
    ]

    return (
        <>
            <PageHeader title={feature.label.plural} icon={feature.icon} />

            <ActionButtons
                leftButtons={leftButtons}
                rightButtons={rightButtons}
            />

            <DataTable
                entity={entity}
                dataTable={dataTable}
                sorting={sorting?.state}

                onRowSelected={handleRowSelected}
                onSortingChange={handleSortingChange}
                onFilterChange={handleFilterChange}
            />

            <FormDialog open={createModalOpen}>
                <Create
                    onCancelled={() => setCreateModalOpen(false)}
                    onCompleted={(created: boolean) => {
                        if (created) {
                            setCreateModalOpen(false)
                            refreshData()
                        }
                    }}
                />
            </FormDialog>

            <FormDialog open={updateModalOpen}>
                <Update
                    id={selectedRow?.id ? selectedRow.id : ''}
                    onCancelled={() => setUpdateModalOpen(false)}
                    onCompleted={(updated: boolean) => {
                        if (updated) {
                            setUpdateModalOpen(false)
                            refreshData()
                        }
                    }}
                />
            </FormDialog>

            <FormDialog open={deleteModalOpen}>
                <Delete
                    id={selectedRow?.id ? selectedRow.id : ''}
                    onCancelled={() => setDeleteModalOpen(false)}
                    onCompleted={(deleted: boolean) => {
                        if (deleted) {
                            setDeleteModalOpen(false)
                            refreshData()
                        }
                    }}
                />
            </FormDialog>
        </>
    );
}
