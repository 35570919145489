import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { validateForm, mapValidationErrors, validateArrayForm } from "../../../../helpers/validation";
import { attachEmployeeOrganizationSchema, rbacRoleSchema, schema } from "./schema";
import { createAction, attachRbacRolesAction, attachOrganizationalUnitsAction, resetCreateAction, resetAttachRbacRolesAction, resetAttachOrganizationalUnitsAction } from '../../user-employee.slice'
import { AttachEmployeeOrganizationalUnitRole, CreateDto } from './create.dto'
import AutoGeneratePasswordInput from "../../../../components/AutoGeneratePasswordInput";
import { userEmployeeFeature } from "../../user-employee.feature";
import { useQuery } from "../../../../hooks";
import OrganizationAutoCompleteInput from "app/modules/organization/components/OrganizationAutoCompleteInput";
import { SelectChangeEvent } from "@mui/material/Select";
import { RoleScopesEnum } from "app/modules/role/role.enums";
import RolesCheckBoxGroupInput from "app/modules/role/components/RolesCheckBoxGroupInput";
import OrganizationalUnitWithRoleMultipleInput, { OrganizationalUnitWithRoleMultiple } from "app/modules/organizational-unit/components/OrganizationalUnitWithRoleMultipleInput";
import ErrorAlert from "app/components/ErrorAlert";

type Props = {
    onCancelled: () => void
    onCompleted: (created: boolean) => void
}

const initialState: CreateDto = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    organizationId: ''
}

const errorInitialState: CreateDto = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    organizationId: ''
}

const feature = userEmployeeFeature

const rbacRoleInitialState = {
    roleIds: []
}

const rbacRoleErrorsInitialState = {
    roleIds: ''
}

const attachEmployeeOrganizationalUnitRoleInitialState = {
    attachments: [
        {
            index: 0,
            organizationalUnitId: undefined,
            role: undefined
        }
    ]  as OrganizationalUnitWithRoleMultiple[]
}

const attachEmployeeOrganizationalUnitRoleErrorsInitialState = {
    attachments: [{
        organizationalUnitId: '',
        role: ''
    }]
}

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const query = useQuery()
    const createEmployeeSelector = useAppSelector(state => state.userEmployee.create)
    const attachEmployeeRbacRolesSelector = useAppSelector(state => state.userEmployee.attachRbacRoles)
    const attachEmployeeOrganizationalUnitRolesSelector = useAppSelector(state => state.userEmployee.attachOrganizationalUnitRoles)

    const [form, setForm] = useState<CreateDto>({
        ...initialState,
        organizationId: query.get('organizationId') || '',
    })
    const [errors, setErrors] = useState<CreateDto>(errorInitialState)

    const [rbacRoleForm, setRbacRoleForm] = useState(rbacRoleInitialState)
    const [rbacRoleFormErrors, setRbacRoleFormErrors] = useState(rbacRoleErrorsInitialState)

    const [
        attachEmployeeOrganizationalUnitRoleForm, 
        setAttachEmployeeOrganizationalUnitRoleForm
    ] = useState<{
        attachments: OrganizationalUnitWithRoleMultiple[]
    }>(attachEmployeeOrganizationalUnitRoleInitialState)
    const [
        attachEmployeeOrganizationalUnitRoleFormErrors, 
        setAttachEmployeeOrganizationalUnitRoleFormErrors
    ] = useState<{
        attachments: AttachEmployeeOrganizationalUnitRole[]
    }>(attachEmployeeOrganizationalUnitRoleErrorsInitialState)

    useEffect(() => {
        if (createEmployeeSelector.done && !!!createEmployeeSelector.error) {
            if (!createEmployeeSelector.id) return

            dispatch(
                attachRbacRolesAction({
                    id: createEmployeeSelector.id,
                    roleIds: rbacRoleForm.roleIds
                })
            )

            dispatch(
                attachOrganizationalUnitsAction({
                    profileId: createEmployeeSelector.id,
                    attachments: attachEmployeeOrganizationalUnitRoleForm.attachments
                        .map(attachment => ({
                            organizationalUnitId: attachment.organizationalUnitId!,
                            role: attachment.role!
                        }))
                })
            )
        }
    }, [createEmployeeSelector.done])

    useEffect(() => {
        if (attachEmployeeRbacRolesSelector.done && attachEmployeeOrganizationalUnitRolesSelector.done) {
            props.onCompleted(
                createEmployeeSelector.done &&
                attachEmployeeRbacRolesSelector.done &&
                attachEmployeeOrganizationalUnitRolesSelector.done
            )

            dispatch(resetCreateAction())
            dispatch(resetAttachRbacRolesAction())
            dispatch(resetAttachOrganizationalUnitsAction())
        }
    }, [attachEmployeeRbacRolesSelector.done, attachEmployeeOrganizationalUnitRolesSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleOrganizationAutoCompleteInputSelect = (name: string, value?: string) => {
        setForm({
            ...form,
            [name]: value
        })
    }

    const handleOrganizationalUnitWithRoleMultipleInputChange = (key: number, organizationalUnitId?: string, role?: string) => {
        const selection = {
            index: key,
            organizationalUnitId,
            role
        }

        if (attachEmployeeOrganizationalUnitRoleForm.attachments[key]) {
            attachEmployeeOrganizationalUnitRoleForm.attachments[key] = selection

            setAttachEmployeeOrganizationalUnitRoleForm({
                attachments: attachEmployeeOrganizationalUnitRoleForm.attachments
            })
        }
    }

    const handleOrganizationalUnitWithRoleMultipleInputAdd = () => {
        setAttachEmployeeOrganizationalUnitRoleForm({
            attachments: [
                ...attachEmployeeOrganizationalUnitRoleForm.attachments,
                {
                    index: attachEmployeeOrganizationalUnitRoleForm.attachments.length,
                    organizationalUnitId: undefined,
                    role: undefined
                }
            ]
        })
    }

    const handleOrganizationalUnitWithRoleMultipleInputDelete = (key: number) => {
        if (attachEmployeeOrganizationalUnitRoleForm.attachments[key]) {
            attachEmployeeOrganizationalUnitRoleForm.attachments.splice(key, 1)
        }

        setAttachEmployeeOrganizationalUnitRoleForm({
            attachments: attachEmployeeOrganizationalUnitRoleForm.attachments
        })
    }

    const handleRoleInputChange = (name: string, value: string[]) => {
        setRbacRoleForm({
            ...rbacRoleForm,
            [name]: value
        })
    }

    const validateAttachmentEmployeeOrganizationErrors = (
        attachEmployeeOrganizationValidation: AttachEmployeeOrganizationalUnitRole[]
    ) => {
        const attachEmployeeOrganizationErrors: AttachEmployeeOrganizationalUnitRole[] = []
        let attachEmployeeOrganizationHaveErrors: boolean =  false

        attachEmployeeOrganizationValidation.map((validation: any) => {
            if(validation.error) {
                attachEmployeeOrganizationHaveErrors = true
                attachEmployeeOrganizationErrors.push({
                    ...attachEmployeeOrganizationalUnitRoleErrorsInitialState.attachments[0],
                    ...mapValidationErrors(validation.error)
                })
            } else {
                attachEmployeeOrganizationErrors.push(
                    attachEmployeeOrganizationalUnitRoleErrorsInitialState.attachments[0]
                )
            }
        })

        return {attachEmployeeOrganizationHaveErrors, attachEmployeeOrganizationErrors}
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema, form)
        const attachEmployeeOrganizationValidation = validateArrayForm(
            attachEmployeeOrganizationSchema, 
            attachEmployeeOrganizationalUnitRoleForm.attachments.map(
                attachment => ({
                    organizationalUnitId: attachment.organizationalUnitId,
                    role: attachment.role
                })
            )
        )
        const rbacRoleValidation = validateForm(rbacRoleSchema, rbacRoleForm.roleIds)

        const {
            attachEmployeeOrganizationHaveErrors, 
            attachEmployeeOrganizationErrors
        } = validateAttachmentEmployeeOrganizationErrors(attachEmployeeOrganizationValidation as AttachEmployeeOrganizationalUnitRole[])

        if (formValidation.error || attachEmployeeOrganizationHaveErrors || rbacRoleValidation.error) {
            const formErrors = formValidation.error
                ? {
                    ...errorInitialState,
                    ...mapValidationErrors(formValidation.error)
                }
                : errorInitialState;

            const rbacRoleFormErrors = rbacRoleValidation.error
                ? {
                    ...rbacRoleErrorsInitialState,
                    roleIds: rbacRoleValidation.error.message,
                }
                : rbacRoleErrorsInitialState;
                
            setErrors(formErrors);
            setAttachEmployeeOrganizationalUnitRoleFormErrors({
                attachments: attachEmployeeOrganizationErrors,
            });
            setRbacRoleFormErrors(rbacRoleFormErrors);
        } else {
            dispatch(
                createAction(form)
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={form.name}
                        onChange={handleInputChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="lastName"
                        label="Apellido"
                        value={form.lastName}
                        onChange={handleInputChange}
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="email"
                        name="email"
                        label="Correo electrónico"
                        value={form.email}
                        onChange={handleInputChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                    />

                    <AutoGeneratePasswordInput
                        switchLabel="Generar contraseña"
                        switchEnabled={true}
                        inputLabel="Contraseña"
                        inputName="password"
                        inputValue={form.password}
                        inputError={errors.password}
                        onGenerate={(newPassword: string) => {
                            setForm({
                                ...form,
                                password: newPassword
                            })
                        }}
                        onChange={handleInputChange} />

                    <OrganizationAutoCompleteInput
                        name="organizationId"
                        value={form.organizationId}
                        error={errors.organizationId}
                        onSelect={handleOrganizationAutoCompleteInputSelect}
                    />

                    <OrganizationalUnitWithRoleMultipleInput
                        organizationId={form.organizationId}
                        value={attachEmployeeOrganizationalUnitRoleForm.attachments}
                        errors={attachEmployeeOrganizationalUnitRoleFormErrors.attachments}
                        onChange={handleOrganizationalUnitWithRoleMultipleInputChange}
                        onAdd={handleOrganizationalUnitWithRoleMultipleInputAdd}
                        onDelete={handleOrganizationalUnitWithRoleMultipleInputDelete}
                    />

                    <RolesCheckBoxGroupInput
                        label="Roles"
                        name="roleIds"
                        scope={RoleScopesEnum.END_USER}
                        value={rbacRoleForm.roleIds}
                        error={rbacRoleFormErrors.roleIds}
                        onChange={handleRoleInputChange}
                    />
                </Box>
            </CardContent>

            <ErrorAlert
                shown={Boolean(createEmployeeSelector.error)}
                message={createEmployeeSelector.error}
            />

            <Divider />

            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createEmployeeSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createEmployeeSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
