import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { PersistGate } from 'redux-persist/integration/react';
// import { persistStore } from 'redux-persist';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { store } from './app/redux/store';
import Home from './app/pages/Home';
import PageNotFound from './app/pages/PageNotFound';

import AuthLayout from './app/containers/Layout/AuthLayout';
import PrivateLayout from './app/containers/Layout/PrivateLayout';

import Login from './app/pages/Auth/Login';
import RequestPasswordReset from './app/pages/Auth/RequestPasswordReset';
import ResetPassword from './app/pages/Auth/ResetPassword';

import Dashboard from './app/modules/dashboard/pages';
import Employee from './app/modules/user-employee/pages';
import OrganizationalUnit from './app/modules/organizational-unit/pages';
import Policy from "./app/modules/policy/pages";
import Assessment from './app/modules/assessment/pages';
import Role from './app/modules/role/pages';
import Statement from './app/modules/statement/pages';
import Organization from './app/modules/organization/pages';
import Profile from './app/modules/profile/pages';
import TestApplicationRecord from 'app/modules/test-application-record/pages';
import Contract from 'app/modules/contract/pages';
import MyTestApplicationRecord from 'app/modules/my-test-application-record/pages';

const theme = createTheme();
// const persistor = persistStore(store);

const App: React.FC = () => {
  useEffect(() => {
    document.title = 'Client'
  })

  return (
    <React.Fragment>
      <CssBaseline />
      {/* <PersistGate persistor={persistor}> */}
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path='/' element={<Home />} />

              <Route path='auth' element={<AuthLayout />}>
                <Route path='' element={<Navigate to={'login'} />} />
                <Route path='login' element={<Login />} />
                <Route path='request-password-reset' element={<RequestPasswordReset />} />
                <Route path='reset-password' element={<ResetPassword />} />
              </Route>

              <Route path='client' element={<PrivateLayout />}>
                <Route path='' element={<Navigate to={'dashboard'} />} />
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='organization/*' element={<Organization />} />
                <Route path='organizational-unit/*' element={<OrganizationalUnit />} />
                <Route path='user-employee/*' element={<Employee />} />
                <Route path="assessment/*" element={<Assessment />} />
                <Route path="policy/*" element={<Policy />} />
                <Route path="role/*" element={<Role />} />
                <Route path="statement/*" element={<Statement />} />
                <Route path="profile/*" element={<Profile />} />
                <Route path="test-application-record/*" element={<TestApplicationRecord />} />
                <Route path="my-test-application-record/*" element={<MyTestApplicationRecord />} />
                <Route path="contract/*" element={<Contract />} />
              </Route>

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </ReduxProvider>
      {/* </PersistGate> */}
    </React.Fragment>
  )
}

export default App
