import { profileFeature } from "../../profile.feature";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import { UpdateInfoDto } from "./update-info.dto";
import { useEffect, useState } from "react";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "app/redux/hooks";
import { mapValidationErrors, validateForm } from "app/helpers/validation";
import { schema } from "./schema";
import { getProfileAction, updateInfoAction } from "../../profile.slice";
import { AuthorizationService } from "app/services/AuthorizationService";

const feature = profileFeature

const formInitialState = {
    name: '',
    lastName: '',
    email: ''
}

const errorsInitialState = formInitialState

export function UpdateInfo() {
    const dispatch = useAppDispatch()
    const updateInfoSelector = useAppSelector(state => state.profile.profileInfo)
    const [canUpdateProfileInfo, setCanUpdateProfileInfo] = useState(false)

    const [form, setForm] = useState<UpdateInfoDto>(formInitialState)
    const [errors, setErrors] = useState<UpdateInfoDto>(errorsInitialState)

    useEffect(() => {
        dispatch(
            getProfileAction()
        )

        setCanUpdateProfileInfo(AuthorizationService.userIsAuthorized('my-profile:update-info'))
    }, [])

    useEffect(() => {
        setForm({
            name: updateInfoSelector.data.name,
            lastName: updateInfoSelector.data.lastName,
            email: updateInfoSelector.data.email
        })
    }, [updateInfoSelector.data])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setErrors({
                ...formInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                updateInfoAction(form)
            )
        }
    };

    return (

        <Card elevation={0} sx={{ width: 500 }}>
            <CardHeader
                title={`Actualizar ${feature.label.singular}`}
            />

            <CardContent>

                <TextField
                    fullWidth
                    margin="normal"
                    type="text"
                    name="name"
                    label="Nombre"
                    value={form.name}
                    onChange={handleInputChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    type="text"
                    name="lastName"
                    label="Apellido"
                    value={form.lastName}
                    onChange={handleInputChange}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    type="email"
                    name="email"
                    label="Correo electrónico"
                    value={form.email}
                    onChange={handleInputChange}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                />

            </CardContent>

            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {
                    canUpdateProfileInfo && (
                        <Button
                            disabled={updateInfoSelector.loading}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Actualizar
                        </Button>
                    )
                }
            </CardActions>
        </Card>
    )
}
