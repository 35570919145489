import { DialogContent } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import { PropsWithChildren } from "react"

type Props = {
    open?: boolean,
}

export default function FormDialog(props: PropsWithChildren<Props>) {
    return (
        <Dialog
            open={props.open || false}
            disableEscapeKeyDown
            keepMounted={false}
            scroll="paper"
            onClose={(event: object, reason: string) => {
                if (
                    reason === 'backdropClick' ||
                    reason === 'escapeKeyDown'
                ) return
            }}
            maxWidth="xl"
            sx={{
                '.MuiDialogContent-root': {
                    padding: 0
                }
            }}
        >
            <DialogContent>
                {props.children}
            </DialogContent>
        </Dialog>
    )
}
