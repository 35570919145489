import Joi from "joi";

export const schema = Joi.object({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required()
})
