export function getMatchingProperties<A extends Object, B extends Object>(a: A, b: B): A {
    const matchingProperties: any = {};
    for (const prop in b) {
        if (prop in a) {
            matchingProperties[prop] = b[prop];
        }
    }

    return matchingProperties;
}
