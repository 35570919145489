import Joi from "joi";

export const schema = Joi.object({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required(),
    lastName: Joi
        .string()
        .label('Apellido')
        .min(3)
        .max(255)
        .required(),
    email: Joi
        .string()
        .label('Correo electrónico')
        .email({ tlds: { allow: false } })
        .required(),
    organizationId: Joi
        .string()
        .label('Organización')
        .required()
})

export const attachEmployeeOrganizationSchema = Joi.object({
    organizationalUnitId: Joi
        .string()
        .label('Equipo de trabajo')
        .required(),
    role: Joi
        .string()
        .label('Rol')
        .valid('LEADER', 'SUBORDINATE')
        .required()
})

export const rbacRoleSchema = Joi.array().label('Roles').min(1).messages({
    'array.min': '"Roles" debe tener almenos 1 elemento'
}).required()