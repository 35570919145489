import { StorageService } from "./StorageService";

const claimSeparator = ','
const permissionSeparator = ':'
const actionSeparator = '|'

export class AuthorizationService {
    static getPermissionsList(): string[] {
        const claims = StorageService.getPermissions()

        if (!claims) return []

        const authorizedActions = claims
            .split(claimSeparator)
            .map(permission => {
                const [authorizedEntity, authorizedActions] = permission.split(permissionSeparator)
                const listOfAuthorizedActions = authorizedActions.split(actionSeparator)

                return listOfAuthorizedActions.map(authorizedAction => `${authorizedEntity}${permissionSeparator}${authorizedAction}`)
            })

        return authorizedActions
            .flat()
    }

    static userIsAuthorized(requiredPermission: string): boolean {
        const [entity] = requiredPermission.split(permissionSeparator)

        const requiredPermissions = [
            requiredPermission,
            `${entity}${permissionSeparator}*`,
            `*${permissionSeparator}*`,
        ]

        const userPermissions = AuthorizationService.getPermissionsList()

        return requiredPermissions.some(element => {
            return userPermissions.indexOf(element) !== -1;
        });
    }
}
