import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers/models';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
    name: string
    label?: string
    value?: string
    error?: boolean
    helperText?: string
    fullWidth?: boolean
    disablepast?: boolean
    format?: string

    maxDate?: string
    presentationFormat?: string
    onChange: (name: string, value: string | null) => void
}

export default function DatePickerInput(props: Props) {

    const handleDatePickerInputChange = (value: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>) => {
        props.onChange(props.name, `${dayjs(value).format('YYYY-MM-DD').toString()}`)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    format='DD-MM-YYYY'
                    label={props.label}
                    disablePast={props.disablepast}
                    value={dayjs(props.value)}
                    maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
                    slotProps={{
                        textField: {
                            name: props.name,
                            helperText: props.helperText,
                            fullWidth: props.fullWidth,
                            error: props.error
                        }
                    }}
                    onChange={handleDatePickerInputChange}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}