import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { schema } from "./schema";
import { updateAction, findOneAction, attachStatementsAction, resetUpdateAction, resetAttachStatementsAction } from '../../policy.slice'
import { UpdateDto } from './update.dto'
import { Entity } from "../../policy.entity";
import StatementsCheckBoxGroupInput from "app/modules/statement/components/StatementsCheckBoxGroupInput";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (done: boolean) => void
}

const updatePolicyInitialState: UpdateDto = {
    name: ''
}

const updatePolicyErrorsInitialState: UpdateDto = {
    name: ''
}

const statementsInitialState = {
    statementIds: [] as string[]
}

const statementsErrorsInitialState = {
    statementIds: ''
}

const uiEntity = new Entity()

export default function Update(props: Props) {
    const dispatch = useAppDispatch()
    const getPolicySelector = useAppSelector(state => state.policy.view)
    const updatePolicySelector = useAppSelector(state => state.policy.update)
    const attachStatementsPolicySelector = useAppSelector(state => state.policy.attachStatements)

    const [updatePolicyForm, setUpdatePolicyForm] = useState<UpdateDto>(updatePolicyInitialState)
    const [updatePolicyFormErrors, setUpdatePolicyFormErrors] = useState<UpdateDto>(updatePolicyErrorsInitialState)

    const [statementsForm, setStatementsForm] = useState(statementsInitialState)
    const [statementsFormErrors, setStatementsFormErrors] = useState(statementsErrorsInitialState)

    useEffect(() => {
        dispatch(findOneAction({
            id: props.id,
            join: 'statements'
        }))
    }, [])

    useEffect(() => {
        if (!getPolicySelector.entity) return

        setUpdatePolicyForm({
            name: getPolicySelector.entity.name
        })

        setStatementsForm({
            statementIds: getPolicySelector.entity.statements.map(statement => statement.id!)
        })
    }, [getPolicySelector.entity])

    useEffect(() => {
        if (updatePolicySelector.done && !!!updatePolicySelector.error) {
            dispatch(
                attachStatementsAction({
                    id: props.id,
                    statementIds: statementsForm.statementIds
                })
            )
        }
    }, [updatePolicySelector.done])

    useEffect(() => {
        if (attachStatementsPolicySelector.done && !!!attachStatementsPolicySelector.error) {
            dispatch(resetUpdateAction())
            dispatch(resetAttachStatementsAction())

            props.onCompleted(updatePolicySelector.done)
        }
    }, [attachStatementsPolicySelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatePolicyForm({
            ...updatePolicyForm,
            [event.target.name]: event.target.value
        })
    }

    const handleStatemetsInputChange = (name: string, value: string[]) => {
        setStatementsForm({
            ...statementsForm,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const formData = {
            name: updatePolicyForm.name,
        }

        const formValidation = validateForm(schema, formData)

        if (formValidation.error) {
            setUpdatePolicyFormErrors({
                ...updatePolicyErrorsInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(updateAction({
                id: props.id,
                dto: formData
            }))
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar ` + uiEntity.name.singular}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={updatePolicyForm.name ? updatePolicyForm.name : ''}
                        onChange={handleInputChange}
                        error={Boolean(updatePolicyFormErrors.name)}
                        helperText={updatePolicyFormErrors.name}
                    />

                    <StatementsCheckBoxGroupInput
                        label="Permisos"
                        name="statementIds"
                        value={statementsForm.statementIds}
                        error={statementsFormErrors.statementIds}
                        onChange={handleStatemetsInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updatePolicySelector.loading || attachStatementsPolicySelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updatePolicySelector.loading || attachStatementsPolicySelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
