import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { authenticate } from '../../../redux/authenticatorSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useNavigateSearch } from '../../../hooks';
import { useSearchParams } from 'react-router-dom';
import { validateSchemaField } from '../../../helpers/validation';
import { schema } from './schema';
import PasswordInput from 'app/components/PasswordInput';
import ErrorAlert from 'app/components/ErrorAlert';

export default function Login() {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const navigateSearch = useNavigateSearch()
  const authenticateSelector = useAppSelector(state => state.authenticator.authenticate)

  const [form, setForm] = React.useState({
    email: {
      error: '',
      value: searchParams.get('email') || ''
    },
    password: {
      error: '',
      value: ''
    }
  })

  React.useEffect(() => {
    if (
      authenticateSelector.loading == false &&
      authenticateSelector.error == '' &&
      authenticateSelector.done == true &&
      authenticateSelector.authenticated == true
    ) {
      window.location.reload()
    }
  }, [authenticateSelector])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validationError = validateSchemaField(schema, event.target.name, event.target.value)

    setForm({
      ...form,
      [event.target.name]: {
        error: validationError ? validationError : '',
        value: event.target.value
      }
    })
  }

  const handleSubmit = () => {
    dispatch(authenticate({
      email: form.email.value,
      password: form.password.value
    }))
  };

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h5">
        Iniciar sesión
      </Typography>
      <Box sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Correo electrónico"
          name="email"
          autoComplete="email"
          value={form.email.value}
          onChange={handleInputChange}
          error={Boolean(form.email.error)}
          helperText={form.email.error}
          autoFocus
        />
        <PasswordInput
          required
          label="Contraseña"
          name="password"
          value={form.password.value}
          error={form.password.error}
          onChange={handleInputChange}
          autoComplete="current-password"
        />

        <ErrorAlert
          shown={Boolean(authenticateSelector.error)}
          message={authenticateSelector.error}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={authenticateSelector.loading}
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit}
        >
          {authenticateSelector.loading ? 'Cargando' : 'Entrar'}
        </Button>

        <Grid container>
          <Grid item xs>
            <Link
              variant="body2"
              href='#'
              onClick={() => { navigateSearch('/auth/request-password-reset', { email: form.email.value }) }}>
              ¿Olvidaste la contraseña?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
