import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./test-version.entity";
import { ProtectedService } from "../../services/ProtectedService";
import { FindOneDto } from "./dto/find-one.dto";

export class TestVersionService extends ProtectedService {

  async findOne(dto: FindOneDto): Promise<EntitySchema> {
    const response = await this.axios.get(`/test-version/${dto.id}`, {
      params: {
        attributes: dto.attributes,
        join: dto.join
      }
    })

    return response.data
  }

  async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
    const response = await this.axios.get("/test-version", {
      params: dto,
    });

    return response.data;
  }
}
