import DashboardIcon from '@mui/icons-material/Dashboard';
import { Feature } from '../../types/feature.type';
import { masculineArticle } from '../../features/articles';
import { Entity } from './dashboard.entity';

const entity = new Entity()

export const dashboardFeature: Feature = {
    label: {
        singular: entity.name.singular,
        plural: entity.name.plural
    },
    menu: {
        grammaticalNumber: 'singular'
    },
    article: masculineArticle,
    icon: DashboardIcon,
    isAuthorized: true,
    entryLink: `/client/${entity.slug}`,
}
