import { useEffect, useState } from 'react'
import { MRT_ColumnFiltersState, MRT_SortingState, MRT_PaginationState } from "material-react-table";
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { findAllAction } from '../../test-application-record.slice';
import DataTable from '../../../../components/DataTable';
import ActionButtons, { ActionButton } from '../../../../components/ActionButtons';
import { AuthorizationService } from '../../../../services/AuthorizationService';
import PageHeader from '../../../../components/PageHeader';
import { TestApplicationRecordEntity, EntitySchema } from '../../test-application-record.entity';
import { testApplicationRecordFeature } from '../../test-application-record.feature';
import FormDialog from '../../../../components/FormDialog';
import Delete from '../Delete';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { FilterState } from 'app/types/pagination.dto';
import SelectCreationTarget from '../SelectCreationTarget';

const feature = testApplicationRecordFeature
const entity = new TestApplicationRecordEntity()

const joinQuery = entity.join?.map(entity => {
    return `${entity.entity}:${entity.attributes.join('|')}`
}).join(',')

const filterInitialState: FilterState = {
    state: [],
    query: undefined,
}

export default function List() {
    const dispatch = useAppDispatch()

    const listSelector = useAppSelector(state => state.testApplicationRecord.list)
    const deleteSelector = useAppSelector(state => state.testApplicationRecord.delete);
    const [selectedRow, setSelectedRow] = useState<EntitySchema | null>(null)
    const [sorting, setSorting] = useState<{ state: MRT_SortingState, query?: string }>()
    const [filter, setFilter] = useState<{ state: MRT_ColumnFiltersState, query?: string }>(filterInitialState)
    const [page, setPage] = useState<MRT_PaginationState>({
        pageIndex: listSelector.meta.page,
        pageSize: listSelector.meta.perPage
    })

    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const [refreshEnabled, setRefreshEnabled] = useState(false)
    const [createEnabled, setCreateEnabled] = useState(false)
    const [deleteEnabled, setDeleteEnabled] = useState(false)

    const [refreshVisible, setRefreshVisible] = useState(false)
    const [createVisible, setCreateVisible] = useState(false)
    const [deleteVisible, setDeleteVisible] = useState(false)

    const refreshData = () => {
        dispatch(findAllAction({
            page: page.pageIndex,
            perPage: page.pageSize,
            sortBy: sorting?.query,
            filter: filter?.query,
            join: joinQuery
        }))
    }

    useEffect(() => {
        const userIsAuthorizedToFindAll = AuthorizationService.userIsAuthorized('test-application-record:findAll')

        setRefreshVisible(userIsAuthorizedToFindAll)
        setRefreshEnabled(userIsAuthorizedToFindAll)

        setCreateVisible(AuthorizationService.userIsAuthorized('test-application-record:create'))
        setDeleteVisible(AuthorizationService.userIsAuthorized('test-application-record:create'))
    }, [])

    useEffect(() => {
        if(deleteSelector.done) setSelectedRow(null)
    }, [deleteSelector.done]);

    useEffect(() => {
        setCreateEnabled(!selectedRow)
        setDeleteEnabled(!!selectedRow)
    }, [selectedRow])

    useEffect(() => {
        refreshData()
    }, [sorting, filter, page])

    const handleRowSelected = (row: EntitySchema | null) => {
        setSelectedRow(row)
    }

    const handleSortingChange = (sorting: MRT_SortingState, query?: string) => {
        setSorting({
            state: sorting,
            query
        })
    }

    const handleFilterChange = (filter: MRT_ColumnFiltersState, query?: string) => {
        setFilter({
            state: filter,
            query
        })
    }

    const handlePageChange = (page: MRT_PaginationState) => {
        setPage({
            pageIndex: page.pageIndex + 1,
            pageSize: page.pageSize
        })
    }

    const handleCreateCancelled = () => {
        setCreateModalOpen(false)
    }

    const handleCreateCompleted = () => {
        setCreateModalOpen(false)
        refreshData()
    }

    const handleDeleteCancelled = () => {
        setDeleteModalOpen(false)
    }

    const handleDeleteCompleted = (deleted: boolean) => {
        if (deleted) {
            setDeleteModalOpen(false)
            refreshData()
        }
    }

    const leftButtons: ActionButton[] = [
        {
            label: 'Refrescar',
            icon: UpdateIcon,
            color: 'primary',
            isEnabled: refreshEnabled,
            isVisible: refreshVisible,
            onClick: () => refreshData()
        },
    ]

    const rightButtons: ActionButton[] = [
        {
            label: 'Crear',
            icon: AddIcon,
            color: 'primary',
            isEnabled: createEnabled,
            isVisible: createVisible,
            onClick: () => setCreateModalOpen(true)
        },
        {
            label: 'Eliminar',
            icon: DeleteIcon,
            color: 'error',
            isEnabled: deleteEnabled,
            isVisible: deleteVisible,
            onClick: () => setDeleteModalOpen(true)
        }
    ]

    return (
        <>
            <PageHeader
                title={feature.label.plural}
                icon={feature.icon}
            />

            <ActionButtons
                leftButtons={leftButtons}
                rightButtons={rightButtons}
            />

            <DataTable
                entity={entity}
                dataTable={listSelector}
                sorting={sorting?.state}

                onRowSelected={handleRowSelected}
                onSortingChange={handleSortingChange}
                onFilterChange={handleFilterChange}
                onPageChange={handlePageChange}
            />

            <FormDialog open={createModalOpen}>
                <SelectCreationTarget
                    onCancelled={handleCreateCancelled}
                    onCompleted={handleCreateCompleted}
                />
            </FormDialog>

            {
                selectedRow && (
                    <>
                        <FormDialog open={deleteModalOpen}>
                            <Delete
                                id={selectedRow?.id || ''}
                                onCancelled={handleDeleteCancelled}
                                onCompleted={handleDeleteCompleted}
                            />
                        </FormDialog>
                    </>
                )
            }
        </>
    );
}
