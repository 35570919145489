import Joi from "joi";

export const schema = Joi.object({
    password: Joi
        .string()
        .label('Nueva contraseña')
        .min(3)
        .max(255)
        .required(),
})
