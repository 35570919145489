import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./contract.entity";
import { ProtectedService } from "../../services/ProtectedService";

export class ContractService extends ProtectedService {
    async findOne(id: string): Promise<EntitySchema> {
        const response = await this.axios.get(`/contract/${id}`)

        return response.data
    }

    async findOneByOrganization(organizationId: string): Promise<EntitySchema> {
        const response = await this.axios.get(`/contract/organization/${organizationId}`)

        return response.data
    }

    async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
        const response = await this.axios.get('/contract', {
            params: dto
        })

        return response.data
    }
}
