import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./policy.entity";
import { PolicyService } from "./policy.service";
import { RootState } from "../../redux/store";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { CreateDto } from "./pages/Create/create.dto";
import { UpdateDto, UpdateInfo } from './pages/Update/update.dto'
import { AttachStatementsDto } from "./dto/attach-statements.dto";
import { FindOneDto } from "./dto/find-one.dto";

const sliceName = "policy";

const initialActionState = {
  loading: false,
  error: '',
  done: false
}

const initialMetaState = {
  page: 1,
  perPage: 20,
  itemCount: 0,
  pageCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
}


const initialUpdate: UpdateDto = {
  name: ''
}

const initialCreateState = { ...initialActionState, entity: null as EntitySchema | null }
const initialViewState = { ...initialActionState, entity: null as EntitySchema | null }
const initialListState = { ...initialActionState, data: [] as EntitySchema[], meta: initialMetaState }
const initialUpdateState = { ...initialActionState, data: initialUpdate }
const initialDeleteState = initialActionState
const initialAttachStatementsState = initialActionState

const initialState = {
  create: initialCreateState,
  view: initialViewState,
  list: initialListState,
  update: initialUpdateState,
  delete: initialDeleteState,
  attachStatements: initialAttachStatementsState
}

const findOneAction = createAsyncThunk(
  `${sliceName}/findOne`,
  async (input: FindOneDto) => {
    const service = new PolicyService()

    return service.findOne(input)
  }
)

const findAllAction = createAsyncThunk(
  `${sliceName}/findAll`,
  async (input: ListDto) => {
    const service = new PolicyService();

    return service.findAll(input);
  }
)

const createAction = createAsyncThunk(
  `${sliceName}/create`,
  (input: CreateDto) => {
    const service = new PolicyService()

    return service.create(input)
  }
)

const updateAction = createAsyncThunk(
  `${sliceName}/update`,
  async (input: UpdateInfo) => {
    const { id, dto } = input
    const service = new PolicyService()

    return service.update(id, dto)
  }
)

const deleteAction = createAsyncThunk(
  `${sliceName}/delete`,
  (input: DeleteDto) => {
    const service = new PolicyService()

    return service.delete(input)
  }
)

const attachStatementsAction = createAsyncThunk(
  `${sliceName}/attachStatements`,
  (input: AttachStatementsDto) => {
    const service = new PolicyService()

    return service.attachStatements(input)
  }
)

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetCreateAction: (state) => {
      state.create = initialCreateState
    },
    resetListAction: (state) => {
      state.list = initialListState
    },
    resetDeleteAction: (state) => {
      state.delete = initialDeleteState
    },
    resetUpdateAction: (state) => {
      state.update = initialUpdateState
    },
    resetAttachStatementsAction: (state) => {
      state.attachStatements = initialAttachStatementsState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAction.pending, (state) => {
        state.create.loading = true;
        state.create.error = ''
        state.create.done = false
        state.create.entity = null
      })
      .addCase(createAction.fulfilled, (state, action) => {
        state.create.loading = false;
        state.create.error = ''
        state.create.done = true
        state.create.entity = action.payload
      })
      .addCase(createAction.rejected, (state, action) => {
        state.create.loading = false;
        state.create.error = action.error.message || 'Someting wrong occured'
        state.create.done = false
        state.create.entity = null
      })

      .addCase(findAllAction.pending, (state) => {
        state.list.loading = true;
        state.list.error = "";
        state.list.data = []
        state.list.meta = initialMetaState
      })
      .addCase(findAllAction.fulfilled, (state, action) => {
        state.list.loading = false;
        state.list.error = "";
        state.list.data = action.payload.data;
        state.list.meta = action.payload.meta;
      })
      .addCase(findAllAction.rejected, (state, action) => {
        state.list.loading = false;
        state.list.error = action.error.message || "Someting wrong occured";
        state.list.data = []
        state.list.meta = initialMetaState
      })

      .addCase(findOneAction.pending, (state) => {
        state.view.loading = true;
        state.view.error = ''
        state.view.entity = null
      })
      .addCase(findOneAction.fulfilled, (state, action) => {
        state.view.loading = false;
        state.view.error = ''
        state.view.entity = action.payload
      })
      .addCase(findOneAction.rejected, (state, action) => {
        state.view.loading = false;
        state.view.error = action.error.message || 'Someting wrong occured'
        state.view.entity = null
      })

      .addCase(updateAction.pending, (state) => {
        state.update.loading = true;
        state.update.error = ''
        state.update.done = false
      })
      .addCase(updateAction.fulfilled, (state, action) => {
        state.update.loading = false;
        state.update.error = ''
        state.update.done = action.payload
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.update.loading = false;
        state.update.error = action.error.message || 'Someting wrong occured'
        state.update.done = false
      })

      .addCase(deleteAction.pending, (state) => {
        state.delete.loading = true;
        state.delete.error = ''
        state.delete.done = false
      })
      .addCase(deleteAction.fulfilled, (state, action) => {
        state.delete.loading = false;
        state.delete.error = ''
        state.delete.done = action.payload
      })
      .addCase(deleteAction.rejected, (state, action) => {
        state.delete.loading = false;
        state.delete.error = action.error.message || 'Someting wrong occured'
        state.delete.done = false
      })

      .addCase(attachStatementsAction.pending, (state) => {
        state.attachStatements.loading = true;
        state.attachStatements.error = ''
        state.attachStatements.done = false
      })
      .addCase(attachStatementsAction.fulfilled, (state, action) => {
        state.attachStatements.loading = false;
        state.attachStatements.error = ''
        state.attachStatements.done = action.payload
      })
      .addCase(attachStatementsAction.rejected, (state, action) => {
        state.attachStatements.loading = false;
        state.attachStatements.error = action.error.message || 'Someting wrong occured'
        state.attachStatements.done = false
      })
  },
});

export { findOneAction, findAllAction, createAction, deleteAction, updateAction, attachStatementsAction };

export const { resetListAction, resetCreateAction, resetDeleteAction, resetUpdateAction, resetAttachStatementsAction } = slice.actions;
export const selector = (state: RootState) => state.policy;
export default slice.reducer;
