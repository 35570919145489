import { JoinDefinition } from "app/types/join.type";
import {
  EntityAttribute,
  EntityName,
  UiEntity,
} from "../../interfaces/EntityInterface";

export type EntitySchema = {
  id?: string;
  name: string;
  key: string;
  createdAt: string;
  updatedAt: string;
};

export const entityInitialState = {
  id: undefined,
  name: "",
  key: "",
  createdAt: "",
  updatedAt: "",
};

export class Entity implements UiEntity {
  slug: string = "statement";

  join?: JoinDefinition[] | undefined;
  name: EntityName = {
    singular: "Permiso",
    plural: "Permisos",
  };

  primaryKeyAttribute: string = "id";

  linkedToViewAttribute: string = "name";

  attributes: EntityAttribute[] = [
    {
      key: "id",
      label: "ID",
      dataType: "string",
      showInTable: false,
      // order: 0,
    },
    {
      key: "name",
      label: "Nombre",
      dataType: "string",
      showInTable: true,
      // order: 1,
    },
    {
      key: "key",
      label: "Llave",
      dataType: "string",
      showInTable: true,
      // order: 1,
    },
    {
      key: "createdAt",
      label: "Creado",
      dataType: "date",
      showInTable: true,
      // order: 3,
    },
    {
      key: "updatedAt",
      label: "Actualizado",
      dataType: "date",
      showInTable: false,
      // order: 4,
    },
  ];
}
