import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'
import { ContractService } from './contract.service'
import { EntitySchema } from './contract.entity'
import { ListDto } from './pages/List/list.dto'
import { initialActionState, initialCreateState, initialDeleteState, initialListState, initialUpdateState, initialViewState } from 'app/redux/helpers/state.helper'

const sliceName = 'contract'

const initialAutocompleteState = {
    ...initialActionState,
    data: [] as EntitySchema[]
}

const initialState = {
    list: initialListState<EntitySchema>(),
    view: initialViewState<EntitySchema>(),
    viewByOrganization: initialViewState<EntitySchema>(),
    autocomplete: initialAutocompleteState
}

const findOneAction = createAsyncThunk(
    `${sliceName}/findOne`,
    async (id: string) => {
        const service = new ContractService()

        return service.findOne(id)
    }
)

const findOneByOrganizationAction = createAsyncThunk(
    `${sliceName}/findOneByOrganization`,
    async (organizationId: string) => {
        const service = new ContractService()

        return service.findOneByOrganization(organizationId)
    }
)

const findAllAction = createAsyncThunk(
    `${sliceName}/findAll`,
    (input: ListDto) => {
        const service = new ContractService()

        return service.findAll(input)
    }
)

const autocompleteAction = createAsyncThunk(
    `${sliceName}/autocomplete`,
    async (input: ListDto) => {
        const service = new ContractService()

        return service.findAll(input)
    }
)

export const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetListAction: (state) => {
            state.list = initialState.list
        },
        resetViewAction: (state) => {
            state.view = initialState.view
        },
        resetViewByOrganizationAction: (state) => {
            state.viewByOrganization = initialState.viewByOrganization
        },
        resetAutocompleteAction: (state) => {
            state.autocomplete = initialState.autocomplete
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findAllAction.pending, (state) => {
                state.list.loading = true;
                state.list.done = false
                state.list.error = initialState.list.error
                state.list.data = initialState.list.data
                state.list.meta = initialState.list.meta
            })
            .addCase(findAllAction.fulfilled, (state, action) => {
                state.list.loading = false;
                state.list.done = true
                state.list.error = initialState.list.error
                state.list.data = action.payload.data
                state.list.meta = action.payload.meta
            })
            .addCase(findAllAction.rejected, (state, action) => {
                state.list.loading = false;
                state.list.done = false
                state.list.error = action.error.message || 'Someting wrong occured'
                state.list.data = initialState.list.data
                state.list.meta = initialState.list.meta
            })

            .addCase(findOneAction.pending, (state) => {
                state.view.loading = true;
                state.view.done = false
                state.view.error = initialState.view.error
                state.view.data = initialState.view.data
            })
            .addCase(findOneAction.fulfilled, (state, action) => {
                state.view.loading = false;
                state.view.done = true
                state.view.error = initialState.view.error
                state.view.data = action.payload
            })
            .addCase(findOneAction.rejected, (state, action) => {
                state.view.loading = false;
                state.view.done = true
                state.view.error = action.error.message || 'Someting wrong occured'
                state.view.data = initialState.view.data
            })

            .addCase(findOneByOrganizationAction.pending, (state) => {
                state.viewByOrganization.loading = true;
                state.viewByOrganization.done = false
                state.viewByOrganization.error = initialState.viewByOrganization.error
                state.viewByOrganization.data = initialState.viewByOrganization.data
            })
            .addCase(findOneByOrganizationAction.fulfilled, (state, action) => {
                state.viewByOrganization.loading = false;
                state.viewByOrganization.done = true
                state.viewByOrganization.error = initialState.viewByOrganization.error
                state.viewByOrganization.data = action.payload
            })
            .addCase(findOneByOrganizationAction.rejected, (state, action) => {
                state.viewByOrganization.loading = false;
                state.viewByOrganization.done = true
                state.viewByOrganization.error = action.error.message || 'Someting wrong occured'
                state.viewByOrganization.data = initialState.viewByOrganization.data
            })

            .addCase(autocompleteAction.pending, (state) => {
                state.autocomplete.loading = true;
                state.autocomplete.done = false
                state.autocomplete.error = initialState.autocomplete.error
                state.autocomplete.data = initialState.autocomplete.data
            })
            .addCase(autocompleteAction.fulfilled, (state, action) => {
                state.autocomplete.loading = false;
                state.autocomplete.done = true
                state.autocomplete.error = initialState.autocomplete.error
                state.autocomplete.data = action.payload.data
            })
            .addCase(autocompleteAction.rejected, (state, action) => {
                state.autocomplete.loading = false;
                state.autocomplete.done = false
                state.autocomplete.error = action.error.message || 'Someting wrong occured'
                state.autocomplete.data = initialState.autocomplete.data
            })
    }
})

export {
    findOneAction,
    findOneByOrganizationAction,
    findAllAction,
    autocompleteAction
}

export const { resetListAction, resetViewAction, resetAutocompleteAction } = slice.actions
export const selector = (state: RootState) => state.contract
export default slice.reducer;
