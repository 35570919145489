import PageHeader from "app/components/PageHeader";
import { dashboardFeature } from "../dashboard.feature";

const feature = dashboardFeature;

export default function Dashboard() {
    return (
        <>
            <PageHeader title={feature.label.singular} icon={feature.icon} />
        </>
    )
}