import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./statement.entity";
import { StatementService } from "./statement.service";
import { RootState } from "../../redux/store";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { CreateDto } from "./pages/Create/create.dto";
import { UpdateDto, UpdateInfo } from './pages/Update/update.dto'

const sliceName = "statement";

const initialActionState = {
  loading: false,
  error: '',
  done: false
}

const initialMetaState = {
  page: 1,
  perPage: 20,
  itemCount: 0,
  pageCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
}

const initialUpdate: UpdateDto = {
  name: '',
  key: ''
}

const initialCreateState = { ...initialActionState, entity: null as EntitySchema | null }
const initialViewState = { ...initialActionState, entity: null as EntitySchema | null }
const initialListState = { ...initialActionState, data: [] as EntitySchema[], meta: initialMetaState }
const initialUpdateState = { ...initialActionState, data: initialUpdate }
const initialDeleteState = initialActionState

const initialState = {
  create: initialCreateState,
  view: initialViewState,
  list: initialListState,
  update: initialUpdateState,
  delete: initialDeleteState,
}

const findOneAction = createAsyncThunk(
  `${sliceName}/findOne`,
  async (id: string) => {
    const service = new StatementService()

    return service.findOne(id)
  }
)

const findAllAction = createAsyncThunk(
  `${sliceName}/findAll`,
  async (input: ListDto) => {
    const service = new StatementService();

    return service.findAll(input);
  }
);

const createAction = createAsyncThunk(
  `${sliceName}/create`,
  (input: CreateDto) => {
    const service = new StatementService()

    return service.create(input)
  }
)

const updateAction = createAsyncThunk(
  `${sliceName}/update`,
  async (input: UpdateInfo) => {
    const { id, dto } = input
    const service = new StatementService()

    return service.update(id, dto)
  }
)

const deleteAction = createAsyncThunk(
  `${sliceName}/delete`,
  (input: DeleteDto) => {
    const service = new StatementService()

    return service.delete(input)
  }
)

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetListAction: (state) => {
      state.list = initialListState
    },
    resetCreateAction: (state) => {
      state.create = initialCreateState
    },
    resetDeleteAction: (state) => {
      state.delete = initialDeleteState
    },
    resetUpdateAction: (state) => {
      state.update = initialUpdateState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAction.pending, (state) => {
        state.create.loading = true;
        state.create.error = ''
        state.create.done = false
        state.create.entity = null
      })
      .addCase(createAction.fulfilled, (state, action) => {
        state.create.loading = false;
        state.create.error = ''
        state.create.done = true
        state.create.entity = action.payload
      })
      .addCase(createAction.rejected, (state, action) => {
        state.create.loading = false;
        state.create.error = action.error.message || 'Someting wrong occured'
        state.create.done = false
        state.create.entity = null
      })

      .addCase(findAllAction.pending, (state) => {
        state.list.loading = true;
        state.list.error = "";
        state.list.data = []
        state.list.meta = initialMetaState
      })
      .addCase(findAllAction.fulfilled, (state, action) => {
        state.list.loading = false;
        state.list.error = "";
        state.list.data = action.payload.data;
        state.list.meta = action.payload.meta;
      })
      .addCase(findAllAction.rejected, (state, action) => {
        state.list.loading = false;
        state.list.error = action.error.message || "Someting wrong occured";
        state.list.data = []
        state.list.meta = initialMetaState
      })

      .addCase(findOneAction.pending, (state) => {
        state.view.loading = true;
        state.view.error = ''
        state.view.entity = null
      })
      .addCase(findOneAction.fulfilled, (state, action) => {
        state.view.loading = false;
        state.view.error = ''
        state.view.entity = action.payload
      })
      .addCase(findOneAction.rejected, (state, action) => {
        state.view.loading = false;
        state.view.error = action.error.message || 'Someting wrong occured'
        state.view.entity = null
      })

      .addCase(updateAction.pending, (state) => {
        state.update.loading = true;
        state.update.error = ''
        state.update.done = false
      })
      .addCase(updateAction.fulfilled, (state, action) => {
        state.update.loading = false;
        state.update.error = ''
        state.update.done = action.payload
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.update.loading = false;
        state.update.error = action.error.message || 'Someting wrong occured'
        state.update.done = false
      })

      .addCase(deleteAction.pending, (state) => {
        state.delete.loading = true;
        state.delete.error = ''
        state.delete.done = false
      })
      .addCase(deleteAction.fulfilled, (state, action) => {
        state.delete.loading = false;
        state.delete.error = ''
        state.delete.done = action.payload
      })
      .addCase(deleteAction.rejected, (state, action) => {
        state.delete.loading = false;
        state.delete.error = action.error.message || 'Someting wrong occured'
        state.delete.done = false
      })
  },
});

export { findOneAction, findAllAction, createAction, deleteAction, updateAction };

export const { resetListAction, resetCreateAction, resetDeleteAction, resetUpdateAction } = slice.actions;
export const selector = (state: RootState) => state.statement;
export default slice.reducer;
