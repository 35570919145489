import Joi from "joi";
import { CreateDto } from "./create.dto";

export const schema = Joi.object<CreateDto, true>({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required(),
})
