import AutoCompleteGroupInput, { AutoCompleteGroupOption } from 'app/components/AutoCompleteGroupInput';
import { useAppDispatch, useAppSelector } from "app/redux/hooks";
import { useEffect, useState } from 'react';
import { userEmployeeFeature } from '../../user-employee.feature';
import { autocompleteAction } from "../../user-employee.slice";

export type Props = {
    name: string
    organizationalUnitId: string
    value?: string | null
    onSelect: (name: string, value?: string) => void
    error?: string | null
}

export default function UserEmployeeAutoComplete(props: Props) {
    const dispatch = useAppDispatch()
    const listSelector = useAppSelector(state => state.userEmployee.autocomplete.data)
    const [options, setOptions] = useState<AutoCompleteGroupOption[]>([])

    const mapOptions = () => {
        const options: AutoCompleteGroupOption[] = listSelector
            .map(row => ({
                id: row.id,
                label: `${row.name} ${row.lastName}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))

        setOptions(options)
    }

    const [queryParams, setQueryParams] = useState({
        organizationalUnitId: props.organizationalUnitId
    })

    useEffect(() => {
        dispatch(
            autocompleteAction(props.organizationalUnitId)
        )
    }, [props.organizationalUnitId])

    useEffect(() => {
        mapOptions()
    }, [listSelector])

    useEffect(() => {
        if (!queryParams.organizationalUnitId && !props.value) return

        dispatch(autocompleteAction(props.organizationalUnitId))
        }, [queryParams.organizationalUnitId, props.value])

    const handleAutoCompleteChange = (value: string) => {
        if (!value) return

        dispatch(
            autocompleteAction(props.organizationalUnitId)
        )
    }

    const handleAutoCompleteSelect = (name: string, value?: AutoCompleteGroupOption) => {
        props.onSelect(name, value?.id)
    }

    return <AutoCompleteGroupInput
            name={props.name}
            defaultValue={props.value || undefined}
            searchInputlabel={`Escriba el nombre de ${userEmployeeFeature.article.definite.singular} ${userEmployeeFeature.label.singular}`}
            searchInputPlaceHoler={`Escriba el nombre de ${userEmployeeFeature.article.definite.singular} ${userEmployeeFeature.label.singular}`}
            group={false}
            options={options}
            noOptionsText={`No hay ${userEmployeeFeature.label.plural} para mostrar`}
            onAutoCompleteChange={handleAutoCompleteChange}
            onSelect={handleAutoCompleteSelect}
            error={props.error || undefined}
        />
}
