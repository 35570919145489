import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./assessment.entity";
import { RootState } from "../../redux/store";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { CreateDto } from "./pages/Create/create.dto";
import { UpdateDto, UpdateInfo } from './pages/Update/update.dto'
import { AssessmentService } from "./assessment.service";

const sliceName = "assessment";

const initialDataTableState = {
  loading: false,
  error: "",

  data: [] as EntitySchema[],
  meta: {
    page: 1,
    perPage: 20,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  },
};

const initialActionState = {
  loading: false,
  error: '',
  done: false
}

const initialUpdate: UpdateDto = {
  name: ''
}

const initialCreateState = initialActionState
const initialUpdateState = { ...initialActionState, data: initialUpdate }
const initialDeleteState = initialActionState

const initialState = {
  dataTable: initialDataTableState,
  create: initialCreateState,
  update: initialUpdateState,
  delete: initialDeleteState,
}

const findOneAction = createAsyncThunk(
  `${sliceName}/findOne`,
  async (id: string) => {
    const service = new AssessmentService()

    return service.findOne(id)
  }
)

const findAllAction = createAsyncThunk(
  `${sliceName}/findAll`,
  async (input: ListDto) => {
    const service = new AssessmentService();

    return service.findAll(input);
  }
)

const createAction = createAsyncThunk(
  `${sliceName}/create`,
  (input: CreateDto) => {
    const service = new AssessmentService()

    return service.create(input)
  }
)

const updateAction = createAsyncThunk(
  `${sliceName}/update`,
  async (input: UpdateInfo) => {
    const { id, dto } = input
    const service = new AssessmentService()

    return service.update(id, dto)
  }
)

const deleteAction = createAsyncThunk(
  `${sliceName}/delete`,
  (input: DeleteDto) => {
    const service = new AssessmentService()

    return service.delete(input)
  }
)

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetDataTableAction: (state) => {
      state.dataTable = initialDataTableState
    },
    resetCreateAction: (state) => {
      state.create = initialCreateState
    },
    resetDeleteAction: (state) => {
      state.delete = initialDeleteState
    },
    resetUpdateAction: (state) => {
      state.update = initialUpdateState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findOneAction.pending, (state) => {
        state.dataTable.loading = true;
        state.dataTable.error = ''
      })
      .addCase(findOneAction.fulfilled, (state, action) => {
        state.dataTable.loading = false;
        state.dataTable.error = ''
        state.update.data = action.payload
      })
      .addCase(findOneAction.rejected, (state, action) => {
        state.dataTable.loading = false;
        state.dataTable.error = action.error.message || 'Someting wrong occured'
      })

      .addCase(findAllAction.pending, (state) => {
        state.dataTable.loading = true;
        state.dataTable.error = "";
      })
      .addCase(findAllAction.fulfilled, (state, action) => {
        state.dataTable.loading = false;
        state.dataTable.error = "";
        state.dataTable.data = action.payload.data;
        state.dataTable.meta = action.payload.meta;
      })
      .addCase(findAllAction.rejected, (state, action) => {
        state.dataTable.loading = false;
        state.dataTable.error =
          action.error.message || "Someting wrong occured";
      })

      .addCase(createAction.pending, (state) => {
        state.create.loading = true;
        state.create.error = ''
        state.create.done = false
      })
      .addCase(createAction.fulfilled, (state, action) => {
        state.create.loading = false;
        state.create.error = ''
        state.create.done = action.payload
      })
      .addCase(createAction.rejected, (state, action) => {
        state.create.loading = false;
        state.create.error = action.error.message || 'Someting wrong occured'
        state.create.done = false
      })

      .addCase(deleteAction.pending, (state) => {
        state.delete.loading = true;
        state.delete.error = ''
        state.delete.done = false
      })
      .addCase(deleteAction.fulfilled, (state, action) => {
        state.delete.loading = false;
        state.delete.error = ''
        state.delete.done = action.payload
      })
      .addCase(deleteAction.rejected, (state, action) => {
        state.delete.loading = false;
        state.delete.error = action.error.message || 'Someting wrong occured'
        state.delete.done = false
      })

      .addCase(updateAction.pending, (state) => {
        state.update.loading = true;
        state.update.error = ''
        state.update.done = false
      })
      .addCase(updateAction.fulfilled, (state, action) => {
        state.update.loading = false;
        state.update.error = ''
        state.update.done = action.payload
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.update.loading = false;
        state.update.error = action.error.message || 'Someting wrong occured'
        state.update.done = false
      })
  },
});

export { findOneAction, findAllAction, createAction, deleteAction, updateAction };

export const { resetDataTableAction, resetCreateAction, resetDeleteAction, resetUpdateAction } = slice.actions;
export const selector = (state: RootState) => state.assessment;
export default slice.reducer;
