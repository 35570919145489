import Joi from "joi";

export const schema = Joi.object({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required(),
    lastName: Joi
        .string()
        .label('Apellido')
        .min(3)
        .max(255)
        .required(),
    email: Joi
        .string()
        .label('Correo electrónico')
        .email({ tlds: { allow: false } })
        .required()
})
