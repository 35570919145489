import AutoCompleteGroupInput, { AutoCompleteGroupOption } from "app/components/AutoCompleteGroupInput"
import { organizationFeature } from "../../organization.feature"
import { autocompleteAction } from "../../organization.slice"
import { useEffect, useState } from "react"
import { useQuery } from "app/hooks"
import { useAppDispatch, useAppSelector } from "app/redux/hooks"

type Props = {
    name: string
    value?: string | null
    onSelect: (name: string, value?: string) => void
    error?: string | null
}

export default function OrganizationAutoCompleteInput(props: Props) {
    const dispatch = useAppDispatch()
    const query = useQuery()
    const organizations = useAppSelector(state => state.organization.autocomplete.data)
    const [organizationOptions, setOrganizationOptions] = useState<AutoCompleteGroupOption[]>([])

    const mapOptions = () => {
        const options: AutoCompleteGroupOption[] = organizations
            .map(row => ({
                id: row.id,
                label: row.name
            }))
            .sort((a, b) => a.label.localeCompare(b.label))

        setOrganizationOptions(options)
    }

    const [queryParams] = useState({
        organizationId: query.get('organizationId'),
    })

    useEffect(() => {
        mapOptions()
    }, [organizations])

    useEffect(() => {
        if (!queryParams.organizationId && !props.value) return

        const organizationId = queryParams.organizationId || props.value

        dispatch(
            autocompleteAction({
                page: 1,
                perPage: 15,
                filter: `id:${organizationId}`
            })
        )
    }, [queryParams.organizationId, props.value])

    const handleAutoCompleteChange = (value: string) => {
        if (!value) return

        dispatch(
            autocompleteAction({
                page: 1,
                perPage: 15,
                filter: `name:${value}`
            })
        )
    }

    const handleAutoCompleteSelect = (name: string, value?: AutoCompleteGroupOption) => {
        props.onSelect(name, value?.id)
    }

    return (
        <AutoCompleteGroupInput
            name={props.name}
            defaultValue={props.value || undefined}
            searchInputlabel={`Escriba el nombre de ${organizationFeature.article.definite.singular} ${organizationFeature.label.singular}`}
            searchInputPlaceHoler={`Escriba el nombre de ${organizationFeature.article.definite.singular} ${organizationFeature.label.singular}`}
            group={false}
            options={organizationOptions}
            noOptionsText={`No hay ${organizationFeature.label.plural} para mostrar`}
            readonly={!!queryParams.organizationId}
            disabled={!!queryParams.organizationId}
            onAutoCompleteChange={handleAutoCompleteChange}
            onSelect={handleAutoCompleteSelect}
            error={props.error || undefined}
        />
    )
}
