import PsychologyIcon from '@mui/icons-material/Psychology';
import { Feature } from '../../types/feature.type';
import { feminineArticle } from '../../features/articles';
import { Entity } from './test.entity';
import { AuthorizationService } from '../../services/AuthorizationService';

const entity = new Entity()

export const testFeature: Feature = {
    label: {
        singular: entity.name.singular,
        plural: entity.name.plural
    },
    menu: {
        grammaticalNumber: 'plural'
    },
    article: feminineArticle,
    icon: PsychologyIcon,
    isAuthorized: AuthorizationService.userIsAuthorized('test:findAll'),
    entryLink: `/client/${entity.slug}`,
}
