import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "app/redux/hooks";
import { mapValidationErrors, validateForm } from "app/helpers/validation";
import { formLabels, schema } from "./schema";
import { initialSociodemographicInfo, getSociodemographicInfoAction, updateSociodemographicInfoAction } from "../../profile.slice";
import { AuthorizationService } from "app/services/AuthorizationService";
import { UpdateSociodemographicInfoDto } from "./update-sociodemographic-info.dto";
import SelectInput from "app/components/SelectInput";
import { SelectChangeEvent } from "@mui/material/Select";
import DatePickerInput from "app/components/DatePickerInput";

const formInitialState = initialSociodemographicInfo
const errorsInitialState = {
    ...formInitialState,
    hasReportingPeopleToYouMorePeopleInCharge: ''
}

export function UpdateSociodemographicInfo() {
    const dispatch = useAppDispatch()
    const getSociodemographicInfoSelector = useAppSelector(state => state.profile.getSociodemographicInfo)
    const updateSociodemographicInfoSelector = useAppSelector(state => state.profile.updateSociodemographicInfo)
    const [canUpdateSociodemographicInfoInfo, setCanUpdateSociodemographicInfo] = useState(false)

    const [form, setForm] = useState<UpdateSociodemographicInfoDto>(formInitialState)
    const [errors, setErrors] = useState(errorsInitialState)

    useEffect(() => {
        dispatch(
            getSociodemographicInfoAction()
        )

        setCanUpdateSociodemographicInfo(AuthorizationService.userIsAuthorized('my-profile:update-sociodemographic-information'))
    }, [])

    useEffect(() => {
        setForm(getSociodemographicInfoSelector.data)
    }, [getSociodemographicInfoSelector.data])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleDatePickerInputChange = (name: string, value: string | null) => {
        setForm({
            ...form,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setErrors({
                ...errorsInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                updateSociodemographicInfoAction(form)
            )
        }
    };

    return (

        <Card elevation={0} sx={{ width: 800 }}>
            <CardHeader
                title={`Actualizar Información Sociodemográfica`}
            />

            <CardContent>
                <TextField
                    fullWidth
                    margin="normal"
                    type="text"
                    name="alias"
                    label={formLabels.alias}
                    value={form.alias}
                    onChange={handleInputChange}
                    error={Boolean(errors.alias)}
                    helperText={errors.alias}
                    InputLabelProps={{ shrink: !!form.alias }}
                />

                <SelectInput
                    fullWidth
                    name="identificationType"
                    label={formLabels.identificationType}
                    error={errors.identificationType || ''}
                    value={form.identificationType}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Tarjeta de identificación',
                            value: 'Tarjeta de identificación'
                        },
                        {
                            label: 'Cédula de ciudadanía',
                            value: 'Cédula de ciudadanía'
                        },
                        {
                            label: 'Cédula de extranjería',
                            value: 'Cédula de extranjería'
                        },
                        {
                            label: 'Documento de identificación extranjero',
                            value: 'Documento de identificación extranjero'
                        },
                        {
                            label: 'Pasaporte',
                            value: 'Pasaporte'
                        }
                    ]}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    type="number"
                    name="identificationNumber"
                    label={formLabels.identificationNumber}
                    value={form.identificationNumber}
                    onChange={handleInputChange}
                    error={Boolean(errors.identificationNumber)}
                    helperText={errors.identificationNumber}
                    InputLabelProps={{ shrink: !!form.identificationNumber }}
                />

                <DatePickerInput
                    fullWidth
                    name="birthDay"
                    label={formLabels.birthDay}
                    value={form.birthDay || ''}
                    error={Boolean(errors.birthDay)}
                    helperText={errors.birthDay}
                    onChange={handleDatePickerInputChange}
                />

                <SelectInput
                    fullWidth
                    name="gender"
                    label={formLabels.gender}
                    error={errors.gender || ''}
                    value={form.gender}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Femenino',
                            value: 'Femenino'
                        },
                        {
                            label: 'Masculino',
                            value: 'Masculino'
                        },
                        {
                            label: 'No binario',
                            value: 'No binario'
                        },
                        {
                            label: 'Otro',
                            value: 'Otro'
                        }
                    ]}
                />

                <SelectInput
                    fullWidth
                    name="maritalStatus"
                    label={formLabels.maritalStatus}
                    error={errors.maritalStatus || ''}
                    value={form.maritalStatus}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Soltero',
                            value: 'Soltero'
                        },
                        {
                            label: 'Casado',
                            value: 'Casado'
                        },
                        {
                            label: 'Conviviente',
                            value: 'Conviviente'
                        },
                        {
                            label: 'Anulado',
                            value: 'Anulado'
                        },
                        {
                            label: 'Separado de Unión Legal',
                            value: 'Separado de Unión Legal'
                        },
                        {
                            label: 'Separado de Unión de Hecho',
                            value: 'Separado de Unión de Hecho'
                        },
                        {
                            label: 'Viudo',
                            value: 'Viudo'
                        }
                    ]}
                />

                <SelectInput
                    fullWidth
                    name="typeOfPropertyOwn"
                    label={formLabels.typeOfPropertyOwn}
                    error={errors.typeOfPropertyOwn || ''}
                    value={form.typeOfPropertyOwn}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Propia',
                            value: 'Propia'
                        },
                        {
                            label: 'Arrendada',
                            value: 'Arrendada'
                        }
                    ]}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    type="number"
                    name="dependentsQuantity"
                    label={formLabels.dependentsQuantity}
                    value={form.dependentsQuantity}
                    onChange={handleInputChange}
                    error={Boolean(errors.dependentsQuantity)}
                    helperText={errors.dependentsQuantity}
                    InputLabelProps={{ shrink: !!form.dependentsQuantity }}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    type="number"
                    name="childrenQuantity"
                    label={formLabels.childrenQuantity}
                    value={form.childrenQuantity}
                    onChange={handleInputChange}
                    error={Boolean(errors.childrenQuantity)}
                    helperText={errors.childrenQuantity}
                    InputLabelProps={{ shrink: !!form.childrenQuantity }}
                />

                <SelectInput
                    fullWidth
                    name="socioeconomicStratum"
                    label={formLabels.socioeconomicStratum}
                    error={errors.socioeconomicStratum || ''}
                    value={form.socioeconomicStratum}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: '1',
                            value: '1'
                        },
                        {
                            label: '2',
                            value: '2'
                        },
                        {
                            label: '3',
                            value: '3'
                        },
                        {
                            label: '4',
                            value: '4'
                        },
                        {
                            label: '5',
                            value: '5'
                        },
                        {
                            label: '6',
                            value: '6'
                        },
                        {
                            label: 'No estratificado',
                            value: '0'
                        }
                    ]}
                />

                <SelectInput
                    fullWidth
                    name="highestLevelOfEducationCompleted"
                    label={formLabels.highestLevelOfEducationCompleted}
                    error={errors.highestLevelOfEducationCompleted || ''}
                    value={form.highestLevelOfEducationCompleted}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Primaria',
                            value: 'Primaria'
                        },
                        {
                            label: 'Media',
                            value: 'Media'
                        },
                        {
                            label: 'Bachillerato',
                            value: 'Bachillerato'
                        },
                        {
                            label: 'Técnico',
                            value: 'Técnico'
                        },
                        {
                            label: 'Técnologico',
                            value: 'Técnologico'
                        },
                        {
                            label: 'Universitario',
                            value: 'Universitario'
                        },
                        {
                            label: 'Especialización',
                            value: 'Especialización'
                        },
                        {
                            label: 'Maestria',
                            value: 'Maestria'
                        },
                        {
                            label: 'Doctorado',
                            value: 'Doctorado'
                        },
                    ]}
                />

                <SelectInput
                    fullWidth
                    name="countryOfResidence"
                    label={formLabels.countryOfResidence}
                    error={errors.countryOfResidence || ''}
                    value={form.countryOfResidence}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Colombia',
                            value: 'Colombia'
                        },
                        {
                            label: 'Otro',
                            value: 'Otro'
                        }
                    ]}
                />

                <SelectInput
                    fullWidth
                    name="departmentOfResidence"
                    label={formLabels.departmentOfResidence}
                    error={errors.departmentOfResidence || ''}
                    value={form.departmentOfResidence}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'AMAZONAS',
                            value: 'AMAZONAS'
                        },
                        {
                            label: 'ANTIOQUIA',
                            value: 'ANTIOQUIA'
                        },
                        {
                            label: 'ARAUCA',
                            value: 'ARAUCA'
                        },
                        {
                            label: 'ATLÁNTICO',
                            value: 'ATLÁNTICO'
                        },
                        {
                            label: 'BOGOTÁ, D. C.',
                            value: 'BOGOTÁ, D. C.'
                        },
                        {
                            label: 'BOLÍVAR',
                            value: 'BOLÍVAR'
                        },
                        {
                            label: 'BOYACÁ',
                            value: 'BOYACÁ'
                        },
                        {
                            label: 'CALDAS',
                            value: 'CALDAS'
                        },
                        {
                            label: 'CAQUETÁ',
                            value: 'CAQUETÁ'
                        },
                        {
                            label: 'CASANARE',
                            value: 'CASANARE'
                        },
                        {
                            label: 'CAUCA',
                            value: 'CAUCA'
                        },
                        {
                            label: 'CESAR',
                            value: 'CESAR'
                        },
                        {
                            label: 'CHOCÓ',
                            value: 'CHOCÓ'
                        },
                        {
                            label: 'CÓRDOBA',
                            value: 'CÓRDOBA'
                        },
                        {
                            label: 'CUNDINAMARCA',
                            value: 'CUNDINAMARCA'
                        },
                        {
                            label: 'GUAINÍA',
                            value: 'GUAINÍA'
                        },
                        {
                            label: 'GUAJIRA',
                            value: 'GUAJIRA'
                        },
                        {
                            label: 'GUAVIARE',
                            value: 'GUAVIARE'
                        },
                        {
                            label: 'HUILA',
                            value: 'HUILA'
                        },
                        {
                            label: 'MAGDALENA',
                            value: 'MAGDALENA'
                        },
                        {
                            label: 'META',
                            value: 'META'
                        },
                        {
                            label: 'NARIÑO',
                            value: 'NARIÑO'
                        },
                        {
                            label: 'NORTE DE SANTANDER',
                            value: 'NORTE DE SANTANDER'
                        },
                        {
                            label: 'PUTUMAYO',
                            value: 'PUTUMAYO'
                        },
                        {
                            label: 'QUINDÍO',
                            value: 'QUINDÍO'
                        },
                        {
                            label: 'RISARALDA',
                            value: 'RISARALDA'
                        },
                        {
                            label: 'SAN ANDRÉS Y PROVIDENCIA',
                            value: 'SAN ANDRÉS Y PROVIDENCIA'
                        },
                        {
                            label: 'SANTANDER',
                            value: 'SANTANDER'
                        },
                        {
                            label: 'SUCRE',
                            value: 'SUCRE'
                        },
                        {
                            label: 'TOLIMA',
                            value: 'TOLIMA'
                        },
                        {
                            label: 'VALLE DEL CAUCA',
                            value: 'VALLE DEL CAUCA'
                        },
                        {
                            label: 'VAUPÉS',
                            value: 'VAUPÉS'
                        },
                        {
                            label: 'VICHADA',
                            value: 'VICHADA'
                        },
                    ]}
                />

                <SelectInput
                    fullWidth
                    name="workArea"
                    label={formLabels.workArea}
                    error={errors.workArea || ''}
                    value={form.workArea}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Vicerrectoria de Planeación y Servicios Digitales',
                            value: 'Vicerrectoria de Planeación y Servicios Digitales'
                        },
                        {
                            label: 'Vicerrectoría de Capital Social',
                            value: 'Vicerrectoría de Capital Social'
                        },
                        {
                            label: 'Vicerrectoria Academica',
                            value: 'Vicerrectoria Academica'
                        },
                        {
                            label: 'Secretaría General',
                            value: 'Secretaría General'
                        },
                        {
                            label: 'Controller',
                            value: 'Controller'
                        },
                        {
                            label: 'Vicerrectoria de experiencia',
                            value: 'Vicerrectoria de experiencia'
                        },
                        {
                            label: 'Vicerrectoría de Investigación e Innovación',
                            value: 'Vicerrectoría de Investigación e Innovación'
                        },
                        {
                            label: 'Vicerrectoría Ejecutiva',
                            value: 'Vicerrectoría Ejecutiva'
                        },
                        {
                            label: 'Dirección Financiera',
                            value: 'Dirección Financiera'
                        },
                    ]}
                />

                <SelectInput
                    fullWidth
                    name="workBranch"
                    label={formLabels.workBranch}
                    error={errors.workBranch || ''}
                    value={form.workBranch}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Bogotá sede A',
                            value: 'Bogotá sede A'
                        },
                        {
                            label: 'Bogotá sede B',
                            value: 'Bogotá sede B'
                        },
                        {
                            label: 'Bogotá sede C',
                            value: 'Bogotá sede C'
                        },
                        {
                            label: 'Bogotá sede D',
                            value: 'Bogotá sede D'
                        },
                        {
                            label: 'Bogotá sede E',
                            value: 'Bogotá sede E'
                        },
                        {
                            label: 'Bogotá sede F (Incluye sedes F, P y H)',
                            value: 'Bogotá sede F (Incluye sedes F, P y H)'
                        },
                        {
                            label: 'Bogotá sede G (Incluye sedes G e I)',
                            value: 'Bogotá sede G (Incluye sedes G e I)'
                        },
                        {
                            label: 'Bogotá sede J',
                            value: 'Bogotá sede J'
                        },
                        {
                            label: 'Bogotá sede Contac Center',
                            value: 'Bogotá sede Contac Center'
                        },
                        {
                            label: 'Bogotá sede Norte',
                            value: 'Bogotá sede Norte'
                        },
                        {
                            label: 'Bogotá sede Sur',
                            value: 'Bogotá sede Sur'
                        },
                        {
                            label: 'Bogotá sede John F. Kennedy',
                            value: 'Bogotá sede John F. Kennedy'
                        },
                        {
                            label: 'Bogotá sede Fontibon',
                            value: 'Bogotá sede Fontibon'
                        },
                        {
                            label: 'Bogotá sede Suba',
                            value: 'Bogotá sede Suba'
                        },
                        {
                            label: 'Bogotá sede Restrepo',
                            value: 'Bogotá sede Restrepo'
                        },
                        {
                            label: 'Regional Medellin',
                            value: 'Regional Medellin'
                        },
                        {
                            label: 'Regional Florencia',
                            value: 'Regional Florencia'
                        },
                        {
                            label: 'Regional Montería',
                            value: 'Regional Montería'
                        },
                        {
                            label: 'Regional Neiva',
                            value: 'Regional Neiva'
                        },
                        {
                            label: 'Regional Santa Marta',
                            value: 'Regional Santa Marta'
                        },
                        {
                            label: 'Regional Nariño (Pasto - Ipiales)',
                            value: 'Regional Nariño (Pasto - Ipiales)'
                        },
                        {
                            label: 'Regional Sincelejo',
                            value: 'Regional Sincelejo'
                        },
                        {
                            label: 'Regional Ibagué',
                            value: 'Regional Ibagué'
                        },
                        {
                            label: 'Regional Meta (Villavicencio - Granada)',
                            value: 'Regional Meta (Villavicencio - Granada)'
                        },
                        {
                            label: 'Regional Cundinamarca (Soacha - Chia)',
                            value: 'Regional Cundinamarca (Soacha - Chia)'
                        },
                        {
                            label: 'Regional Yopal',
                            value: 'Regional Yopal'
                        },
                        {
                            label: 'Regional Pereira',
                            value: 'Regional Pereira'
                        },
                        {
                            label: 'Regional Armenia',
                            value: 'Regional Armenia'
                        },
                        {
                            label: 'Regional Cucuta',
                            value: 'Regional Cucuta'
                        },
                        {
                            label: 'Regional Barranquilla',
                            value: 'Regional Barranquilla'
                        },
                        {
                            label: 'Regional Cartagena',
                            value: 'Regional Cartagena'
                        },
                        {
                            label: 'Regional Buenaventura',
                            value: 'Regional Buenaventura'
                        },
                        {
                            label: 'Otra',
                            value: 'Otra'
                        },
                    ]}
                />

                <SelectInput
                    fullWidth
                    name="workChargeType"
                    label={formLabels.workChargeType}
                    error={errors.workChargeType || ''}
                    value={form.workChargeType}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Auxiliar',
                            value: 'Auxiliar'
                        },
                        {
                            label: 'Analista',
                            value: 'Analista'
                        },
                        {
                            label: 'Especialista',
                            value: 'Especialista'
                        },
                        {
                            label: 'Lider',
                            value: 'Lider'
                        },
                        {
                            label: 'Coordinandor',
                            value: 'Coordinandor'
                        },
                        {
                            label: 'Director',
                            value: 'Director'
                        },
                        {
                            label: 'Vicerrector',
                            value: 'Vicerrector'
                        },
                        {
                            label: 'Rector',
                            value: 'Rector'
                        },
                        {
                            label: 'Aprendiz',
                            value: 'Aprendiz'
                        },
                        {
                            label: 'Controler',
                            value: 'Controler'
                        },
                        {
                            label: 'Supervisor',
                            value: 'Supervisor'
                        },
                        {
                            label: 'Docente',
                            value: 'Docente'
                        },
                    ]}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    type="text"
                    name="workChargeName"
                    label={formLabels.workChargeName}
                    value={form.workChargeName}
                    onChange={handleInputChange}
                    error={Boolean(errors.workChargeName)}
                    helperText={errors.workChargeName}
                    InputLabelProps={{ shrink: !!form.workChargeName }}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    type="number"
                    name="workPropleReportingDirectly"
                    label={formLabels.workPropleReportingDirectly}
                    value={form.workPropleReportingDirectly}
                    onChange={handleInputChange}
                    error={Boolean(errors.workPropleReportingDirectly)}
                    helperText={errors.workPropleReportingDirectly}
                    InputLabelProps={{ shrink: !!form.workPropleReportingDirectly }}
                />

                <SelectInput
                    fullWidth
                    name="hasReportingPeopleToYouMorePeopleInCharge"
                    label={formLabels.hasReportingPeopleToYouMorePeopleInCharge}
                    error={errors.hasReportingPeopleToYouMorePeopleInCharge || ''}
                    value={form.hasReportingPeopleToYouMorePeopleInCharge}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Sí',
                            value: 'Sí'
                        },
                        {
                            label: 'No',
                            value: 'No'
                        },
                        {
                            label: 'No tengo personas a cargo',
                            value: 'No tengo personas a cargo'
                        }
                    ]}
                />

                <SelectInput
                    fullWidth
                    name="haveEverHadLeadershipCharges"
                    label={formLabels.haveEverHadLeadershipCharges}
                    error={errors.haveEverHadLeadershipCharges || ''}
                    value={form.haveEverHadLeadershipCharges}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: 'Sí',
                            value: 'Sí'
                        },
                        {
                            label: 'No',
                            value: 'No'
                        }
                    ]}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    type="number"
                    name="monthsQuantityWithLeadershipCharges"
                    label={formLabels.monthsQuantityWithLeadershipCharges}
                    value={form.monthsQuantityWithLeadershipCharges}
                    onChange={handleInputChange}
                    error={Boolean(errors.monthsQuantityWithLeadershipCharges)}
                    helperText={errors.monthsQuantityWithLeadershipCharges}
                    InputLabelProps={{ shrink: !!form.monthsQuantityWithLeadershipCharges }}
                />

                <DatePickerInput
                    fullWidth
                    name="dateOfIngressToTheCompany"
                    label={formLabels.dateOfIngressToTheCompany}
                    value={form.dateOfIngressToTheCompany || ''}
                    error={Boolean(errors.dateOfIngressToTheCompany)}
                    helperText={errors.dateOfIngressToTheCompany}
                    onChange={handleDatePickerInputChange}
                />

            </CardContent>

            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {
                    canUpdateSociodemographicInfoInfo && (
                        <Button
                            disabled={updateSociodemographicInfoSelector.loading}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Actualizar
                        </Button>
                    )
                }
            </CardActions>
        </Card>
    )
}
