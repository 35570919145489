import { ApiService } from "./ApiService"
import { StorageService } from "./StorageService"
import { handleAxiosError } from "app/helpers/axios.helper"
import { ApiResponse } from "app/types/api-response"


type UserType = {
    id: string
    email: string
    name: string
    lastName: string
    scope: string
    organizationId: string
    organizationName: string
    permissions: string
    roles: string[]
}

type LoginResponse = {
    token: string
    user: UserType
    permissions: string
}

export class AuthenticationService extends ApiService {
    async login(email: string, password: string): Promise<ApiResponse<LoginResponse>> {
        try {
            const response = await this.axios.post('/auth/login/password', {
                email,
                password,
                scope: 'END_USER'
            })

            const { token, user, permissions } = response.data

            return {
                statusCode: response.status,
                result: {
                    token,
                    user,
                    permissions,
                }
            }
        } catch (error) {
            return handleAxiosError(error)
        }
    }

    async getPermissionsAndRoles() {
        const accessToken = StorageService.getAccessToken()

        if (!accessToken) throw new Error('the access token is required to access this service')

        this.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

        const response = await this.axios.get('/auth/permissions-and-roles')
        const { user, permissions } = response.data

        return {
            user,
            permissions,
        }
    }

    async requestPasswordReset(email: string) {
        const response = await this.axios.post('/auth/request-password-reset', {
            email,
            scope: 'END_USER'
        })

        return response.status === 200
    }

    async resetPassword(password: string, code: string) {
        const response = await this.axios.post('/auth/reset-password', {
            password,
            code,
            scope: 'END_USER'
        })

        return response.status === 200
    }

    logout() {
        StorageService.removeLoginData()
    }
}
