import { useEffect, useState } from 'react'
import { MRT_ColumnFiltersState, MRT_SortingState, MRT_PaginationState } from "material-react-table";
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { findAllAction } from '../../my-test-application-record.slice';
import DataTable from '../../../../components/DataTable';
import ActionButtons, { ActionButton } from '../../../../components/ActionButtons';
import { AuthorizationService } from '../../../../services/AuthorizationService';
import PageHeader from '../../../../components/PageHeader';
import { MyTestApplicationRecordEntity } from '../../my-test-application-record.entity';
import { myTestApplicationRecordFeature } from '../../my-test-application-record.feature';
import UpdateIcon from '@mui/icons-material/Update';
import { FilterState } from 'app/types/pagination.dto';

const feature = myTestApplicationRecordFeature
const entity = new MyTestApplicationRecordEntity()

const joinQuery = entity.join?.map(entity => {
    return `${entity.entity}:${entity.attributes.join('|')}`
}).join(',')

const filterInitialState: FilterState = {
    state: [],
    query: undefined,
}

export default function List() {
    const dispatch = useAppDispatch()

    const listSelector = useAppSelector(state => state.myTestApplicationRecord.list)
    const [sorting, setSorting] = useState<{ state: MRT_SortingState, query?: string }>()
    const [filter, setFilter] = useState<{ state: MRT_ColumnFiltersState, query?: string }>(filterInitialState)
    const [page, setPage] = useState<MRT_PaginationState>({
        pageIndex: listSelector.meta.page,
        pageSize: listSelector.meta.perPage
    })

    const [refreshEnabled, setRefreshEnabled] = useState(false)
    const [refreshVisible, setRefreshVisible] = useState(false)

    const refreshData = () => {
        dispatch(findAllAction({
            page: page.pageIndex,
            perPage: page.pageSize,
            sortBy: sorting?.query,
            filter: filter?.query,
            join: joinQuery
        }))
    }

    useEffect(() => {
        const userIsAuthorizedToFindAll = AuthorizationService.userIsAuthorized('test-application-record:findAllMine')

        setRefreshVisible(userIsAuthorizedToFindAll)
        setRefreshEnabled(userIsAuthorizedToFindAll)
    }, [])

    useEffect(() => {
        refreshData()
    }, [sorting, filter, page])

    const handleSortingChange = (sorting: MRT_SortingState, query?: string) => {
        setSorting({
            state: sorting,
            query
        })
    }

    const handleFilterChange = (filter: MRT_ColumnFiltersState, query?: string) => {
        setFilter({
            state: filter,
            query
        })
    }

    const handlePageChange = (page: MRT_PaginationState) => {
        setPage({
            pageIndex: page.pageIndex + 1,
            pageSize: page.pageSize
        })
    }

    const leftButtons: ActionButton[] = [
        {
            label: 'Refrescar',
            icon: UpdateIcon,
            color: 'primary',
            isEnabled: refreshEnabled,
            isVisible: refreshVisible,
            onClick: () => refreshData()
        },
    ]

    const rightButtons: ActionButton[] = []

    return (
        <>
            <PageHeader
                title={feature.label.plural}
                icon={feature.icon}
            />

            <ActionButtons
                leftButtons={leftButtons}
                rightButtons={rightButtons}
            />

            <DataTable
                entity={entity}
                dataTable={listSelector}
                sorting={sorting?.state}

                onSortingChange={handleSortingChange}
                onFilterChange={handleFilterChange}
                onPageChange={handlePageChange}
            />
        </>
    );
}
