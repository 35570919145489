import AutoCompleteGroupInput, { AutoCompleteGroupOption } from "app/components/AutoCompleteGroupInput"
import { useQuery } from "app/hooks"
import { useAppDispatch, useAppSelector } from "app/redux/hooks"
import { useCallback, useEffect, useState } from "react"
import { autocompleteAction } from "../../organizational-unit.slice"
import { organizationalUnitFeature } from "../../organizational-unit.feature"
import { FilterState } from "app/types/pagination.dto"
import { getNewFilterState } from "app/helpers/pagination.helper"
import { debounce } from "@mui/material"

type Props = {
    name: string
    value?: string
    exclude?: string[]
    onSelect: (name: string, value?: string) => void
    error?: string
    isDependent?: boolean
    organizationId?: string
}

const filterInitialState: FilterState = {
    state: [],
    query: undefined
}

export default function OrganizationalUnitAutoCompleteInput(props: Props) {
    const dispatch = useAppDispatch()
    const query = useQuery()

    const [readonly, setReadonly] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)

    const [queryParams] = useState({
        organizationalUnitId: query.get('organizationalUnitId') || undefined
    })

    const [organizationId, setOrganizationId] = useState<string>()
    const [filterQuery, setFilterQuery] = useState<FilterState>(filterInitialState)

    const organizationalUnits = useAppSelector(state => state.organizationalUnit.autocomplete.data)
    const [organizationalUnitOptions, setOrganizationalUnitOptions] = useState<AutoCompleteGroupOption[]>([])

    const refreshData = () => {
        dispatch(
            autocompleteAction({
                page: 1,
                perPage: 15,
                filter: filterQuery.query
            })
        )
    }

    const mapOptions = () => {
        const options: AutoCompleteGroupOption[] = organizationalUnits
            .filter(row => !props.exclude?.includes(row.id))
            .map(row => ({
                id: row.id,
                label: row.name,
                group: row.organization?.name || 'Sin ' + organizationalUnitFeature.label.singular,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))

        setOrganizationalUnitOptions(options)
    }

    const debounceRefreshData = useCallback(
        debounce(refreshData, 300)
        , [])

    useEffect(() => {
        mapOptions()
    }, [organizationalUnits])

    useEffect(() => {
        setOrganizationId(props.organizationId)
    }, [props.organizationId])

    useEffect(() => {
        if (organizationId) {
            const filter = {
                id: 'organizationId',
                value: organizationId,
            }

            setFilterQuery(
                getNewFilterState(filterQuery, filter)
            )

            if (props.isDependent) {
                setReadonly(false)
                setDisabled(false)
            }
        } else {
            if (props.isDependent) {
                setReadonly(true)
                setDisabled(true)
            }
        }
    }, [organizationId])

    useEffect(() => {
        if (queryParams.organizationalUnitId) {
            const filter = {
                id: 'organizationalUnitId',
                value: queryParams.organizationalUnitId,
            }

            setFilterQuery(
                getNewFilterState(filterQuery, filter)
            )
        }
    }, [queryParams.organizationalUnitId])

    useEffect(() => {
        if (filterQuery.query) {
            debounceRefreshData()
        }
    }, [filterQuery.query])

    const handleAutoCompleteChange = (value: string) => {
        if (!value) return

        const filter = {
            id: 'organizationalUnit.name',
            value: value,
        }

        setFilterQuery(
            getNewFilterState(filterQuery, filter)
        )
    }

    const handleAutoCompleteSelect = (name: string, value?: AutoCompleteGroupOption) => {
        props.onSelect(name, value?.id)
    }

    return (
        <AutoCompleteGroupInput
            name={props.name}
            defaultValue={props.value}
            searchInputlabel={`${organizationalUnitFeature.label.singular}`}
            group={true}
            options={organizationalUnitOptions}
            noOptionsText={`No hay ${organizationalUnitFeature.label.plural} para mostrar`}
            readonly={readonly}
            disabled={disabled}
            onAutoCompleteChange={handleAutoCompleteChange}
            onSelect={handleAutoCompleteSelect}
            error={props.error}
        />
    )
}
