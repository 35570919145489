import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { userEmployeeFeature } from "app/modules/user-employee/user-employee.feature";
import { organizationalUnitFeature } from "../../organizational-unit.feature";

type DeleteEmployeeFromOrganizationalUnitDialogProps = {
    onCancelled: () => void
    onConfirm: () => void
}

export default function DeleteEmployeeFromOrganizationalUnitDialog(props: DeleteEmployeeFromOrganizationalUnitDialogProps) {
    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Eliminar ${userEmployeeFeature.label.singular} de ${organizationalUnitFeature.label.singular}`}
            />
            <Divider />
            <CardContent>
                <Box>
                    <Typography>¿Seguro que desea eliminar {userEmployeeFeature.article.definite.singular} {userEmployeeFeature.label.singular} de {organizationalUnitFeature.article.definite.singular} {organizationalUnitFeature.label.singular}?, esta acción no se puede deshacer</Typography>
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    No, Cancelar
                </Button>
                <Button
                    variant="contained"
                    onClick={props.onConfirm}
                >
                    Si, Eliminar
                </Button>
            </CardActions>
        </Card>
    )
}