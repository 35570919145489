import { Route, Routes } from "react-router-dom";
import List from "./List";

export default function MyTestApplicationRecord() {
    return (
        <>
            <Routes>
                <Route path={''} element={<List />} />
            </Routes>
        </>
    );
}
