import { EntityAttribute, EntityName } from "../../interfaces/EntityInterface";
import { JoinDefinition } from "../../types/join.type";
import { EntitySchema as OrganizationEntitySchema } from '../organization/organization.entity'
import { JoinAndCountDefinition } from "app/types/join-and-count.type";
import { BaseUiEntity } from "app/classes/base-ui-entity";

export type EntitySchema = {
    id: string
    name: string
    organizationalUnitId: string | null
    organizationId: string
    createdAt: string
    updatedAt: string
    organizationalUnit?: EntitySchema
    organization?: OrganizationEntitySchema
}

export const entityInitialState: EntitySchema = {
    id: '',
    name: '',
    organizationalUnitId: null,
    organizationId: '',
    createdAt: '',
    updatedAt: '',
}

const singularName = 'Equipo de trabajo'
const pluralName = 'Equipos de trabajo'

const name: EntityName = {
    singular: singularName,
    plural: pluralName
}

const slug = 'organizational-unit'

const primaryKeyAttribute: string = 'id'

const linkedToViewAttribute: string = 'name'

const attributes: EntityAttribute[] = [
    {
        key: 'id',
        label: 'ID',
        dataType: 'string',
        showInTable: false
    },
    {
        key: 'name',
        label: 'Nombre',
        dataType: 'string',
        showInTable: true
    },
    {
        key: 'organizationalUnit.name',
        label: singularName,
        dataType: 'string',
        showInTable: true
    },
    {
        key: 'organizationalUnitsCount',
        label: pluralName,
        dataType: 'string',
        showInTable: true,
        filter: false,
        sort: false
    },
    {
        key: 'organization.name',
        label: 'Organización',
        dataType: 'string',
        showInTable: true
    },
    {
        key: 'createdAt',
        label: 'Creado',
        dataType: 'date',
        showInTable: true,
        filter: false
    }
]

const join: JoinDefinition[] = [
    {
        entity: 'organizationalUnit',
        attributes: ['id', 'name']
    },
    {
        entity: 'organization',
        attributes: ['id', 'name']
    }
]

const joinAndCount: JoinAndCountDefinition[] = [
    {
        entity: 'organizationalUnits'
    }
]

export class Entity extends BaseUiEntity {
    constructor() {
        super(
            name,
            slug,
            primaryKeyAttribute,
            linkedToViewAttribute,
            attributes,
            join,
            joinAndCount,
        )
    }
}
