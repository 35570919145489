import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { validateForm, mapValidationErrors } from "../../../../helpers/validation";
import { schema } from "./schema";
import { createAction, resetCreateAction } from '../../organizational-unit.slice'
import { CreateDto } from './create.dto'
import { organizationalUnitFeature } from "../../organizational-unit.feature";
import OrganizationalUnitAutoCompleteInput from "../../components/OrganizationalUnitAutoCompleteInput";
import { useQuery } from "app/hooks";
import OrganizationAutoCompleteInput from "app/modules/organization/components/OrganizationAutoCompleteInput";

type Props = {
    onCancelled: () => void
    onCompleted: (done: boolean) => void
}

const formInitialState: CreateDto = {
    name: '',
    organizationalUnitId: null,
    organizationId: ''
}

const errorsInitialState = formInitialState
const feature = organizationalUnitFeature

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const query = useQuery()
    const createSelector = useAppSelector(state => state.organizationalUnit.create)

    const [form, setForm] = useState<CreateDto>({
        ...formInitialState,
        organizationalUnitId: query.get('organizationalUnitId') || null,
        organizationId: query.get('organizationId') || '',
    })
    const [errors, setErrors] = useState<CreateDto>(errorsInitialState)

    useEffect(() => {
        if (createSelector.done)
            props.onCompleted(createSelector.done)

        dispatch(resetCreateAction())
    }, [createSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setErrors({
                ...formInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                createAction(form)
            )
        }
    };

    const handleAutoCompleteInputSelect = (name: string, value?: string) => {
        setForm({
            ...form,
            [name]: value
        })
    }

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={form.name}
                        onChange={handleInputChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />

                    <OrganizationAutoCompleteInput
                        name="organizationId"
                        value={form.organizationId}
                        onSelect={handleAutoCompleteInputSelect}
                        error={errors.organizationId}
                    />

                    <OrganizationalUnitAutoCompleteInput
                        name="organizationalUnitId"
                        value={form.organizationalUnitId || undefined}
                        isDependent={true}
                        organizationId={form.organizationId}
                        onSelect={handleAutoCompleteInputSelect}
                        error={errors.organizationalUnitId || undefined}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
