import CardInputWrapper from "app/components/CardInputWrapper";
import AddIcon from '@mui/icons-material/Add';
import { organizationalUnitFeature } from "../../organizational-unit.feature";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import OrganizationalUnitWithRoleInput, { OrganizationalUnitWithRole } from "../OrganizationalUnitWithRoleInput";
import { AttachEmployeeOrganizationalUnitRole } from "app/modules/user-employee/pages/Create/create.dto";

export type OrganizationalUnitWithRoleInputProps = {
    organizationId: string
    value: OrganizationalUnitWithRoleMultiple[]
    errors: AttachEmployeeOrganizationalUnitRole[]
    onChange: (key: number, organizationalUnitId?: string, role?: string) => void
    onAdd: () => void
    onDelete: (key: number) => void
}

export type OrganizationalUnitWithRoleMultiple = OrganizationalUnitWithRole & {
    index: number
}

export default function OrganizationalUnitWithRoleMultipleInput(props: OrganizationalUnitWithRoleInputProps) {
    const handleAddButtonClick = () => {
        props.onAdd()
    }

    const handleOrganizationalUnitWithRoleInputChange = (key: number, organizationalUnitId?: string, role?: string) => {
        props.onChange(key, organizationalUnitId, role)
    }

    const handleDeleteButtonClick = (key: number, organizationalUnitId?: string, role?: string) => {
        props.onDelete(key)
    }

    return (
        <CardInputWrapper
            title={organizationalUnitFeature.label.plural}
        >
            {
                props.value
                    .map((organizationalUnitWithRole, index) => (
                        <OrganizationalUnitWithRoleInput
                            key={index}
                            index={index}
                            error={props.errors[index]}
                            organizationId={props.organizationId}
                            organizationalUnitId={organizationalUnitWithRole.organizationalUnitId}
                            role={organizationalUnitWithRole.role}
                            onChange={handleOrganizationalUnitWithRoleInputChange}
                            onConfirmDeletion={handleDeleteButtonClick}
                        />
                    ))
            }

            <Divider sx={{ mt: 1 }} variant="fullWidth" />

            <Grid item xs={1} display="flex" justifyContent="center" sx={{ mt: 1 }}>
                <IconButton onClick={handleAddButtonClick}>
                    <AddIcon color="success" />
                </IconButton>
            </Grid>

        </CardInputWrapper>
    )
}