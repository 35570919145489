import { JoinDefinition } from "app/types/join.type";
import {
    EntityAttribute,
    EntityName,
    UiEntity,
} from "../../interfaces/EntityInterface";

export type EntitySchema = {
    id?: string;
    name: string;
    lastName: string;
    createdAt: string;
    updatedAt: string;
};

export const entityInitialState = {
    id: undefined,
    name: "",
    lastName: "",
    createdAt: "",
    updatedAt: "",
};

export class Entity implements UiEntity {
    slug: string = "profile";

    join?: JoinDefinition[] | undefined;

    name: EntityName = {
        singular: "Perfil",
        plural: "Perfiles",
    };

    primaryKeyAttribute: string = "id";

    linkedToViewAttribute: string = "name";

    attributes: EntityAttribute[] = [
        {
            key: "id",
            label: "ID",
            dataType: "string",
            showInTable: false,
        },
        {
            key: "name",
            label: "Nombre",
            dataType: "string",
            showInTable: true,
        },
        {
            key: "lastName",
            label: "Apellido",
            dataType: "string",
            showInTable: true,
        },
        {
            key: "email",
            label: "Correo electrónico",
            dataType: "string",
            showInTable: true,
        },
        {
            key: "createdAt",
            label: "Creado",
            dataType: "date",
            showInTable: true,
        },
        {
            key: "updatedAt",
            label: "Actualizado",
            dataType: "date",
            showInTable: false,
        },
    ];
}
