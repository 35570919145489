import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { schema } from "./schema";
import { updateAction, resetUpdateAction, findOneAction, attachPoliciesAction, resetAttachPoliciesAction } from '../../role.slice'
import { UpdateDto } from './update.dto'
import { Entity } from "../../role.entity";
import { SelectChangeEvent } from "@mui/material/Select";
import PolicyCheckBoxGroupInput from "app/modules/policy/components/PolicyCheckBoxGroupInput";
import SelectInput from "app/components/SelectInput";
import { roleScopes } from "../../role.constants";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (done: boolean) => void
}

const updateRoleInitialState = {
    name: '',
    scope: ''
}

const updateRoleErrorsInitialState = {
    name: '',
    scope: ''
}

const policyInitialState = {
    policyIds: [] as string[]
}

const policyErrorsInitialState = {
    policyIds: ''
}

const uiEntity = new Entity()

export default function Update(props: Props) {
    const dispatch = useAppDispatch()
    const getRoleSelector = useAppSelector(state => state.role.view)
    const updateRoleSelector = useAppSelector(state => state.role.update)
    const attachPoliciesRoleSelector = useAppSelector(state => state.role.attachPolicies)

    const [updateRoleForm, setUpdateRoleForm] = useState<UpdateDto>(updateRoleInitialState)
    const [updateRoleFormErrors, setUpdateRoleFormErrors] = useState<UpdateDto>(updateRoleErrorsInitialState)

    const [policyForm, setPolicyForm] = useState(policyInitialState)
    const [policyFormErrors, setPolicyFormErrors] = useState(policyErrorsInitialState)

    useEffect(() => {
        dispatch(findOneAction({
            id: props.id,
            join: 'policies'
        }))
    }, [])

    useEffect(() => {
        if (!getRoleSelector.entity) return

        setUpdateRoleForm({
            name: getRoleSelector.entity.name,
            scope: getRoleSelector.entity.scope,
        })

        setPolicyForm({
            policyIds: getRoleSelector.entity.policies.map(policy => policy.id!)
        })
    }, [getRoleSelector.entity])

    useEffect(() => {
        if (updateRoleSelector.done && !!!updateRoleSelector.error) {
            dispatch(
                attachPoliciesAction({
                    id: props.id,
                    policyIds: policyForm.policyIds
                })
            )
        }
    }, [updateRoleSelector.done])

    useEffect(() => {
        if (attachPoliciesRoleSelector.done && !!!attachPoliciesRoleSelector.error) {
            dispatch(resetUpdateAction())
            dispatch(resetAttachPoliciesAction())

            props.onCompleted(updateRoleSelector.done)
        }
    }, [attachPoliciesRoleSelector.done])

    useEffect(() => {
        setUpdateRoleForm(updateRoleSelector.data)
    }, [updateRoleSelector.data])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateRoleForm({
            ...updateRoleForm,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setUpdateRoleForm({
            ...updateRoleForm,
            [event.target.name]: event.target.value
        })
    }

    const handlePolicyInputChange = (name: string, value: string[]) => {
        setPolicyForm({
            ...policyForm,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const formData = {
            name: updateRoleForm.name,
            scope: updateRoleForm.scope
        }

        const formValidation = validateForm(schema, formData)

        if (formValidation.error) {
            setUpdateRoleFormErrors({
                ...updateRoleErrorsInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(updateAction({
                id: props.id,
                dto: formData
            }))
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar ` + uiEntity.name.singular}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={updateRoleForm.name ? updateRoleForm.name : ''}
                        onChange={handleInputChange}
                        error={Boolean(updateRoleFormErrors.name)}
                        helperText={updateRoleFormErrors.name}
                    />

                    <SelectInput
                        fullWidth
                        name="scope"
                        label="Ámbito"
                        options={roleScopes}
                        value={updateRoleForm.scope}
                        error={updateRoleFormErrors.scope}
                        onChange={handleSelectChange}
                    />

                    <PolicyCheckBoxGroupInput
                        label="Políticas"
                        name="policyIds"
                        value={policyForm.policyIds}
                        error={policyFormErrors.policyIds}
                        onChange={handlePolicyInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updateRoleSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updateRoleSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
