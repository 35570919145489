import {
  EntityAttribute,
  EntityName,
  UiEntity,
} from "../../interfaces/EntityInterface";
import { JoinAndCountDefinition } from "../../types/join-and-count.type";
import { JoinDefinition } from "../../types/join.type";
import { organizationFeature } from "../organization/organization.feature";
import { testFeature } from '../test/test.feature'

export type EntitySchema = {
  id?: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  organizationId: string;
  testVersionId: string;
};

export const entityInitialState = {
  id: undefined,
  name: "",
  createdAt: "",
  updatedAt: "",
  organizationId: "",
  testVersionId: ""
};

export class Entity implements UiEntity {
  slug: string = "assessment";

  name: EntityName = {
    singular: "Evaluación",
    plural: "Evaluaciones",
  };

  primaryKeyAttribute: string = "id";

  linkedToViewAttribute: string = "name";

  attributes: EntityAttribute[] = [
    {
      key: "id",
      label: "ID",
      dataType: "string",
      showInTable: false,
    },
    {
      key: "name",
      label: "Nombre",
      dataType: "string",
      showInTable: true,
    },
    {
      key: 'testVersion.test.name',
      label: testFeature.label.singular,
      dataType: 'string',
      showInTable: true,
    },
    {
      key: 'testVersion.name',
      label: 'Version',
      dataType: 'string',
      showInTable: true,
    },
    {
      key: 'organization.name',
      label: organizationFeature.label.singular,
      dataType: 'string',
      showInTable: true,
    },
    {
      key: "createdAt",
      label: "Creado",
      dataType: "date",
      showInTable: true,
      filter: false
    },
    {
      key: "updatedAt",
      label: "Actualizado",
      dataType: "date",
      showInTable: false,
      filter: false
    },
  ];

  join?: JoinDefinition[] = [
    {
      entity: 'organization',
      attributes: ['id', 'name']
    },
    {
      entity: 'testVersion',
      attributes: ['id', 'name']
    },
    {
      entity: 'testVersion.test',
      attributes: ['id', 'name']
    }
  ]

  joinAndCount?: JoinAndCountDefinition[] = []
}
