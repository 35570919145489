import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "../../modules/organization/organization.entity";
import { ProtectedService } from "../../services/ProtectedService";
import { UpdateDto } from "./pages/Update/update.dto";

export class OrganizationService extends ProtectedService {
    async findOne(id: string): Promise<EntitySchema> {
        const response = await this.axios.get(`/organization/${id}`)

        return response.data
    }

    async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
        const response = await this.axios.get('/organization', {
            params: dto
        })

        return response.data
    }

    async update(id: string, dto: UpdateDto): Promise<boolean> {
        const response = await this.axios.patch(`/organization/${id}`, dto)

        return response.status === 200
    }
}
