import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { CreateDto } from "./pages/Create/create.dto";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./statement.entity";
import { ProtectedService } from "../../services/ProtectedService";
import { UpdateDto } from "./pages/Update/update.dto";

export class StatementService extends ProtectedService {

  async findOne(id: string): Promise<EntitySchema> {
    const response = await this.axios.get(`/statement/${id}`)

    return response.data
  }

  async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
    const response = await this.axios.get("/statement", {
      params: dto,
    });

    return response.data;
  }

  async create(dto: CreateDto): Promise<EntitySchema> {
    const response = await this.axios.post('/statement', dto)

    return response.data
  }

  async update(id: string, dto: UpdateDto): Promise<boolean> {
    const response = await this.axios.patch(`/statement/${id}`, dto)

    return response.status === 200
  }

  async delete(dto: DeleteDto): Promise<boolean> {
    const response = await this.axios.delete(`/statement/${dto.id}`)

    return response.status === 200
  }
}
