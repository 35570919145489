import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { findOneAction, updateAction } from '../../organization.slice'
import { validateForm, mapValidationErrors } from "../../../../helpers/validation";

import { schema } from "./schema";
import { UpdateDto } from './update.dto'
import { organizationFeature } from "../../organization.feature";
import { EntitySchema } from "../../organization.entity";
import { getMatchingProperties } from "app/helpers/object.helper";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (updated: boolean, data?: EntitySchema) => void
}

const initialState: UpdateDto = {
    name: ''
}

const feature = organizationFeature

export default function Update(props: Props) {
    const dispatch = useAppDispatch()
    const viewSelector = useAppSelector(state => state.organization.view)
    const updateSelector = useAppSelector(state => state.organization.update)
    const [form, setForm] = useState<UpdateDto>(initialState)
    const [errors, setErrors] = useState<UpdateDto>(initialState)

    useEffect(() => {
        dispatch(findOneAction(props.id))
    }, [])

    useEffect(() => {
        if (updateSelector.done)
            props.onCompleted(updateSelector.done)
    }, [updateSelector.done])

    useEffect(() => {
        if (viewSelector.done && viewSelector.data) {
            setForm(
                getMatchingProperties(initialState, viewSelector.data)
            )
        }
    }, [viewSelector])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setErrors({
                ...initialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                updateAction({
                    id: props.id,
                    dto: form
                })
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={form.name}
                        onChange={handleInputChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updateSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updateSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
