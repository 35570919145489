import Joi from "joi";
import { UpdateDto } from "./update.dto";

export const schema = Joi.object<UpdateDto, true>({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required()
})
