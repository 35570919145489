import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type Props = {
    required?: boolean
    label: string
    name: string
    value: string
    error: string
    autoComplete?: string
    disabled?: boolean
    showPassword?: boolean
    onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export default function PasswordInput(props: Props) {
    const [showPassword, setShowPassword] = useState(props.showPassword);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        setShowPassword(props.showPassword)
    }, [props.showPassword])

    return (
        <FormControl
            required={props.required}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={props.disabled}
            error={Boolean(props.error)}
        >
            <InputLabel>{props.label}</InputLabel>
            <OutlinedInput
                required={props.required}
                autoComplete={props.autoComplete}
                type={showPassword ? 'text' : 'password'}
                label={props.label}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                endAdornment={<InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {(showPassword) ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>} />
            <FormHelperText>{props.error}</FormHelperText>
        </FormControl>
    )
}
