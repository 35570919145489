import { JoinDefinition } from "app/types/join.type";
import { UiEntity, EntityAttribute, EntityName } from "../../interfaces/EntityInterface";
import { EntitySchema as RoleEntitySchema } from '../role/role.entity'
import { JoinAndCountDefinition } from "app/types/join-and-count.type";

export type ProfileOrganizationalUnitAttachment = {
    id: string
    profileId: string
    organizationalUnitId: string
    role: string
}

export type EntitySchema = {
    id: string
    name: string
    lastName: string
    email: string
    password: string
    scope: string
    createdAt: string
    updatedAt: string
    organizationId: string
    roles: RoleEntitySchema[]
    profileOrganizationalUnitAttachments: ProfileOrganizationalUnitAttachment[]
}

export const entityInitialState = {
    id: '',
    name: '',
    lastName: '',
    email: '',
    password: '',
    scope: '',
    createdAt: '',
    updatedAt: '',
    organizationId: null,
    roles: [],
    profileOrganizationalUnitAttachments: []
}

export class Entity implements UiEntity {
    name: EntityName = {
        singular: 'Colaborador',
        plural: 'Colaboradores'
    }

    slug = 'user-employee'

    primaryKeyAttribute: string = 'id'

    linkedToViewAttribute: string = 'name'

    attributes: EntityAttribute[] = [
        {
            key: 'name',
            label: 'Nombre',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'lastName',
            label: 'Apellido',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'email',
            label: 'Correo electrónico',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'organization.name',
            label: 'Organización',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'createdAt',
            label: 'Creado',
            dataType: 'date',
            showInTable: true,
            filter: false
        },
        {
            key: 'updatedAt',
            label: 'Actualizado',
            dataType: 'date',
            showInTable: false,
            filter: false
        },
    ]

    join: JoinDefinition[] = [
        {
            entity: 'organization',
            attributes: ['id', 'name']
        },
        {
            entity: 'profileOrganizationalUnitAttachments',
            attributes: ['id', 'profileId', 'organizationalUnitId']
        }
    ]

    joinAndCount?: JoinAndCountDefinition[] = []
}
