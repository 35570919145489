import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { validateArrayForm, mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { attachEmployeeOrganizationSchema, rbacRoleSchema, schema } from "./schema";
import { updateInfoAction, resetUpdateInfoAction, findOneAction, attachRbacRolesAction, resetAttachRbacRolesAction, attachOrganizationalUnitsAction } from '../../user-employee.slice'
import { UpdateInfoDto } from './update-info.dto'
import { Entity } from "../../user-employee.entity";
import OrganizationAutoCompleteInput from "app/modules/organization/components/OrganizationAutoCompleteInput";
import { SelectChangeEvent } from "@mui/material/Select";
import { RoleScopesEnum } from "app/modules/role/role.enums";
import RolesCheckBoxGroupInput from "app/modules/role/components/RolesCheckBoxGroupInput";
import OrganizationalUnitWithRoleMultipleInput, { OrganizationalUnitWithRoleMultiple } from "app/modules/organizational-unit/components/OrganizationalUnitWithRoleMultipleInput";
import { AttachEmployeeOrganizationalUnitRole } from "../Create/create.dto";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (done: boolean) => void
}

const formInitialState = {
    name: '',
    lastName: '',
    email: '',
    scope: '',
    organizationId: ''
}

const errorsInitialState = formInitialState
const uiEntity = new Entity()

const rbacRoleInitialState = {
    roleIds: [] as string[]
}

const rbacRoleErrorsInitialState = {
    roleIds: ''
}

const attachEmployeeOrganizationalUnitRoleInitialState = {
    attachments: [
        {
            index: 0,
            organizationalUnitId: undefined,
            role: undefined
        }
    ]  as OrganizationalUnitWithRoleMultiple[]
}

const attachEmployeeOrganizationalUnitRoleErrorsInitialState = {
    attachments: [{
        organizationalUnitId: '',
        role: ''
    }]
}

export default function UpdateInfo(props: Props) {
    const dispatch = useAppDispatch()
    const getEmployeeSelector = useAppSelector(state => state.userEmployee.view)
    const updateEmployeeInfoSelector = useAppSelector(state => state.userEmployee.updateInfo)
    const attachRolesSelector = useAppSelector(state => state.userEmployee.attachRbacRoles)
    const attachEmployeeOrganizationalUnitRolesSelector = useAppSelector(state => state.userEmployee.attachOrganizationalUnitRoles)

    const [updateEmployeeInfoForm, setUpdateEmployeeInfoForm] = useState<UpdateInfoDto>(formInitialState)
    const [updateEmployeeInfoFormErrors, setUpdateEmployeeInfoFormErrors] = useState<UpdateInfoDto>(errorsInitialState)

    const [
        attachEmployeeOrganizationalUnitRoleForm, 
        setAttachEmployeeOrganizationalUnitRoleForm
    ] = useState<{
        attachments: OrganizationalUnitWithRoleMultiple[]
    }>(attachEmployeeOrganizationalUnitRoleInitialState)
    const [
        attachEmployeeOrganizationalUnitRoleFormErrors, 
        setAttachEmployeeOrganizationalUnitRoleFormErrors
    ] = useState<{
        attachments: AttachEmployeeOrganizationalUnitRole[]
    }>(attachEmployeeOrganizationalUnitRoleErrorsInitialState)

    const [rbacRoleForm, setRbacRoleForm] = useState(rbacRoleInitialState)
    const [rbacRoleFormErrors, setRbacRoleFormErrors] = useState(rbacRoleErrorsInitialState)

    useEffect(() => {
        dispatch(findOneAction({
            id: props.id,
            join: 'roles,profileOrganizationalUnitAttachments'
        }))
    }, [])

    useEffect(() => {
        if (!getEmployeeSelector.data) return

        setUpdateEmployeeInfoForm({
            name: getEmployeeSelector.data.name,
            lastName: getEmployeeSelector.data.lastName,
            email: getEmployeeSelector.data.email,
            organizationId: getEmployeeSelector.data.organizationId
        })

        setAttachEmployeeOrganizationalUnitRoleForm({
            attachments: getEmployeeSelector.data.profileOrganizationalUnitAttachments.map((a, i) => ({
                index: i,
                organizationalUnitId: a.organizationalUnitId,
                role: a.role
            }))
        })

        setRbacRoleForm({
            roleIds: getEmployeeSelector.data.roles.map(role => role.id!)
        })
    }, [getEmployeeSelector.data])

    useEffect(() => {
        if (updateEmployeeInfoSelector.done && !!!updateEmployeeInfoSelector.error) {
            dispatch(
                attachRbacRolesAction({
                    id: props.id,
                    roleIds: rbacRoleForm.roleIds
                })
            )

            dispatch(
                attachOrganizationalUnitsAction({
                    profileId: props.id,
                    attachments: attachEmployeeOrganizationalUnitRoleForm.attachments
                        .map(attachment => ({
                            organizationalUnitId: attachment.organizationalUnitId!,
                            role: attachment.role!
                        }))
                })
            )
        }
    }, [updateEmployeeInfoSelector.done])

    useEffect(() => {
        if (attachRolesSelector.done && !!!attachRolesSelector.error) {
            dispatch(resetUpdateInfoAction())
            dispatch(resetAttachRbacRolesAction())

            props.onCompleted(updateEmployeeInfoSelector.done)
        }
    }, [attachRolesSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateEmployeeInfoForm({
            ...updateEmployeeInfoForm,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setUpdateEmployeeInfoForm({
            ...updateEmployeeInfoForm,
            [event.target.name]: event.target.value
        })
    }

    const handleOrganizationAutoCompleteInputSelect = (name: string, value?: string) => {
        setUpdateEmployeeInfoForm({
            ...updateEmployeeInfoForm,
            [name]: value
        })
    }

    const handleRolesInputChange = (name: string, value: string[]) => {
        setRbacRoleForm({
            ...rbacRoleForm,
            [name]: value
        })
    }

    const handleOrganizationalUnitWithRoleMultipleInputChange = (key: number, organizationalUnitId?: string, role?: string) => {
        const selection = {
            index: key,
            organizationalUnitId,
            role
        }

        if (attachEmployeeOrganizationalUnitRoleForm.attachments[key]) {
            attachEmployeeOrganizationalUnitRoleForm.attachments[key] = selection

            setAttachEmployeeOrganizationalUnitRoleForm({
                attachments: attachEmployeeOrganizationalUnitRoleForm.attachments
            })
        }
    }

    const handleOrganizationalUnitWithRoleMultipleInputAdd = () => {
        setAttachEmployeeOrganizationalUnitRoleForm({
            attachments: [
                ...attachEmployeeOrganizationalUnitRoleForm.attachments,
                {
                    index: attachEmployeeOrganizationalUnitRoleForm.attachments.length,
                    organizationalUnitId: undefined,
                    role: undefined
                }
            ]
        })
    }

    const handleOrganizationalUnitWithRoleMultipleInputDelete = (key: number) => {
        if (attachEmployeeOrganizationalUnitRoleForm.attachments[key]) {
            attachEmployeeOrganizationalUnitRoleForm.attachments.splice(key, 1)
        }

        setAttachEmployeeOrganizationalUnitRoleForm({
            attachments: attachEmployeeOrganizationalUnitRoleForm.attachments
        })
    }

    const validateAttachmentEmployeeOrganizationErrors = (
        attachEmployeeOrganizationValidation: AttachEmployeeOrganizationalUnitRole[]
    ) => {
        const attachEmployeeOrganizationErrors: AttachEmployeeOrganizationalUnitRole[] = []
        let attachEmployeeOrganizationHaveErrors: boolean =  false

        attachEmployeeOrganizationValidation.map((validation: any) => {
            if(validation.error) {
                attachEmployeeOrganizationHaveErrors = true
                attachEmployeeOrganizationErrors.push({
                    ...attachEmployeeOrganizationalUnitRoleErrorsInitialState.attachments[0],
                    ...mapValidationErrors(validation.error)
                })
            } else {
                attachEmployeeOrganizationErrors.push(
                    attachEmployeeOrganizationalUnitRoleErrorsInitialState.attachments[0]
                )
            }
        })

        return {attachEmployeeOrganizationHaveErrors, attachEmployeeOrganizationErrors}
    }

    const handleSubmit = () => {
        const formData = {
            name: updateEmployeeInfoForm.name,
            lastName: updateEmployeeInfoForm.lastName,
            email: updateEmployeeInfoForm.email,
            organizationId: updateEmployeeInfoForm.organizationId
        }

        const formValidation = validateForm(schema, formData)
        const attachEmployeeOrganizationValidation = validateArrayForm(
            attachEmployeeOrganizationSchema, 
            attachEmployeeOrganizationalUnitRoleForm.attachments.map(
                attachment => ({
                    organizationalUnitId: attachment.organizationalUnitId,
                    role: attachment.role
                })
            )
        )
        const rbacRoleValidation = validateForm(rbacRoleSchema, rbacRoleForm.roleIds)

        const {
            attachEmployeeOrganizationHaveErrors, 
            attachEmployeeOrganizationErrors
        } = validateAttachmentEmployeeOrganizationErrors(attachEmployeeOrganizationValidation as AttachEmployeeOrganizationalUnitRole[])

        if (formValidation.error || attachEmployeeOrganizationHaveErrors || rbacRoleValidation.error) {
            const formErrors = formValidation.error
                ? {
                    ...errorsInitialState,
                    ...mapValidationErrors(formValidation.error)
                }
                : errorsInitialState;

            const rbacRoleFormErrors = rbacRoleValidation.error
                ? {
                    ...rbacRoleErrorsInitialState,
                    roleIds: rbacRoleValidation.error.message,
                }
                : rbacRoleErrorsInitialState;
                
            setUpdateEmployeeInfoFormErrors(formErrors);
            setAttachEmployeeOrganizationalUnitRoleFormErrors({
                attachments: attachEmployeeOrganizationErrors,
            });
            setRbacRoleFormErrors(rbacRoleFormErrors);
        } else {
            dispatch(updateInfoAction({
                id: props.id,
                dto: formData
            }))
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar ` + uiEntity.name.singular}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={updateEmployeeInfoForm.name}
                        onChange={handleInputChange}
                        error={Boolean(updateEmployeeInfoFormErrors.name)}
                        helperText={updateEmployeeInfoFormErrors.name}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="lastName"
                        label="Apellido"
                        value={updateEmployeeInfoForm.lastName}
                        onChange={handleInputChange}
                        error={Boolean(updateEmployeeInfoFormErrors.lastName)}
                        helperText={updateEmployeeInfoFormErrors.lastName}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="email"
                        name="email"
                        label="Correo electrónico"
                        value={updateEmployeeInfoForm.email}
                        onChange={handleInputChange}
                        error={Boolean(updateEmployeeInfoFormErrors.email)}
                        helperText={updateEmployeeInfoFormErrors.email}
                    />

                    <OrganizationAutoCompleteInput
                        name="organizationId"
                        value={updateEmployeeInfoForm.organizationId}
                        error={updateEmployeeInfoFormErrors.organizationId}
                        onSelect={handleOrganizationAutoCompleteInputSelect}
                    />

                    <OrganizationalUnitWithRoleMultipleInput
                        organizationId={updateEmployeeInfoForm.organizationId}
                        value={attachEmployeeOrganizationalUnitRoleForm.attachments}
                        errors={attachEmployeeOrganizationalUnitRoleFormErrors.attachments}
                        onChange={handleOrganizationalUnitWithRoleMultipleInputChange}
                        onAdd={handleOrganizationalUnitWithRoleMultipleInputAdd}
                        onDelete={handleOrganizationalUnitWithRoleMultipleInputDelete}
                    />

                    <RolesCheckBoxGroupInput
                        label="Roles"
                        name="roleIds"
                        scope={RoleScopesEnum.END_USER}
                        value={rbacRoleForm.roleIds}
                        error={rbacRoleFormErrors.roleIds}
                        onChange={handleRolesInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updateEmployeeInfoSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updateEmployeeInfoSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
