import Joi from "joi";

export const schema = Joi.object({
    code: Joi
        .string()
        .label('Código de restablecimiento de contraseña')
        .required(),
    password: Joi
        .string()
        .label('Nueva contraseña')
        .min(3)
        .max(255)
        .required(),
    confirmPassword: Joi
        .any()
        .label('Confirmar contraseña')
        .required()
        .valid(Joi.ref('password'))
        .messages({
            'any.only': '{{#label}} no coincide con "Nueva contraseña".',
        })
})
