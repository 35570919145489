import Joi from "joi";
import { testVersionFeature } from "app/modules/test-version/test-version.feature";
import { organizationFeature } from "app/modules/organization/organization.feature";
import { organizationalUnitFeature } from "app/modules/organizational-unit/organizational-unit.feature";
import { contractFeature } from "app/modules/contract/contract.feature";
import dayjs from "dayjs";

export const schema = (maxDate: string) => Joi.object<any, true>({
    expirationDate: Joi
        .date()
        .min(dayjs().add(1, 'day').toString())
        .max(maxDate)
        .label('Fecha de expiración')
        .required()
        .messages({
            'date.max': '"Fecha de expiración" sobrepasa la vigencia del contrato',
            'date.min': '"Fecha de expiración" debe ser mínimo 24 horas'
        }),
    organizationId: Joi
        .string()
        .label(organizationFeature.label.singular)
        .required(),
    contractId: Joi
        .string()
        .label(contractFeature.label.singular)
        .required(),
    organizationalUnitId: Joi
        .string()
        .label(organizationalUnitFeature.label.singular)
        .required(),
    testVersionId: Joi
        .string()
        .label(testVersionFeature.label.singular)
        .required(),
})
