import { ColumnFilter, FilterState } from "app/types/pagination.dto";

type BuildFilterByQueryParamsInput = {
    id: string,
    value: string | null
}

type BuildFilterByQueryParamsInputState = {
    id: string,
    value: string
}

export function buildFilterByQueryParams(filterParams: BuildFilterByQueryParamsInput[]): FilterState | undefined {
    const states: BuildFilterByQueryParamsInputState[] = filterParams
        .filter(filterParam => !!filterParam.value)
        .map(filterParam => ({
            id: filterParam.id,
            value: filterParam.value!
        }))

    if (!states.length) return undefined

    const query = states
        .map(state => `${state.id}:${state.value}`)
        .join(',')

    return {
        state: states,
        query: query,
    }
}

export function getNewFilterState(filterState: FilterState, newFilter: ColumnFilter) {
    const index = filterState.state.findIndex(f => f.id === newFilter.id);

    if (index === -1) {
        filterState.state.push(newFilter);
    } else {
        filterState.state[index] = newFilter;
    }

    filterState.query = getFilterStateQuery(filterState);

    return filterState;
}

export function getFilterStateQuery(filterState: FilterState): string {
    return filterState.state.map(f => `${f.id}:${f.value}`).join('|');
}
