import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'
import { MyTestApplicationRecordService } from './my-test-application-record.service'
import { EntitySchema } from './my-test-application-record.entity'
import { ListDto } from './pages/List/list.dto'
import { initialListState } from 'app/redux/helpers/state.helper'

const sliceName = 'my-test-application-record'

const initialState = {
    list: initialListState<EntitySchema>()
}

const findAllAction = createAsyncThunk(
    `${sliceName}/findAll`,
    (input: ListDto) => {
        const service = new MyTestApplicationRecordService()

        return service.findAll(input)
    }
)

export const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetListAction: (state) => {
            state.list = initialState.list
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findAllAction.pending, (state) => {
                state.list.loading = true;
                state.list.done = false
                state.list.error = initialState.list.error
                state.list.data = initialState.list.data
                state.list.meta = initialState.list.meta
            })
            .addCase(findAllAction.fulfilled, (state, action) => {
                state.list.loading = false;
                state.list.done = true
                state.list.error = initialState.list.error
                state.list.data = action.payload.data
                state.list.meta = action.payload.meta
            })
            .addCase(findAllAction.rejected, (state, action) => {
                state.list.loading = false;
                state.list.done = false
                state.list.error = action.error.message || 'Someting wrong occured'
                state.list.data = initialState.list.data
                state.list.meta = initialState.list.meta
            })
    }
})

export {
    findAllAction
}

export const { resetListAction } = slice.actions
export const selector = (state: RootState) => state.myTestApplicationRecord
export default slice.reducer;
