import ChecklistIcon from '@mui/icons-material/Checklist';
import { MyTestApplicationRecordEntity } from './my-test-application-record.entity';
import { Feature } from '../../types/feature.type';
import { masculineArticle } from '../../features/articles';
import { AuthorizationService } from 'app/services/AuthorizationService';

const entity = new MyTestApplicationRecordEntity()

export const myTestApplicationRecordFeature: Feature = {
    label: {
        singular: entity.name.singular,
        plural: entity.name.plural
    },
    menu: {
        grammaticalNumber: 'plural'
    },
    article: masculineArticle,
    icon: ChecklistIcon,
    isAuthorized: AuthorizationService.userIsAuthorized(`test-application-record:findAllMine`),
    entryLink: `/client/${entity.slug}`,
}
