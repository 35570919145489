import { RoleScopesEnum } from "./role.enums";

export const roleScopes = [
    {
        label: 'Cliente',
        value: RoleScopesEnum.END_USER
    },
    {
        label: 'Back Office',
        value: RoleScopesEnum.BACK_OFFICE
    }
]