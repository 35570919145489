import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { CreateDto } from "./pages/Create/create.dto";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./assessment.entity";
import { ProtectedService } from "../../services/ProtectedService";
import { UpdateDto } from "./pages/Update/update.dto";

export class AssessmentService extends ProtectedService {

  async findOne(id: string): Promise<EntitySchema> {
    const response = await this.axios.get(`/assessment/${id}`)

    return response.data
  }

  async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
    const response = await this.axios.get("/assessment", {
      params: dto,
    });

    return response.data;
  }

  async create(dto: CreateDto): Promise<boolean> {
    const response = await this.axios.post('/assessment', dto)

    return response.status === 201
  }

  async update(id: string, dto: UpdateDto): Promise<boolean> {
    const response = await this.axios.patch(`/assessment/${id}`, dto)

    return response.status === 200
  }

  async delete(dto: DeleteDto): Promise<boolean> {
    const response = await this.axios.delete(`/assessment/${dto.id}`)

    return response.status === 200
  }
}
