import { Feature } from '../../types/feature.type';
import PolicyIcon from '@mui/icons-material/Policy';
import { Entity } from './policy.entity';
import { feminineArticle } from '../../features/articles';
import { AuthorizationService } from 'app/services/AuthorizationService';

const entity = new Entity()

export const policyFeature: Feature = {
    label: {
        singular: entity.name.singular,
        plural: entity.name.plural,
    },
    menu: {
        grammaticalNumber: 'plural'
    },
    article: feminineArticle,
    icon: PolicyIcon,
    isAuthorized: AuthorizationService.userIsAuthorized('rbac-policy:findAll'),
    entryLink: `/client/${entity.slug}`,
}
