import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./my-test-application-record.entity";
import { ProtectedService } from "../../services/ProtectedService";

export class MyTestApplicationRecordService extends ProtectedService {

    async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
        const response = await this.axios.get('/test-application-record/mine', {
            params: dto
        })

        return response.data
    }
}
