import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { resetPassword } from '../../../redux/authenticatorSlice';
import Alert from '@mui/material/Alert';
import PasswordInput from '../../../components/PasswordInput';
import { useState } from 'react';
import { mapValidationErrors, validateForm } from '../../../helpers/validation';
import { schema } from './schema';

function ResetConfirmPasswordationMesage() {
  return (
    <>
      <br />
      <Alert severity="success">
        Contraseña actualizada <br />
        Inicie sesión con las nuevas credenciales
      </Alert>
    </>
  )
}

const formInitialState = {
  code: '',
  password: '',
  confirmPassword: ''
}

const errorsInitialState = formInitialState

export default function ResetPassword() {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const resetPasswordState = useAppSelector(state => state.authenticator.resetPassword)

  const [form, setForm] = useState({
    ...formInitialState,
    code: searchParams.get('code') || ''
  })

  const [errors, setErrors] = useState(errorsInitialState)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = () => {
    const formValidation = validateForm(schema, form)

    if (formValidation.error) {
      setErrors({
        ...formInitialState,
        ...mapValidationErrors(formValidation.error)
      })
    } else {
      dispatch(
        resetPassword({
          password: form.password,
          code: form.code
        })
      )

      setForm(formInitialState)
      setErrors(errorsInitialState)
    }
  };

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h5">
        Restablecer Contraseña
      </Typography>

      {
        (resetPasswordState.done && Boolean(resetPasswordState.error === ''))
        && ResetConfirmPasswordationMesage()
      }

      <Box sx={{ mt: 1 }}>
        <PasswordInput
          label="Nueva Contraseña"
          name="password"
          value={form.password}
          error={errors.password}
          onChange={handleInputChange}
        />
        <PasswordInput
          label="Confirme Nueva Contraseña"
          name="confirmPassword"
          value={form.confirmPassword}
          error={errors.confirmPassword}
          onChange={handleInputChange}
        />
        <Button
          fullWidth
          variant="contained"
          disabled={resetPasswordState.loading}
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit}
        >
          {resetPasswordState.loading ? 'Cargando' : 'Restablecer'}
        </Button>

        {
          (resetPasswordState.done && !resetPasswordState.error) &&
          (
            <Button
              href='/auth/login'
              fullWidth
              variant="outlined"
              disabled={resetPasswordState.loading}
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar sesión
            </Button>
          )
        }

      </Box>
    </Box>
  );
}
