export const initialActionState = {
    loading: false,
    error: '',
    done: false
}

export const initialListState = <T>() => ({
    ...initialActionState,

    data: [] as T[],
    meta: {
        page: 1,
        perPage: 20,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
    }
})

export const initialViewState = <T>() => ({
    ...initialActionState,
    data: undefined as T | undefined
})

export const initialCreateState = () => ({
    ...initialActionState,
    id: undefined as string | undefined
})

export const initialUpdateState = () => ({
    ...initialActionState
})

export const initialDeleteState = () => ({
    ...initialActionState
})
