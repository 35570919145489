import Alert from "@mui/material/Alert"
import { useAppDispatch, useAppSelector } from "app/redux/hooks"
import { useEffect } from "react"
import { findOneByOrganizationAction } from "../../contract.slice"
import { contractFeature } from "../../contract.feature"

type Props = {
    organizationId: string
    onContractFound: (contractId: string) => void
}

export default function ContractValidatorInput(props: Props) {
    const dispatch = useAppDispatch()
    const viewSelector = useAppSelector(state => state.contract.viewByOrganization)

    useEffect(() => {
        dispatch(findOneByOrganizationAction(props.organizationId))
    }, [])

    useEffect(() => {
        if (
            viewSelector.loading === false &&
            viewSelector.error === '' &&
            viewSelector.done === true &&
            viewSelector.data
        ) {
            props.onContractFound(viewSelector.data.id)
        }
    }, [viewSelector])

    return (
        <>
            {
                Boolean(viewSelector.data?.id) ?
                    <Alert severity="success">Se ha encontrado {contractFeature.article.definite.singular} {contractFeature.label.singular} {viewSelector.data?.id} vigente</Alert> :
                    <Alert severity="error">La organización no tiene {contractFeature.label.plural} vigentes</Alert>
            }
        </>
    )
}