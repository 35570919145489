import Joi from "joi";

export const schema = Joi.object({
    email: Joi
        .string()
        .label('Correo electrónico')
        .email({ tlds: { allow: false } })
        .required(),
    password: Joi
        .string()
        .label('Correo electrónico')
        .required(),
})
