import { ProtectedService } from "app/services/ProtectedService";
import { UpdateInfoDto } from "./pages/UpdateInfo/update-info.dto";
import { UpdatePasswordDto } from "./pages/UpdatePassword/update-password.dto";
import { UpdateSociodemographicInfoDto } from "./pages/UpdateSociodemographicInfo/update-sociodemographic-info.dto";
import { ApiResponse } from "app/types/api-response";
import { handleAxiosError } from "app/helpers/axios.helper";

export class ProfileService extends ProtectedService {
    async getMyProfile() {
        const response = await this.axios.get(`/my-profile`)

        return response.data.response
    }

    async updateInfo(dto: UpdateInfoDto) {
        const response = await this.axios.patch(`/my-profile/info`, dto)

        return response.status === 200
    }

    async updatePassword(dto: UpdatePasswordDto): Promise<ApiResponse<boolean>> {
        try {
            const response = await this.axios.patch(`/my-profile/password`, dto)

            return {
                statusCode: response.status,
                result: response.status === 200
            }
        } catch (error) {
            return handleAxiosError(error)
        }
    }

    async getSociodemographicInfo() {
        const response = await this.axios.get(`/my-profile/sociodemographic-information`)

        return response.data.response
    }

    async updateSociodemographicInfo(dto: UpdateSociodemographicInfoDto) {
        const response = await this.axios.patch(`/my-profile/sociodemographic-information`, dto)

        return response.status === 200
    }
}