import { JoinAndCountDefinition } from "app/types/join-and-count.type"
import { EntityAttribute, EntityName } from "../../interfaces/EntityInterface"
import { JoinDefinition } from "../../types/join.type"
import { organizationalUnitFeature } from "../organizational-unit/organizational-unit.feature"
import { BaseUiEntity } from "app/classes/base-ui-entity"

export type EntitySchema = {
    id: string
    name: string
    createdAt: string
    updatedAt: string
}

export const entityInitialState: EntitySchema = {
    id: '',
    name: '',
    createdAt: '',
    updatedAt: '',
}

const name: EntityName = {
    singular: 'Organización',
    plural: 'Organizaciones'
}

const slug = 'organization'

const primaryKeyAttribute: string = 'id'

const linkedToViewAttribute: string = 'name'

const attributes: EntityAttribute[] = [
    {
        key: 'id',
        label: 'ID',
        dataType: 'string',
        showInTable: false,
    },
    {
        key: 'name',
        label: 'Nombre',
        dataType: 'string',
        showInTable: true,
    },
    {
        key: 'organizationalUnitsCount',
        label: organizationalUnitFeature.label.plural,
        dataType: 'number',
        showInTable: true,
        filter: false,
        sort: false
    },
    {
        key: 'createdAt',
        label: 'Creado',
        dataType: 'date',
        showInTable: true,
        filter: false
    },
]

const join: JoinDefinition[] = []

const joinAndCount: JoinAndCountDefinition[] = [
    {
        entity: 'organizationalUnits'
    }
]

export class OrganizationEntity extends BaseUiEntity {
    constructor() {
        super(
            name,
            slug,
            primaryKeyAttribute,
            linkedToViewAttribute,
            attributes,
            join,
            joinAndCount,
        )
    }
}
