import { useEffect, useState } from 'react'
import { MRT_ColumnFiltersState, MRT_SortingState, MRT_PaginationState } from "material-react-table";
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { findAllAction, initialState } from '../../organization.slice';
import DataTable from '../../../../components/DataTable';
import ActionButtons, { ActionButton } from '../../../../components/ActionButtons';
import { AuthorizationService } from '../../../../services/AuthorizationService';
import Update from '../Update';
import PageHeader from '../../../../components/PageHeader';
import { OrganizationEntity, EntitySchema } from '../../organization.entity';
import { organizationFeature } from '../../organization.feature';
import FormDialog from '../../../../components/FormDialog';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { userEmployeeFeature } from '../../../user-employee/user-employee.feature';
import { organizationalUnitFeature } from 'app/modules/organizational-unit/organizational-unit.feature';
import { FilterState, SortingState } from 'app/types/pagination.dto';
import { buildFilterByQueryParams } from 'app/helpers/pagination.helper';

const feature = organizationFeature
const entity = new OrganizationEntity()

export default function List() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const listSelector = useAppSelector(state => state.organization.list)
    const filterInitialState: FilterState | undefined = buildFilterByQueryParams([])

    const [selectedRow, setSelectedRow] = useState<EntitySchema | null>(null)
    const [sorting, setSorting] = useState<SortingState>()
    const [filter, setFilter] = useState<FilterState | undefined>(filterInitialState)
    const [page, setPage] = useState<MRT_PaginationState>({
        pageIndex: initialState.list.meta.page,
        pageSize: initialState.list.meta.perPage
    })

    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const [refreshEnabled, setRefreshEnabled] = useState(false)
    const [updateEnabled, setUpdateEnabled] = useState(false)

    const [viewEmployeesEnabled, setViewEmployeesEnabled] = useState(false)
    const [viewOrganizationalUnitsEnabled, setViewOrganizationalUnitsEnabled] = useState(false)

    const [refreshVisible, setRefreshVisible] = useState(false)
    const [updateVisible, setUpdateVisible] = useState(false)

    const refreshData = () => {
        dispatch(findAllAction({
            page: page.pageIndex,
            perPage: page.pageSize,
            sortBy: sorting?.query,
            filter: filter?.query,
            join: entity.getJoinQuery(),
            joinAndCount: entity.getJoinAndCountQuery()
        }))
    }

    const viewEmployeesByOrganization = (organizationId: string) => {
        const params = {
            organizationId
        }

        navigate({
            pathname: `../${userEmployeeFeature.entryLink}`,
            search: `?${createSearchParams(params)}`
        })
    }

    const viewOrganizationalUnitsByOrganization = (organizationId: string) => {
        const params = {
            organizationId
        }

        navigate({
            pathname: `../${organizationalUnitFeature.entryLink}`,
            search: `?${createSearchParams(params)}`
        })
    }

    useEffect(() => {
        const userIsAuthorizedToFindAll = AuthorizationService.userIsAuthorized('organization:findAll')

        setRefreshVisible(userIsAuthorizedToFindAll)
        setRefreshEnabled(userIsAuthorizedToFindAll)

        setUpdateVisible(AuthorizationService.userIsAuthorized('organization:update'))
    }, [])

    useEffect(() => {
        setUpdateEnabled(!!selectedRow)
        setViewEmployeesEnabled(!!selectedRow)
        setViewOrganizationalUnitsEnabled(!!selectedRow)
    }, [selectedRow])

    useEffect(() => {
        refreshData()
    }, [sorting, filter, page])

    const handleRowSelected = (row: EntitySchema | null) => {
        setSelectedRow(row)
    }

    const handleSortingChange = (sorting: MRT_SortingState, query?: string) => {
        setSorting({
            state: sorting,
            query
        })
    }

    const handleFilterChange = (filter: MRT_ColumnFiltersState, query?: string) => {
        setFilter({
            state: filter,
            query
        })
    }

    const handlePageChange = (page: MRT_PaginationState) => {
        setPage({
            pageIndex: page.pageIndex + 1,
            pageSize: page.pageSize
        })
    }

    const handleUpdateCancelled = () => {
        setUpdateModalOpen(false)
    }

    const handleUpdateCompleted = (updated: boolean, data?: EntitySchema) => {
        if (updated) {
            setUpdateModalOpen(false)
            refreshData()
        }
    }

    const leftButtons: ActionButton[] = [
        {
            label: 'Refrescar',
            icon: UpdateIcon,
            color: 'primary',
            isEnabled: refreshEnabled,
            isVisible: refreshVisible,
            onClick: () => refreshData()
        },
    ]

    const rightButtons: ActionButton[] = [
        {
            label: userEmployeeFeature.label.plural,
            icon: userEmployeeFeature.icon,
            color: 'primary',
            isEnabled: viewEmployeesEnabled,
            isVisible: userEmployeeFeature.isAuthorized,
            onClick: () => {
                viewEmployeesByOrganization(selectedRow!.id)
            }
        },
        {
            label: organizationalUnitFeature.label.plural,
            icon: organizationalUnitFeature.icon,
            color: 'primary',
            isEnabled: viewOrganizationalUnitsEnabled,
            isVisible: organizationalUnitFeature.isAuthorized,
            onClick: () => {
                viewOrganizationalUnitsByOrganization(selectedRow!.id)
            }
        },
        {
            label: 'Actualizar',
            icon: EditIcon,
            color: 'primary',
            isEnabled: updateEnabled,
            isVisible: updateVisible,
            onClick: () => setUpdateModalOpen(true)
        }
    ]

    return (
        <>
            <PageHeader
                title={feature.label.plural}
                icon={feature.icon}
            />

            <ActionButtons
                leftButtons={leftButtons}
                rightButtons={rightButtons}
            />

            <DataTable
                entity={entity}
                dataTable={listSelector}
                sorting={sorting?.state}

                onRowSelected={handleRowSelected}
                onSortingChange={handleSortingChange}
                onFilterChange={handleFilterChange}
                onPageChange={handlePageChange}
            />

            {
                selectedRow && (
                    <>
                        <FormDialog open={updateModalOpen}>
                            <Update
                                id={selectedRow?.id || ''}
                                onCancelled={handleUpdateCancelled}
                                onCompleted={handleUpdateCompleted}
                            />
                        </FormDialog>
                    </>
                )
            }
        </>
    );
}
