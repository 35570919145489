import Joi from "joi";
import { UpdateDto } from "./update.dto";
import { organizationalUnitFeature } from "../../organizational-unit.feature";
import { organizationFeature } from "app/modules/organization/organization.feature";

export const schema = Joi.object<UpdateDto, true>({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required(),
    organizationalUnitId: Joi
        .string()
        .label(organizationalUnitFeature.label.singular)
        .uuid()
        .allow(null)
        .optional(),
    organizationId: Joi
        .string()
        .label(organizationFeature.label.singular)
        .uuid()
        .required(),
})
