import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { organizationFeature } from "app/modules/organization/organization.feature";
import { organizationalUnitFeature } from "app/modules/organizational-unit/organizational-unit.feature";
import { userEmployeeFeature } from "app/modules/user-employee/user-employee.feature";
import CardActions from "@mui/material/CardActions";
import FormDialog from "app/components/FormDialog";
import CreateForEmployee from "../CreateForEmployees";
import CreateForOrganizationalUnit from "../CreateForOrganizationalUnit";
import CreateForOrganization from "../CreateForOrganization";

type Props = {
    onCancelled: () => void
    onCompleted: () => void
}

export default function SelectCreationTarget(props: Props) {
    const [createForOrganizationModalOpen, setCreateForOrganizationModalOpen] = useState(false)
    const [createForOrganizationalUnitModalOpen, setCreateForOrganizationalUnitModalOpen] = useState(false)
    const [createForEmployeeModalOpen, setCreateForEmployeeModalOpen] = useState(false)

    const handleCreateForOrganizationClick = () => {
        setCreateForOrganizationModalOpen(true)
    }

    const handleCreateForOrganizationCancelled = () => {
        setCreateForOrganizationModalOpen(false)
    }

    const handleCreateForOrganizationCompleted = () => {
        setCreateForOrganizationModalOpen(false)
        props.onCompleted()
    }

    const handleCreateForOrganizationalUnitClick = () => {
        setCreateForOrganizationalUnitModalOpen(true)
    }

    const handleCreateForOrganizationalUnitCancelled = () => {
        setCreateForOrganizationalUnitModalOpen(false)
    }

    const handleCreateForOrganizationalUnitCompleted = () => {
        setCreateForOrganizationalUnitModalOpen(false)
        props.onCompleted()
    }

    const handleCreateForEmployeeClick = () => {
        setCreateForEmployeeModalOpen(true)
    }

    const handleCreateForEmployeeCancelled = () => {
        setCreateForEmployeeModalOpen(false)
    }

    const handleCreateForEmployeeCompleted = () => {
        setCreateForEmployeeModalOpen(false)
        props.onCompleted()
    }

    return (
        <Card sx={{ minWidth: 400 }}>

            <CardHeader
                title={`A qué nivel quiere aplicar la prueba?`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    alignContent={'center'}
                    alignItems={'center'}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleCreateForOrganizationClick}
                    >
                        {organizationFeature.label.singular}
                    </Button>

                    <br />
                    <br />

                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleCreateForOrganizationalUnitClick}
                    >
                        {organizationalUnitFeature.label.singular}
                    </Button>

                    <br />
                    <br />

                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleCreateForEmployeeClick}
                    >
                        {userEmployeeFeature.label.singular}
                    </Button>
                </Box>
            </CardContent>
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
            </CardActions>

            <FormDialog open={createForOrganizationModalOpen}>
                <CreateForOrganization
                    onCancelled={handleCreateForOrganizationCancelled}
                    onCompleted={handleCreateForOrganizationCompleted}
                />
            </FormDialog>

            <FormDialog open={createForOrganizationalUnitModalOpen}>
                <CreateForOrganizationalUnit
                    onCancelled={handleCreateForOrganizationalUnitCancelled}
                    onCompleted={handleCreateForOrganizationalUnitCompleted}
                />
            </FormDialog>

            <FormDialog open={createForEmployeeModalOpen}>
                <CreateForEmployee
                    onCancelled={handleCreateForEmployeeCancelled}
                    onCompleted={handleCreateForEmployeeCompleted}
                />
            </FormDialog>
        </Card>
    )
}
